<template>
    <PerfectScrollbar class="sidebar">
        <div ref="sidebarMenubar" class="sidebar-scroll" :class="{ 'has-admin': $auth.isTechnicalRole() }">
            <ul>
                <li>
                    <RouterLink :to="{ name: 'dashboard' }">Dashboard</RouterLink>
                </li>
            </ul>
            <ul>
                <li>
                    <RouterLink :to="{ name: 'inventory' }">Inventory</RouterLink>
                </li>
                <li>
                    <RouterLink :to="{ name: 'ads-txt' }">Ads.txt</RouterLink>
                </li>
                <li>
                    <RouterLink :to="{ name: 'dealList' }">Deals</RouterLink>
                </li>
                <li>
                    <RouterLink
                        :to="{ name: 'reports' }"
                        :class="{ 'router-link-exact-active router-link-active': isReportPage }"
                    >
                        Reports
                    </RouterLink>
                </li>
            </ul>
            <ul>
                <li>
                    <RouterLink :to="{ name: 'billing' }">Billing</RouterLink>
                </li>
                <li>
                    <RouterLink :to="{ path: '/user-guide/1.0/getting-started#getting-started' }">
                        Platform guide
                    </RouterLink>
                </li>
            </ul>
            <ul v-if="$auth.isTechnicalRole()">
                <li class="title">Admin</li>
                <li v-if="$auth.check('admin') || $auth.check('root')">
                    <RouterLink :to="{ name: 'admin.inventory' }">Inventory</RouterLink>
                </li>
                <li>
                    <RouterLink :to="{ name: 'admin.users' }">Manage Users</RouterLink>
                </li>
                <li v-if="$auth.check('finance') || $auth.check('root')">
                    <RouterLink :to="{ name: 'admin.transactions' }">Transactions</RouterLink>
                </li>
                <li>
                    <RouterLink
                        :to="{ name: 'admin.reports.general' }"
                        :class="{ 'router-link-exact-active router-link-active': isAdminReportPage }"
                    >
                        General Report
                    </RouterLink>
                </li>
                <li>
                    <RouterLink :to="{ name: 'admin.crid' }">CRID Blacklist</RouterLink>
                </li>
                <li>
                    <RouterLink :to="{ name: 'activity.logs' }">Activity Logs</RouterLink>
                </li>
                <li v-if="$auth.check('admin') || $auth.check('root')">
                    <RouterLink :to="{ name: 'sample.logger' }">Activity Samples Logger</RouterLink>
                </li>
                <li v-if="$auth.check('admin') || $auth.check('root')">
                    <RouterLink :to="{ name: 'admin.prebid.analytics' }">Analytical Prebid Adapter</RouterLink>
                </li>
                <li v-if="$auth.check('root')">
                    <a :href="`${apiURL}/horizon/dashboard`" target="_blank">Laravel Horizon</a>
                </li>
            </ul>

            <div class="sidebar-copy" :class="{ publisher: $auth.check('publisher') }">
                <a href="https://smartyads.com/ccpa-resource#ccpa-formwrap" target="_blank"
                    >Do not sell my <br />
                    personal information</a
                >
                <div class="mt-3">2011 - {{ new Date().getFullYear() }} © SmartyAds.</div>
                <div class="mt-1">All Rights Reserved</div>
            </div>
        </div>
    </PerfectScrollbar>
</template>

<script>
export default {
    name: 'TheSidebarComponent',

    mounted() {
        window.removeEventListener('resize', this.onWindowsResizeHandle);
        window.addEventListener('resize', this.onWindowsResizeHandle);
    },

    updated() {
        this.calculateGuideLinkPosition();
    },

    destroyed() {
        window.removeEventListener('resize', this.onWindowsResizeHandle);
    },

    methods: {
        onWindowsResizeHandle() {
            this.calculateGuideLinkPosition();
        },

        calculateGuideLinkPosition() {
            const platformGuide = this.$refs.platformGuide;
            if (platformGuide === undefined) {
                return;
            }

            const platformGuideHeight = platformGuide.offsetHeight;

            const menuBar = this.$refs.sidebarMenubar;
            if (menuBar === undefined || this.$refs.sidebarMenubar.$el === undefined) {
                return;
            }
            let menuBarHeight = 40;
            const menuBarChildNodes = this.$refs.sidebarMenubar.$el.childNodes;
            for (const nodeId in menuBarChildNodes) {
                const node = menuBarChildNodes[nodeId];
                if (node.offsetHeight === undefined) {
                    continue;
                }
                if (node === platformGuide) {
                    continue;
                }

                menuBarHeight += node.offsetHeight;
            }

            const header = document.getElementsByTagName('header')[0];
            const headerHeight = header === undefined ? 0 : header.offsetHeight;

            if (headerHeight + menuBarHeight + platformGuideHeight > document.documentElement.clientHeight) {
                platformGuide.classList.remove('platform-guide-fixed');
            } else {
                platformGuide.classList.add('platform-guide-fixed');
            }
        }
    },

    computed: {
        isAdminReportPage() {
            return ['admin.reports.general', 'admin.reports.pixalate-fraud'].includes(this.$route.name);
        },

        isReportPage() {
            return ['reports', 'reports.email'].includes(this.$route.name);
        },

        apiURL() {
            return process.env.VUE_APP_API_URL.replaceAll(/\/api|\/$/g, '').toLowerCase();
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../scss/variables';

.sidebar {
    background: $color-grey-light;
    position: relative;
    min-width: 210px;
    max-width: 100vw;
    box-shadow: 0 1px 5px rgba($color-black, 0.1);
    z-index: 4;

    @include media(md) {
        position: sticky;
        top: 56px;
    }

    @include media(lg) {
        max-width: 210px;
        padding-top: 20px;
        box-shadow: none;
        overflow: visible !important;
        position: relative;
        top: 0;
    }

    ul {
        padding-left: 0;
        margin: 0;
        list-style: none;
        display: flex;

        @include media(lg) {
            padding-bottom: 20px;
            flex-direction: column;
        }

        li {
            a {
                padding: 15px 20px;
                font-size: 12px;
                line-height: 1.5;
                color: $color-secondary;
                display: block;
                outline: none;
                text-decoration: none;
                white-space: nowrap;

                @include media(lg) {
                    white-space: normal;
                }

                &:hover {
                    background: $color-white;
                    color: $color-primary;
                    text-decoration: none;
                }

                &.router-link-exact-active {
                    background: $color-white;
                    color: $color-primary;
                }
            }

            &.new {
                position: relative;

                a {
                    align-content: center;
                    display: flex;
                    padding-right: 43px;

                    span {
                        display: flex;
                        line-height: 14px;
                        align-self: center;
                    }
                }

                &:before {
                    content: 'new';
                    padding: 0 7px;
                    position: absolute;
                    right: 7px;
                    top: 10px;
                    font-size: 10px;
                    line-height: 20px;
                    color: $color-primary;
                    text-transform: uppercase;
                    border: 1px solid $color-primary;
                    box-sizing: border-box;
                    border-radius: 100px;
                }
            }

            &.title {
                font-size: 12px;
                line-height: 1.5;
                color: $color-secondary;
                padding-left: 20px;
                font-weight: 600;
                display: flex;
                align-items: center;
            }
        }
    }

    &-scroll {
        display: flex;

        @include media(lg) {
            flex-direction: column;
            position: sticky;
            top: 85px;
            height: calc(100vh - 85px);
        }

        &.has-admin {
            @media (max-height: 800px) {
                height: auto;
            }
        }
    }

    &-copy {
        padding: 15px 20px;
        font-size: 10px;
        color: rgba($color-secondary, 0.4);
        display: none;
        text-decoration: none;
        margin-top: auto;

        @include media(lg) {
            display: block;
            //position: sticky;
            //top: calc(100% - 110px);
        }

        a {
            color: rgba($color-secondary, 0.4);
            text-decoration: none;
            border-bottom: 1px solid rgba($color-secondary, 0.1);
            transition: 0.3s ease-out;
            transition-property: color, border-color;

            &:hover {
                color: $color-primary;
                border-color: $color-primary;
            }
        }
    }
}

.platform-guide-fixed {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 170px;
}
</style>
