<template>
    <div :class="{ 'has-error': localErrors.has(this.name), [boxClass]: true }">
        <div class="input-group">
            <div v-if="prependLabel" class="input-group-prepend">
                <div class="input-group-text input-prepend-group-text">{{ prependLabel }}</div>
            </div>
            <input
                :value="localValue"
                :type="type"
                :name="name"
                :disabled="isDisabled"
                :readonly="isReadonly"
                :maxlength="max"
                :placeholder="placeholder"
                @change="
                    $emit('input', $event.target.value);
                    $emit('change', $event.target.value);
                "
                @keyup="$emit('keyup', $event.target.value)"
                @input="$emit('changed', $event.target.value)"
                class="input-text form-control"
            />
            <div v-if="appendLabel" class="input-group-append">
                <div class="input-group-text input-append-group-text">{{ appendLabel }}</div>
            </div>
        </div>
        <div v-show="localErrors.has(this.name)" class="help-text">{{ localErrors.first(this.name) }}</div>
    </div>
</template>

<script>
export default {
    name: 'AppInputGroupComponent',

    props: {
        name: {
            type: String
        },

        value: {
            type: [String, Number]
        },

        boxClass: {
            default: 'input-wrap-default',
            type: String
        },

        appendLabel: {
            default: '',
            type: String
        },

        prependLabel: {
            default: '',
            type: String
        },

        isDisabled: {
            default: false,
            type: Boolean
        },

        isReadonly: {
            default: false,
            type: Boolean
        },

        placeholder: {
            type: String
        },

        errorsBag: {
            type: Object,
            name: 'errors'
        },

        type: {
            type: String,
            default: 'text'
        },

        max: {
            type: Number,
            default: 1024
        }
    },

    data: function () {
        return {
            localErrors: this.errorsBag,
            type_: this.type
        };
    },

    watch: {
        type: function (newVal) {
            this.type_ = newVal;
        }
    },

    computed: {
        localValue() {
            return this.value;
        }
    },

    created: function () {
        this.localErrors = this.errorsBag || this.errors;
    }
};
</script>

<style lang="scss" scoped>
@import '../../scss/variables';

// .input-group

.alt-error {
    position: relative;

    .input-group.has-error .help-text {
        position: absolute;
        left: 0;
        bottom: -22px;
    }
}
</style>
