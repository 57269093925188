<template>
    <VueSelect
        class="app-select"
        :value="valueLocal"
        :name="name"
        :label="label"
        :options="options"
        :close-on-select="closeOnSelect"
        :placeholder="placeholder"
        :multiple="multiple"
        :getOptionLabel="getOptionLabel"
        :selectable="selectable"
        @input="$emit('input', $event)"
        @search="$emit('search', $event)"
        @close="$emit('close')"
        @open="$emit('open')"
    >
        <span slot="no-options">{{ 'Sorry, no matching options' }}</span>
        <span slot="option" slot-scope="option">
            <slot name="option" v-bind="normalizeOptionForSlot(option)">
                {{ getOptionLabel(option) }}
            </slot>
        </span>
        <template #selected-option="option">
            <span>{{ getOptionLabel(option) }}</span>
        </template>
    </VueSelect>
</template>

<script>
import VueSelect from 'vue-select';

export default {
    name: 'AppSelectComponent',

    components: { VueSelect },

    props: {
        name: {
            type: String
        },

        value: {},

        options: {
            type: Array,
            default: () => []
        },

        multiple: {
            type: Boolean,
            default: false
        },

        placeholder: {
            type: String,
            default: ''
        },

        label: {
            type: String,
            default: 'label'
        },

        getOptionLabel: {
            type: Function,
            default(option) {
                if (typeof option === 'object') {
                    if (!Object.prototype.hasOwnProperty.call(option, this.label)) {
                        return console.warn(
                            `[vue-select warn]: Label key "option.${this.label}" does not exist in options object
                            ${JSON.stringify(option)}.\nhttp://sagalbot.github.io/vue-select/#ex-labels`
                        );
                    }
                    return option[this.label];
                }
                return option;
            }
        },

        selectable: {
            type: Function,
            default: () => true
        },

        closeOnSelect: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        valueLocal() {
            return this.value;
        }
    },

    methods: {
        normalizeOptionForSlot(option) {
            return typeof option === 'object' ? option : { [this.label]: option };
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep input[type='search'].vs__search::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

::v-deep input[type='search'].vs__search::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

::v-deep input[type='search'].vs__search::-webkit-search-decoration,
::v-deep input[type='search'].vs__search::-webkit-search-cancel-button,
::v-deep input[type='search'].vs__search::-webkit-search-results-button,
::v-deep input[type='search'].vs__search::-webkit-search-results-decoration {
    display: none;
}
</style>

<style lang="scss">
.app-select.v-select {
    .vs__actions {
        cursor: pointer;
        transition: 0.5s;
        z-index: 2;
    }

    .vs__dropdown-menu {
        top: 35px;
    }

    &.vs--open {
        border-color: #740b99;

        .vs__actions {
            transform: rotateZ(180deg);
        }
    }
}
</style>
