<template>
    <div class="action-panel-options options" :class="{ 'is-active': isDropdownActive }" v-on-clickaway="away">
        <div :class="['dropdown-trigger', { forbidden: this.forbidden }]" @click="handlerDropdown">
            <a aria-haspopup="true" aria-controls="{ 'dropdown-menu'}">
                <span class="icon is-small">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12.4374 13.9317L13.9155 12.4536L13.3949 10.5623L13.6217 10.0207L15.3333 9.05488V6.96463L13.6266 5.99481L13.4036 5.45411L13.9307 3.56189L12.4516 2.08488L10.5601 2.60531L10.0197 2.37871L9.05384 0.666992H6.96358L5.99377 2.37369L5.45319 2.59663L3.56128 2.06886L2.08443 3.54572L2.60495 5.43744L2.37834 5.97789L0.666626 6.94376V9.03341L2.37178 10.0054L2.59497 10.5465L2.06794 12.4384L3.54535 13.9159L5.43708 13.3953L5.97758 13.622L6.94347 15.333H9.03319L10.0044 13.6278L10.5455 13.4046L12.4374 13.9317ZM12.5684 9.08406L11.9872 10.4722L12.4241 12.0594L12.0452 12.4383L10.4613 11.9971L9.07261 12.5698L8.2582 13.9997H7.72191L6.91424 12.5689L5.52767 11.9875L3.93961 12.4245L3.56132 12.0462L4.00254 10.4624L3.42985 9.07375L1.99997 8.2587V7.72235L3.43137 6.91466L4.01277 5.52803L3.57581 3.93997L3.95331 3.56246L5.53714 4.00429L6.92633 3.43136L7.73951 2.00033H8.27525L9.08294 3.43173L10.4696 4.01312L12.0579 3.57608L12.4372 3.95477L11.9961 5.5383L12.5689 6.92736L14 7.74053V8.27628L12.5684 9.08406ZM7.99995 10.667C6.52719 10.667 5.33328 9.47308 5.33328 8.00033C5.33328 6.52757 6.52719 5.33366 7.99995 5.33366C9.47271 5.33366 10.6666 6.52757 10.6666 8.00033C10.6666 9.47308 9.47271 10.667 7.99995 10.667ZM9.33329 8.00033C9.33329 8.7367 8.73634 9.33366 7.99996 9.33366C7.26358 9.33366 6.66663 8.7367 6.66663 8.00033C6.66663 7.26395 7.26358 6.66699 7.99996 6.66699C8.73634 6.66699 9.33329 7.26395 9.33329 8.00033Z"
                            fill="#C2CAD8"
                        />
                    </svg>
                </span>
            </a>
        </div>
        <div class="dropdown-menu" role="menu">
            <div v-if="showSelectAll" class="wrapper">
                <div class="dropdown-content mt-10">
                    <a class="select-all-item">
                        <DspCheckbox
                            inpID="selectAll"
                            :inpLabel="allFieldsSelected ? 'Unselect All' : 'Select All'"
                            :isChecked="allFieldsSelected"
                            @change="selectAll()"
                        ></DspCheckbox>
                    </a>
                </div>
            </div>
            <template v-for="(section, i) in sections">
                <div class="wrapper" :key="i">
                    <div class="title">{{ section }}</div>
                    <div class="dropdown-content">
                        <template v-if="section === 'selected'">
                            <Draggable
                                v-model="optionsCurrent[section]"
                                handle=".dropdown-item.draggable"
                                @change="onOrderChange($event)"
                            >
                                <template v-for="option in optionsCurrent[section]">
                                    <a class="dropdown-item draggable" :key="option.checkID">
                                        <DspCheckbox
                                            :inpID="option.checkID"
                                            :inpLabel="option.name"
                                            :isChecked="option.checked"
                                            :isDisabled="option.disabled"
                                            @change="onMerticChange(option, $event)"
                                        ></DspCheckbox>
                                    </a>
                                </template>
                            </Draggable>
                        </template>
                        <template v-else>
                            <template v-for="option in optionsCurrent[section]">
                                <a class="dropdown-item" :key="option.checkID">
                                    <DspCheckbox
                                        :inpID="option.checkID"
                                        :inpLabel="option.name"
                                        :isChecked="option.checked"
                                        :isDisabled="option.disabled"
                                        @change="onMerticChange(option, $event)"
                                    ></DspCheckbox>
                                </a>
                            </template>
                        </template>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import Draggable from 'vuedraggable';
import _ from 'lodash';
import hasDifference from '@/lib/hasDifference';

export default {
    name: 'ActionOptionsComponent',

    props: {
        sections: {
            type: Array,
            default: () => {
                return [];
            }
        },

        options: {
            type: Object,
            default: () => {
                return {};
            }
        },

        showSelectAll: {
            type: Boolean,
            default: () => {
                return false;
            }
        },

        forbidden: {
            type: Boolean,
            default: false
        }
    },

    components: { Draggable },

    mixins: [clickaway],

    data: function () {
        return {
            isDropdownActive: false,
            optionsCurrent: {},
            valueAfterOpened: {}
        };
    },

    computed: {
        allFieldsSelected() {
            let allSelected = true;

            for (const sectionId in this.sections) {
                const section = this.sections[sectionId];
                if (section === 'selected') {
                    continue;
                }

                for (const optionId in this.optionsCurrent[section]) {
                    if (!this.optionsCurrent[section][optionId].checked) {
                        allSelected = false;
                    }
                }
            }

            return allSelected;
        }
    },

    methods: {
        away() {
            this.isDropdownActive = false;
        },

        onOrderChange() {
            const selected = [];

            this.sections.map(sec => {
                this.optionsCurrent[sec].map(i => {
                    if (i.checked) {
                        selected.push(i.checkID);
                    }
                });
            });

            this.$emit('change', selected);
        },

        onMerticChange(option, event) {
            this.$set(option, 'checked', event);

            const selected = [];

            this.sections.map(sec => {
                this.optionsCurrent[sec].map(i => {
                    if (i.checked) {
                        selected.push(i.checkID);
                    }
                });
            });

            this.$emit('change', selected);
        },

        selectAll() {
            const newState = !this.allFieldsSelected;

            for (const sectionId in this.sections) {
                const section = this.sections[sectionId];
                if (section === 'selected') {
                    continue;
                }

                for (const optionId in this.optionsCurrent[section]) {
                    this.optionsCurrent[section][optionId].checked = newState;
                    this.onMerticChange(this.optionsCurrent[section][optionId], newState);
                }
            }
        },

        /**
         * Событие открытия dropdown.
         *
         * @return void
         */
        handlerDropdown() {
            !this.forbidden && (this.isDropdownActive = !this.isDropdownActive);
        }
    },

    watch: {
        options: {
            handler() {
                this.optionsCurrent = this.options;
            },

            immediate: true
        },

        isDropdownActive(value) {
            if (value) {
                this.valueAfterOpened = _.cloneDeep(this.optionsCurrent);
            } else if (!value && hasDifference(this.optionsCurrent, this.valueAfterOpened)) {
                this.$emit('change-after-close');
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../scss/variables';

.action-panel-options {
    position: relative;
    transition: 0.5s;

    &:has(.forbidden) {
        background-color: #f3f3f3c9;
    }

    &.alter .dropdown-menu .dropdown-item .check-wrap label {
        white-space: normal;
        word-break: normal;
        height: auto;
    }

    .input-wrap {
        padding-left: 10px;
        padding-right: 10px;
    }

    .dropdown-trigger {
        cursor: pointer;
        box-sizing: border-box;

        &.forbidden {
            cursor: not-allowed;
        }

        a {
            display: block;
            position: absolute;
            right: 0;
            width: 100%;
            height: 100%;
        }
    }

    .icon {
        width: 100%;
        height: 100%;
        text-align: center;
        display: grid;
        place-content: center;

        svg {
            width: 18px;
            height: 18px;
        }
    }

    .dropdown-menu {
        margin-top: 0;
        border-radius: 0;
        border-color: $color-grey;
        width: 270px;
        right: 0;
        left: auto;
        padding-left: 25px;
        padding-right: 25px;
        box-shadow: 0 1px 5px rgba($color-black, 0.1);

        .dropdown-content {
            padding-bottom: 7px;
        }

        .title {
            padding-top: 20px;
            padding-bottom: 10px;
            font-size: 16px;
            line-height: 20px;
            color: #1c2d3f;
            font-weight: normal;
            text-transform: none;

            &::first-letter {
                text-transform: uppercase;
            }
        }

        .dropdown-item {
            padding-left: 10px;
            padding-top: 10px;
            padding-bottom: 10px;
            font-size: 12px;
            border-radius: 5px;
            height: 36px;
            box-sizing: border-box;
            position: relative;

            &.draggable:after {
                content: '';
                width: 16px;
                height: 16px;
                background: url('@/images/drag-n-drop.svg') no-repeat;
                position: absolute;
                top: 50%;
                margin-top: -8px;
                right: 10px;
                opacity: 0;
            }

            &:focus {
                color: #212529;
            }

            &:active {
                color: #212529;
                background: #f6f6f8;
            }

            .check-wrap {
                width: 100%;
                vertical-align: top;

                label {
                    width: 100%;
                }
            }

            &:hover {
                background: #f6f6f8;

                &:after {
                    opacity: 1;
                }
            }
        }

        .select-all-item {
            color: #1c2d3f;
        }
    }

    &.is-active {
        .dropdown-menu {
            display: block;
        }

        .icon svg path {
            fill: $color-primary;
        }
    }
}
</style>
