import moment from 'moment';

export default {
    namespaced: true,

    state: {
        dateRange: {
            rangeName: 'Today',
            fromDate: moment().format('YYYY-MM-DD'),
            toDate: moment().format('YYYY-MM-DD'),
            timezone: 'UTC',
            byHours: false
        },
        tableGroupFieldsSelected: ['date'],
        tableNumericFieldsSelected: ['requests', 'wins', 'impressions', 'sspSpend'],
        tableFilter: {},
        chartFilter: 'all',
        groupByDate: 'days',
        testMode: false
    },

    getters: {
        getDateRange: state => ({
            rangeName: state.dateRange.rangeName ? state.dateRange.rangeName : 'Today',
            timezone: state.dateRange.timezone ? state.dateRange.timezone : 'UTC',
            fromDate: moment(state.dateRange.fromDate, 'YYYY-MM-DD'),
            toDate: moment(state.dateRange.toDate, 'YYYY-MM-DD'),
            byHours: state.dateRange.byHours ? state.dateRange.byHours : false
        }),
        getTableGroupFieldsSelected: state => state.tableGroupFieldsSelected,
        getTableNumericFieldsSelected: state => state.tableNumericFieldsSelected,
        getTableFilter: state => state.tableFilter,
        getChartFilter: state => state.chartFilter,
        getGroupByDate: state => state.groupByDate,
        getTestMode: state => state.testMode
    },

    actions: {},
    mutations: {
        setDateRange(state, value) {
            state.dateRange = {
                fromDate: value.fromDate.format('YYYY-MM-DD'),
                toDate: value.toDate.format('YYYY-MM-DD'),
                timezone: value.timezone,
                rangeName: value.rangeName,
                byHours: value.byHours
            };
        },

        setGroupByDate(state, value) {
            state.groupByDate = value;
        },
        setTableGroupFieldsSelected(state, value) {
            state.tableGroupFieldsSelected = value;
        },
        setTableNumericFieldsSelected(state, value) {
            state.tableNumericFieldsSelected = value;
        },
        setTableFilter(state, value) {
            state.tableFilter = value;
        },
        setChartFilter(state, value) {
            state.chartFilter = value;
        },
        setTestMode: (state, value) => (state.testMode = value)
    }
};
