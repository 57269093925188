interface State {
    selectedColumns: string[];
}

export default {
    namespaced: true,

    state: {
        selectedColumns: [
            'recipients',
            'frequency',
            'period',
            'customPeriod',
            'timezone',
            'hour',
            'lastSendDate',
            'nextSend',
            'createdAt',
            'updatedAt'
        ]
    },

    getters: {
        getSelectedColumns: (state: State): string[] => state.selectedColumns
    },

    mutations: {
        setSelectedColumns(state: State, value: string[]): void {
            state.selectedColumns = value;
        }
    }
};
