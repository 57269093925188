<template>
    <div class="check-wrap">
        <input type="checkbox" v-model="checked" :id="_uid" :disabled="isDisabled" :name="name" :value="value" />
        <label class="check-alt" :for="_uid">{{ inpLabel }}</label>
    </div>
</template>

<script>
export default {
    name: 'CheckboxComponent',

    props: {
        isChecked: {
            type: Boolean,
            default: false
        },

        isDisabled: {
            type: Boolean,
            default: false
        },

        inpLabel: {
            type: String
        },

        name: {
            type: String
        },

        value: {
            type: Boolean,
            default: null
        }
    },

    computed: {
        checked: {
            get() {
                return this.value !== null ? this.value : this.isChecked;
            },

            set(value) {
                this.$emit('change', value);
                this.$emit('input', value);
            }
        }
    }
};
</script>

<style lang="scss">
@import '../../scss/variables';
@import '../../scss/components/checkbox';
</style>
