import Vue from 'vue';
import App from '@/App.vue';

import store from '@/store';

import router from '@/router';
import '@/plugins/axios';
import '@/plugins/notifications';
import '@/plugins/vue-auth';
import '@/plugins/vee-validate';
import '@/plugins/errors';
import '@/plugins/filters';
import '@/plugins/loading';

import { VBTooltip, VBToggle, ModalPlugin, ToastPlugin } from 'bootstrap-vue';
import AsyncComputed from 'vue-async-computed';
import dspInput from '@/components/shared/Input.vue';
import dspInputGroup from '@/components/shared/AppInputGroup.vue';
import dspTextArea from '@/components/shared/Textarea.vue';
import AppSelect from '@/components/shared/AppSelect.vue';
import dspSelect from '@/components/shared/Select.vue';
import dspMultiSelect from '@/components/shared/Multiselect.vue';
import dspTextEditable from '@/components/shared/TextEditable.vue';
import dspButton from '@/components/shared/Button.vue';
import dspButtonGroup from '@/components/shared/AppButtonGroup.vue';
import dspCheckbox from '@/components/shared/Checkbox.vue';
import dropdown from '@/components/shared/Dropdown.vue';
import inputGroup from '@/components/shared/InputGroup.vue';
import topNotification from '@/components/shared/TopNotification.vue';
import FilterDropdown from '@/components/shared/FilterDropdown.vue';
import adminUsersList from '@/components/shared/AdminUsers.vue';
import adminTopNotification from '@/components/shared/AdminTopNotification.vue';
import dspSwitcher from '@/components/shared/Switcher.vue';
import Box from '@/components/shared/Box.vue';
import metrixBox from '@/components/shared/MetrixBox.vue';
import metrixItem from '@/components/shared/MetrixItem.vue';
import tableTopActions from '@/components/shared/TableTopActions.vue';
import summaryStatisticWithChart from '@/components/shared/SummaryStatisticWithChart.vue';
import sampleLoggerChart from '@/components/shared/SampleLoggerChart.vue';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
import 'vue-select/dist/vue-select.css';
import actionOptions from '@/components/shared/ActionOptions.vue';
import DateSelect from '@/components/DatePicker/DateSelect.vue';
import groupByDropdown from '@/components/shared/GroupByDropdown.vue';

import HighchartsVue from 'highcharts-vue';
import VueApexCharts from 'vue-apexcharts';
import VueClipboard from 'vue-clipboard2';
import VueMeta from 'vue-meta';
import VueCookies from 'vue-cookies';

import * as Sentry from '@sentry/vue';

Vue.use(VueApexCharts);

Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
});

Vue.use(VueCookies);

Vue.config.productionTip = false;

Vue.use(AsyncComputed);

Vue.use(ModalPlugin);
Vue.use(ToastPlugin);

Vue.use(PerfectScrollbar);

Vue.use(HighchartsVue, { tagName: 'Highcharts' });

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

Vue.component('DspInput', dspInput);

Vue.component('VSelect', AppSelect);

Vue.component('DspInputGroup', dspInputGroup);

Vue.component('DspTextArea', dspTextArea);

Vue.component('DspSelect', dspSelect);

Vue.component('DspMultiSelect', dspMultiSelect);

Vue.component('DspTextEditable', dspTextEditable);

Vue.component('DspButton', dspButton);

Vue.component('DspButtonGroup', dspButtonGroup);

Vue.component('DspCheckbox', dspCheckbox);

Vue.component('DropdownComponent', dropdown);

Vue.component('FilterDropdown', FilterDropdown);

Vue.component('InputGroup', inputGroup);

Vue.component('TopNotification', topNotification);

Vue.component('AdminUsersList', adminUsersList);

Vue.component('AdminTopNotification', adminTopNotification);

Vue.component('DspSwitcher', dspSwitcher);

Vue.component('BoxComponent', Box);

Vue.component('MetrixBox', metrixBox);

Vue.component('MetrixItem', metrixItem);

Vue.component('TableTopActions', tableTopActions);

Vue.component('ActionOptions', actionOptions);

Vue.component('ApexchartComponent', VueApexCharts);

Vue.component('SummaryStatisticWithChart', summaryStatisticWithChart);

Vue.component('SampleLoggerChart', sampleLoggerChart);

Vue.component('DateSelect', DateSelect);

Vue.component('GroupByDropdown', groupByDropdown);

Vue.directive('b-tooltip', VBTooltip);
Vue.directive('b-toggle', VBToggle);

window._hsq = window._hsq || [];

Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    tracesSampleRate: parseFloat(process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE),
    tracePropagationTargets: ['https://ssp.smartyads.com'],
    trackComponents: true,
    hooks: ['create', 'mount', 'update', 'destroy'],
    release: process.env.VUE_APP_SENTRY_UI_VERSION ? 'ssp-ui@' + process.env.VUE_APP_SENTRY_UI_VERSION : '',

    replaysSessionSampleRate: parseFloat(process.env.VUE_APP_SENTRY_REPLAYS_SESSION),
    replaysOnErrorSampleRate: parseFloat(process.env.VUE_APP_SENTRY_REPLAYS_ON_ERROR),

    denyUrls: [/extensions\//i, /^chrome:\/\//i, /^chrome-extension:\/\//i]
});

export const app = new Vue({
    render: h => h(App),
    router,
    store
}).$mount('#app');
