import moment, { Moment } from 'moment-timezone';
import { datesToPeriod, dayFormat, getAllDateRanges, Period } from '@/lib/periodToDates';
import _ from 'lodash';

export default (from: Moment, to: Moment, timezone: string) => {
    const period = datesToPeriod(from.format(dayFormat), to.format(dayFormat));
    const periods = _.map(getAllDateRanges(), 'label');

    if (!(<string[]>periods).includes(period)) {
        return { from, to };
    }
    if ((<string[]>[Period.THIS_MONTH, Period.LAST_MONTH]).includes(period)) {
        const defaultDate = moment().tz(timezone);
        <string>Period.LAST_MONTH === period && defaultDate.subtract(1, 'month');

        return {
            from: defaultDate.clone().startOf('month'),
            to: defaultDate.clone().endOf('month')
        };
    }

    return { from: from.clone().tz(timezone), to: to.clone().tz(timezone) };
};
