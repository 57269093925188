export class NeedHelpParamsDTO {
    private _subject = '';
    private _message = '';

    getObject() {
        return {
            subject: this.subject,
            message: this.message
        };
    }

    get subject(): string {
        return this._subject;
    }

    set subject(value: string) {
        this._subject = value;
    }

    get message(): string {
        return this._message;
    }

    set message(value: string) {
        this._message = value;
    }
}
