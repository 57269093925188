<template>
    <div class="item" :class="{ 'bg-active': isActive }">
        <div class="title">{{ itemTitle }}</div>
        <div class="value">{{ itemValue }}</div>
    </div>
</template>

<script>
export default {
    name: 'MetrixItemComponent',

    props: {
        itemTitle: {
            type: String
        },

        itemValue: {
            type: [String, Number],
            default: '0'
        },

        isActive: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../scss/variables';

.item {
    padding: 15px;
    background: $color-grey-light;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.06);
    border-radius: 5px;

    &.bg-active {
        background: $color-primary;

        .title {
            color: $color-white;
        }

        .value {
            color: $color-white;
        }
    }

    &:first-child {
        margin-left: 0;
    }

    .title {
        font-size: 12px;
        line-height: 20px;
        color: #637381;
    }

    .value {
        font-size: 18px;
        line-height: 24px;
        color: $color-secondary;
        display: inline-block;
        width: 100%;
        overflow: hidden;
    }
}
</style>
