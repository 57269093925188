<template>
    <div class="switch-button-control">
        <div
            class="switch-button"
            v-bind:class="{ enabled: !disabled && isEnabled }"
            @click="modalConfirm ? (modalShow = true) : toggle()"
        >
            <div class="s-button"></div>
        </div>
        <BModal ref="modal" modal-class="confirm-modal" v-model="modalShow" centered>
            <p class="main">{{ messageDisplay }}</p>
            <span class="extra">Would you like to continue?</span>
            <template #modal-footer>
                <button class="btn" @click="modalShow = false">No</button>
                <button class="btn yes" @click="toggle()">Yes</button>
            </template>
        </BModal>
    </div>
</template>

<script>
export default {
    name: 'SwitcherComponent',

    props: {
        enabled: Boolean,

        disabled: {
            type: Boolean,
            default: false
        },

        modalConfirm: {
            type: Boolean,
            default: false
        },

        enableMessage: {
            type: String,
            default: ''
        },

        disableMessage: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            isEnabled: true,
            modalShow: false,
            messageDisplay: ''
        };
    },

    mounted() {
        this.isEnabled = this.enabled;

        if (this.modalConfirm === true) {
            this.messageDisplay = this.isEnabled ? this.disableMessage : this.enableMessage;
        }
    },

    watch: {
        enabled(newVal) {
            this.isEnabled = newVal;
        }
    },

    methods: {
        toggle() {
            this.modalShow = false;
            if (this.disabled) {
                return false;
            }

            this.isEnabled = !this.isEnabled;
            this.$emit('change', this.isEnabled);
        }
    }
};
</script>

<style lang="scss">
@import '../../scss/variables';

.switch-button-control {
    display: inline-block !important;
    vertical-align: middle;

    .switch-button {
        width: 24px;
        height: 14px;
        border: 2px solid #c2cad8;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        box-sizing: border-box;
        transition: all 400ms;
        display: block !important;

        .s-button {
            width: 6px;
            height: 6px;
            background: #c2cad8;
            border-radius: 50%;
            position: absolute;
            left: 2px;
            top: 2px;
            transition: all 400ms;
        }

        &.enabled {
            border-color: $color-primary;

            .s-button {
                left: 12px;
                background: $color-primary;
            }
        }
    }

    &.huge {
        align-self: center;
        margin-top: 22px;

        .switch-button {
            width: 48px;
            height: 28px;
            border-radius: 16px;
            border: 1px solid #c2cad8;

            &.enabled {
                border-color: $color-primary;

                .s-button {
                    left: 20px;
                    background: $color-primary;
                    top: 1px;
                }
            }
        }

        .s-button {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            left: 1px;
            top: 1px;
        }
    }

    &.disabled {
        cursor: not-allowed;

        .switch-button {
            pointer-events: none;
            border-color: #c2cad8;

            & .s-button {
                background: #c2cad8;
            }
        }
    }
}
</style>
