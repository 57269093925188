<template>
    <div v-bind:class="{ 'is-active': isDropdownActive }" v-on-clickaway="away" class="dropdown">
        <div class="dropdown-trigger" @click="isDropdownActive = !isDropdownActive">
            <a aria-haspopup="true" aria-controls="{'dropdown-menu'}">
                <slot name="header" :value="searchValue">
                    <span class="value">{{ searchValue }}</span>
                </slot>
                <span class="icon is-small"></span>
            </a>
        </div>

        <div class="dropdown-menu" role="menu" :class="{ leftAlignMenu: leftAlignMenu }">
            <DspInput v-show="showSearch" @changed="$emit('search', $event)" :fillAvailable="true"></DspInput>
            <div class="empty-table" v-show="isBusy">
                <img src="@/images/loader_smaller.svg" alt="loader smaller" />
            </div>
            <PerfectScrollbar>
                <div class="dropdown-content" v-show="!isBusy">
                    <template v-for="(option, index) in options">
                        <div v-if="value === option" :class="{ selected: value === option }" :key="index">
                            <a class="dropdown-item" style="pointer-events: none">
                                <slot name="item" v-bind:option="option"
                                    ><span>{{ option }}</span></slot
                                >
                            </a>
                        </div>
                        <a
                            v-else
                            @click="isDropdownActive = !isDropdownActive"
                            @click.prevent="optionclicked(option)"
                            class="dropdown-item"
                            :key="index"
                        >
                            <slot name="item" v-bind:option="option"
                                ><span>{{ option }}</span></slot
                            >
                        </a>
                    </template>
                </div>
            </PerfectScrollbar>
        </div>
    </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';

export default {
    name: 'DropdownComponent',

    props: {
        options: {
            type: Array,
            default: () => []
        },

        showSearch: {
            type: Boolean
        },

        dropValue: {
            type: [String, Object]
        },

        value: {
            type: [String, Object]
        },

        leftAlignMenu: {
            type: Boolean,
            default: false
        },

        isBusy: {
            type: Boolean,
            default: false
        }
    },

    mixins: [clickaway],

    computed: {
        searchValue() {
            return this.value ? this.value : this.dropValue;
        }
    },

    data() {
        return {
            isDropdownActive: false
        };
    },

    methods: {
        away() {
            this.isDropdownActive = false;
        },

        optionclicked(option) {
            this.$emit('input', option);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../scss/variables';

.dropdown {
    position: relative;

    &.imp-drop {
        .dropdown-trigger {
            padding-left: 0;

            a {
                padding-left: 36px;
                position: relative;
            }
        }

        .chart-control-label {
            background: #9c6ade;
        }
    }

    &.bid-drop {
        .dropdown-trigger {
            padding-left: 0;

            a {
                padding-left: 36px;
                position: relative;
            }
        }

        .chart-control-label {
            background: #47c0bf;
        }
    }

    .input-wrap {
        padding-left: 10px;
        padding-right: 10px;
    }

    &.with-icon-calendar {
        .dropdown-trigger {
            padding-left: 36px;
            background: url(@/images/icon-calendar.svg) 12px 50% no-repeat;
        }
    }

    &.is-active {
        .dropdown-trigger {
            border: 1px solid $color-primary;
        }
    }

    .chart-control-label {
        width: 15px;
        height: 15px;
        border-radius: 3px;
        position: absolute;
        left: 10px;
        top: 2px;
    }

    .dropdown-trigger {
        cursor: pointer;
        padding-top: 4px;
        padding-bottom: 3px;
        padding-left: 16px;
        min-width: 100px;
        background: $color-white;
        border: 1px solid #c4cdd5;
        box-sizing: border-box;
        box-shadow: inset 0 1px 2px rgba(102, 113, 123, 0.21);
        border-radius: 5px;
        height: 35px;

        a {
            width: 100%;
            display: inline-block;
            position: relative;
            line-height: 20px;
            font-size: 12px;
            color: $color-secondary;
            padding-right: 33px;
            overflow: hidden;
            vertical-align: middle;

            span:first-child {
                display: inline-block;
                white-space: nowrap;
            }
        }
    }

    .icon {
        position: absolute;
        right: 0;
        top: -5px;
        width: 30px;
        height: 30px;
        background: url(@/images/chevron-down.svg) 50% 50% no-repeat $color-white;
    }

    &.wide {
        .dropdown-menu {
            min-width: auto;
            width: 100%;
        }
    }

    .leftAlignMenu {
        right: 0 !important;
        left: auto !important;
    }

    .dropdown-menu {
        width: 100%;
        padding: 10px;
        border: 1px solid $color-grey;
        border-radius: 5px;
        box-shadow: 0 1px 5px rgba($color-black, 0.1);

        .selected {
            border-radius: 5px;
            background-color: #e1e1e1;
            cursor: no-drop;
        }

        .dropdown-item {
            font-size: 12px;
            border-radius: 5px;
            padding: 11px;
            white-space: normal;
            cursor: pointer;

            &:focus,
            &:active {
                background: $color-primary;
                color: $color-white;
            }
        }

        .ps {
            padding: 0;
        }
    }

    &.is-active {
        .dropdown-menu {
            display: block;
        }

        .dropdown-content {
            display: block;
        }
    }

    &.right {
        .dropdown-menu {
            right: 0;
            left: auto;
        }
    }
}

.user-date {
    .mdrp-root .mdrp__activator .activator-wrapper .text-field {
        border-radius: 5px 0 0 5px;
        border-width: 1px 0 1px 1px;
    }

    .dropdown .dropdown-trigger {
        border-radius: 0 5px 5px 0;
    }
}
</style>
