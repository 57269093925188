<template>
    <div
        v-if="$auth.ready() && $auth.check()"
        v-bind:class="{ 'is-active': isDropdownActive }"
        v-on-clickaway="away"
        v-loading="$store.state.loadingUserFromSudo || (!$auth.isViewOtherUser() && $store.state.loadingOriginalUser)"
        class="header-dropdown has-admin"
    >
        <div
            class="dropdown-trigger"
            @click="toggle(!isDropdownActive)"
            v-bind:class="{ online: $auth.user().isOnline }"
        >
            <div class="info">
                <div class="is-ellipsis">
                    <span class="user-id">{{ $auth.user().id }}</span>
                    <span class="name">{{ $auth.user() | full_username }}</span>
                </div>

                <div class="is-ellipsis">
                    <span class="balance">$ {{ $auth.user().balanceEarn }}</span>
                    <span class="user-mail">{{ $auth.user().email }}</span>
                </div>
            </div>

            <span class="dropdown-arrow" />
        </div>

        <div class="dropdown-menu" role="menu">
            <div class="dropdown-content">
                <div class="top">
                    <DspInput @changed="searchText = $event" class="search-input" placeholder="Search" />
                    <VSelect v-model="searchField" :options="searchFieldsList" class="blank-select" />
                </div>

                <PerfectScrollbar class="user-list">
                    <div
                        v-for="item in usersList"
                        :key="item.id"
                        @click="sudo(item)"
                        v-bind:class="{ online: item.isOnline }"
                        class="item"
                    >
                        <div>
                            <div class="u-id">{{ item.id }}</div>
                            <div class="u-balance">$ {{ item.balanceEarn }}</div>
                        </div>

                        <div>
                            <div class="u-name">{{ item.firstName }} {{ item.lastName }}</div>
                            <div class="u-mail">{{ item.email }}</div>
                        </div>

                        <a href="#" class="u-actions" @click.prevent.stop="gotoUserPreferences(item)">
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M12.4374 13.9317L13.9155 12.4536L13.3949 10.5623L13.6217 10.0207L15.3333 9.05488V6.96463L13.6266 5.99481L13.4036 5.45411L13.9307 3.56189L12.4516 2.08488L10.5601 2.60531L10.0197 2.37871L9.05384 0.666992H6.96358L5.99377 2.37369L5.45319 2.59663L3.56128 2.06886L2.08443 3.54572L2.60495 5.43744L2.37834 5.97789L0.666626 6.94376V9.03341L2.37178 10.0054L2.59497 10.5465L2.06794 12.4384L3.54535 13.9159L5.43708 13.3953L5.97758 13.622L6.94347 15.333H9.03319L10.0044 13.6278L10.5455 13.4046L12.4374 13.9317ZM12.5684 9.08406L11.9872 10.4722L12.4241 12.0594L12.0452 12.4383L10.4613 11.9971L9.07261 12.5698L8.2582 13.9997H7.72191L6.91424 12.5689L5.52767 11.9875L3.93961 12.4245L3.56132 12.0462L4.00254 10.4624L3.42985 9.07375L1.99997 8.2587V7.72235L3.43137 6.91466L4.01277 5.52803L3.57581 3.93997L3.95331 3.56246L5.53714 4.00429L6.92633 3.43136L7.73951 2.00033H8.27525L9.08294 3.43173L10.4696 4.01312L12.0579 3.57608L12.4372 3.95477L11.9961 5.5383L12.5689 6.92736L14 7.74053V8.27628L12.5684 9.08406ZM7.99995 10.667C6.52719 10.667 5.33328 9.47308 5.33328 8.00033C5.33328 6.52757 6.52719 5.33366 7.99995 5.33366C9.47271 5.33366 10.6666 6.52757 10.6666 8.00033C10.6666 9.47308 9.47271 10.667 7.99995 10.667ZM9.33329 8.00033C9.33329 8.7367 8.73634 9.33366 7.99996 9.33366C7.26358 9.33366 6.66663 8.7367 6.66663 8.00033C6.66663 7.26395 7.26358 6.66699 7.99996 6.66699C8.73634 6.66699 9.33329 7.26395 9.33329 8.00033Z"
                                    fill="#C2CAD8"
                                />
                            </svg>
                        </a>
                    </div>
                </PerfectScrollbar>
            </div>
        </div>
    </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import debounce from 'debounce';
import modalLeave from '@/mixins/modalLeave';

export default {
    name: 'AdminUsersComponent',

    mixins: [clickaway, modalLeave],

    data: function () {
        return {
            isDropdownActive: false,
            usersList: [],
            searchText: '',
            searchField: {},
            searchFieldsList: [
                {
                    field: 'id',
                    label: 'ID'
                },
                {
                    field: 'firstname',
                    label: 'First Name'
                },
                {
                    field: 'lastname',
                    label: 'Last Name'
                },
                {
                    field: 'email',
                    label: 'Email'
                },
                {
                    field: 'company',
                    label: 'Company'
                }
            ]
        };
    },

    watch: {
        searchText() {
            this.loadUserListDebounced();
        },

        searchField() {
            this.loadUserListDebounced();
        }
    },

    created() {
        this.searchField = this.searchFieldsList.find(() => true);
    },

    methods: {
        sudo(user) {
            this.away();

            if (this.$route.name !== 'inventory') {
                this.$router.push({ name: 'inventory' }).then(() => this.$auth.setUserView(user.id));
                return true;
            }

            this.$router
                .push({ name: 'dashboard' })
                .then(() => this.$auth.setUserView(user.id))
                .then(() => this.$router.push({ name: 'inventory' }));
        },

        gotoUserPreferences(item) {
            if (this.$route.name !== 'profile') {
                this.$router.push({ name: 'profile' }).then(() => {
                    this.setUser(item);
                });
            } else if (this.$store.state.hasProfileDifference) {
                this.modalLeave().then(answer => answer && this.setUser(item));
            } else {
                this.setUser(item);
            }
        },

        setUser(item) {
            this.$store.commit('setProfileOriginalUser', false);
            this.$auth.setUserView(item.id);
        },

        loadUserList() {
            let search = '';
            let searchFields = '';
            if (this.searchText) {
                search = this.searchText;
                searchFields = this.searchField.field;
            }

            const params = new URLSearchParams();
            params.append('search', search);
            params.append('searchField', searchFields);

            const promise = this.axios
                .get('/admin/users/list', { params: params })
                .then(response => {
                    const currentUser =
                        Object.keys(this.$auth.getOrigUser()).length > 0 ? this.$auth.getOrigUser() : this.$auth.user();
                    const currentUserId = currentUser.id;

                    this.usersList = response.data.data
                        .map(item => ({
                            id: item.id,
                            isActive: item.active,
                            isOnline: item.isOnline,
                            firstName: item.firstName,
                            lastName: item.lastName,
                            email: item.email,
                            role: item.role,
                            balanceEarn: item.balanceEarn
                        }))
                        .filter(item => item.id !== currentUserId);

                    this.usersList.unshift(currentUser);
                })
                .catch(error => {
                    this.usersList = [];
                    this.$backendErrors(error, 'Error');
                });

            if (this.$auth.ready()) {
                return promise;
            }

            this.$auth.ready(function () {
                promise;
            });
        },

        loadUserListDebounced: debounce(function () {
            this.loadUserList();
        }, 1000),

        toggle(newState) {
            if (this.usersList.length === 0) {
                this.loadUserList();
            }

            this.isDropdownActive = newState;
        },

        away() {
            this.isDropdownActive = false;
        }
    }
};
</script>
