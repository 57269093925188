import { extend } from 'vee-validate';
import Vue from 'vue';

// Capitalize
Vue.filter('capitalize', function (value) {
    if (!value) {
        return '';
    }

    value = value.toString();

    return value.charAt(0).toUpperCase() + value.slice(1);
});

// Dash if null
Vue.filter('dashIfNull', function (value) {
    const number = Number(value);
    if (value === null || isNaN(value) || number === 0 || number === 0.0) {
        return '-';
    }

    return value;
});

export const integer = value => {
    const number = parseInt(value);
    if (!number) {
        return 0;
    }

    return number.toLocaleString('en-US');
};
// Integer
Vue.filter('integer', integer);

// Finance
Vue.filter('finance', function (value) {
    let number = parseFloat(value);
    if (!number) {
        number = 0.0;
    }

    return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
});

// Date
Vue.filter('date', function (value) {
    const unixTime = Date.parse(value);

    if (!unixTime) {
        return '';
    }

    const dt = new Date(unixTime);

    return dt.toISOString().slice(0, 10);
});

// Date
Vue.filter('date-slash', function (value) {
    const unixTime = Date.parse(value);

    if (!unixTime) {
        return '';
    }

    const dt = new Date(unixTime);

    return dt.toISOString().slice(0, 10).replace(/-/g, '/');
});

// Date
Vue.filter('full_username', function (user) {
    if (typeof user !== 'object') {
        return '';
    }

    const fullName = user.firstName + ' ' + user.lastName + ' (' + user.email + ')';

    return fullName.trim();
});

const roundToDecimals = (value, decimals) => {
    const multiplier = 10 ** decimals;
    const int = String(value * multiplier).split('.')[0];
    return int / multiplier;
};
export const float = (value, decimals) => {
    let number = parseFloat(value);
    if (!number) {
        number = 0.0;
    }

    let dec = 2;
    if (decimals) {
        dec = decimals;
    }
    number = roundToDecimals(number, dec);
    return number.toLocaleString('en-US', { minimumFractionDigits: dec, maximumFractionDigits: dec });
};

Vue.filter('float', float);

Vue.filter('percent', function (value, decimals) {
    return float(value, decimals) + ' %';
});

// Coordinates
Vue.filter('coordinates', function (value) {
    let number = parseFloat(value);
    if (!number) {
        number = 0.0;
    }

    return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
});

// Coordinates with KM
Vue.filter('coordinates_km', function (value) {
    let number = parseFloat(value);
    if (!number) {
        number = 0.0;
    }

    return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' km';
});

Vue.filter('convertComa', value => {
    const result = +value.toString().replace(',', '.');
    return isNaN(result) ? value : result;
});

export const filterFloorCPM = () => {
    extend('floorCPMRule', {
        validate: value => {
            if (value < 0.01) {
                return false;
            }
            const tester = /^\d*\.?\d{1,2}$/;
            const isMatch = !tester.test(String(value));

            return !isMatch;
        }
    });
};
