<template>
    <div id="groups-filter-container" class="filter-wrap">
        <div class="top">
            <div class="button-wrap">
                <DspButton @click="showHide" id="show-groups-filter-button" :class="[btnClass, { active: show }]">
                    {{ btnText }}
                </DspButton>
                <div v-show="show" v-on-clickaway="away" class="filter-dropdown">
                    <DspInput
                        v-show="!activeMenu"
                        v-model="search"
                        @changed="search = $event"
                        class="search-input"
                        placeholder="Search"
                    />
                    <DspInput
                        v-show="activeMenu"
                        v-model="subMenuSearch"
                        @changed="searchChanged"
                        class="search-input"
                        placeholder="Search"
                    />
                    <PerfectScrollbar ref="filterScrollbar" class="dropdown-scroll">
                        <Transition name="slide-fade">
                            <ul v-show="!activeMenu" class="menu">
                                <li v-for="item in filteredItems" :key="item.field" @click="selectMenu(item)">
                                    {{ item.label }}
                                </li>
                            </ul>
                        </Transition>
                        <Transition name="slide-fade">
                            <ul v-show="activeMenu" class="inner-menu">
                                <li @click="filterChanged">
                                    <svg
                                        width="6"
                                        height="10"
                                        viewBox="0 0 6 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M5.31606 8.30762L4.43218 9.1915L0.240235 4.99956L4.43218 0.807616L5.31606 1.6915L2.008 4.99956L5.31606 8.30762Z"
                                            fill="#740B99"
                                        />
                                    </svg>
                                    <span>Back</span>
                                </li>
                                <li v-for="(item, index) in filteredSubmenuItems" :key="index">
                                    <DspCheckbox
                                        :isChecked="item.checked"
                                        :inpLabel="String(item.label)"
                                        @change="item.checked = $event"
                                    />
                                </li>
                            </ul>
                        </Transition>
                    </PerfectScrollbar>
                </div>
            </div>

            <div class="result">
                <div v-for="(item, id) in value" :key="id" class="item">
                    <span>{{ item.group.label }}: </span>
                    <em>{{ item.values.map(item => item.label).join(', ') }}</em>
                    <div
                        @click="
                            $delete(value, id);
                            uncheckValue(item);
                        "
                        class="remove"
                    >
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M6.00008 7.41429L1.70718 11.7072L0.292969 10.293L4.58586 6.00008L0.292969 1.70718L1.70718 0.292969L6.00008 4.58586L10.293 0.292969L11.7072 1.70718L7.41429 6.00008L11.7072 10.293L10.293 11.7072L6.00008 7.41429V7.41429Z"
                                fill="#637381"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import debounce from 'debounce';

export default {
    name: 'FilterDropdownComponent',

    mixins: [clickaway],

    props: {
        btnText: {
            type: String
        },

        btnClass: {
            default: 'primary',
            type: String
        },

        value: {
            type: Object
        },

        items: {
            type: Array
        },

        getValues: {
            type: Function
        },

        externalSearch: {
            type: Boolean,
            default: false
        }
    },

    data: function () {
        return {
            show: false,
            search: '',
            activeMenu: null,

            subMenuSearch: '',
            submenuItems: []
        };
    },

    computed: {
        itemsLocal() {
            return this.items.filter(item => item.field !== 'date');
        },

        filteredItems() {
            if (!this.search) {
                return this.itemsLocal;
            }

            const callback = this.getCallbackForFiltered(this.search.toUpperCase());
            return this.itemsLocal.filter(callback);
        },

        filteredSubmenuItems() {
            if (!this.subMenuSearch) {
                return this.submenuItems.slice(0, 50);
            }

            const callback = this.getCallbackForFiltered(this.subMenuSearch.toUpperCase());
            return this.submenuItems.filter(callback).slice(0, 50);
        }
    },

    methods: {
        away(event) {
            if (event.target.id === 'show-groups-filter-button') {
                return;
            }

            if (this.activeMenu) {
                this.filterChanged();
            }

            this.show = false;
        },

        showHide() {
            if (this.show) {
                this.show = false;
            } else {
                this.show = true;
                this.activeMenu = null;
            }
        },

        async selectMenu(item) {
            this.submenuItems = await this.getValues(item, '');
            this.activeMenu = item;

            this.$nextTick(() => {
                this.$refs.filterScrollbar.$el.scrollTop = 0;
            });
        },

        filterChanged() {
            const values = this.submenuItems.filter(item => item.checked);

            if (values.length) {
                this.$set(this.value, this.activeMenu.field, {
                    group: this.activeMenu,
                    values: values
                });
            } else {
                this.$delete(this.value, this.activeMenu.field);
            }

            this.$emit('input', Object.assign({}, this.value));

            this.activeMenu = null;
            this.submenuItems = [];
            this.subMenuSearch = '';
        },

        async searchChanged(event) {
            this.subMenuSearch = event;

            if (!this.externalSearch) {
                return;
            }
            if (!event || event.length < 2) {
                return;
            }

            this.searchDebounced(event);
        },

        searchDebounced: debounce(async function (search) {
            this.submenuItems = await this.getValues(this.activeMenu, search);
        }, 1000),

        uncheckValue(item) {
            item.values.map(entity => {
                entity.checked = false;
            });

            this.$emit('input', Object.assign({}, this.value));
        },

        /**
         * Возвращает callback для фильтрации.
         *
         * @param {string} search Значение поиска.
         *
         * @return CallableFunction
         */
        getCallbackForFiltered(search) {
            return item => String(item.label).toUpperCase().includes(search);
        }
    }
};
</script>

<style lang="scss" scoped>
.filter-wrap {
    .button {
        padding-right: 30px;

        &:after {
            transition: 0.5s;
            content: '';
            width: 9px;
            height: 5px;
            right: 13px;
            top: 15px;
            position: absolute;
            background: url(@/images/filter-arrow.svg) 50% 50% no-repeat;
            background-size: 100%;
        }

        &.active:after {
            transform: rotateZ(180deg);
        }
    }
}
</style>

<style lang="scss">
@import '../../scss/variables';

.dropdown-scroll {
    max-height: 250px;
    padding: 0 20px 0 0;

    .ps__rail-y {
        &:hover {
            background: none;

            .ps__thumb-y {
                width: 3px;
            }
        }

        .ps__thumb-y {
            width: 3px;
            background-color: #8592a5;
            border-radius: 3px;
            opacity: 1;
        }
    }
}
</style>
