import moment, { Moment } from 'moment';

interface Range {
    rangeName: string;
    fromDate: string;
    toDate: string;
}
interface RangeMoment {
    rangeName: string;
    fromDate: Moment;
    toDate: Moment;
}
interface SampleLogger {
    dateRange: Range | RangeMoment;
    perPage: number;
    hasBidResponses: number;
}

export default {
    namespaced: true,

    state: {
        dateRange: {
            rangeName: 'Today',
            fromDate: moment().format('YYYY-MM-DD'),
            toDate: moment().format('YYYY-MM-DD')
        },
        perPage: 10,
        hasBidResponses: 1
    },

    getters: {
        getDateRange: (state: SampleLogger) => ({
            rangeName: state.dateRange.rangeName ? state.dateRange.rangeName : 'Today',
            fromDate: moment(state.dateRange.fromDate, 'YYYY-MM-DD'),
            toDate: moment(state.dateRange.toDate, 'YYYY-MM-DD')
        }),

        getPerPage: (state: SampleLogger) => state.perPage,
        getHasBidResponses: (state: SampleLogger) => state.hasBidResponses
    },

    actions: {},
    mutations: {
        setDateRange(state: SampleLogger, value: RangeMoment) {
            state.dateRange = {
                fromDate: value.fromDate.format('YYYY-MM-DD'),
                toDate: value.toDate.format('YYYY-MM-DD'),
                rangeName: value.rangeName
            };
        },

        setPerPage: (state: SampleLogger, value: number) => (state.perPage = value),
        setHasBidResponses: (state: SampleLogger, value: number) => (state.hasBidResponses = value)
    }
};
