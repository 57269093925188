<template>
    <div :class="[{ 'has-error': errorsBag.length > 0 }]" class="input-wrap">
        <BFormSelect
            v-model="localValue"
            :options="options"
            :disabled="isDisabled"
            :placeholder="placeholder"
            v-bind="$attrs"
        />
        <div v-show="errorsBag.length > 0" class="help-text">{{ errorsBag[0] }}</div>
    </div>
</template>

<script>
import { BFormSelect } from 'bootstrap-vue';
export default {
    name: 'SelectComponent',

    components: { BFormSelect },
    inheritAttrs: false,
    props: {
        value: {
            type: [String, Number, Object]
        },

        options: {
            default: () => [],
            type: Array
        },

        isDisabled: {
            default: false,
            type: Boolean
        },

        placeholder: {
            type: String
        },

        errorsBag: {
            type: Array,
            name: 'errors',
            default() {
                return [];
            }
        }
    },

    data() {
        return {
            localErrors: this.errorsBag
        };
    },

    computed: {
        localValue: {
            get() {
                return this.value;
            },

            set(value) {
                this.$emit('input', value);
            }
        }
    },

    created: function () {
        this.localErrors = this.errorsBag;
    }
};
</script>

<style lang="scss" scoped>
.input-wrap {
    position: relative;

    .custom-select {
        background: none;
        cursor: pointer;
        box-shadow: inset 0 1px 2px rgb(102 113 123 / 21%);
    }

    &:after {
        transition: 0.5s;
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        right: 10px;
        top: 50%;
        margin-top: -8px;
        background: url(@/images/chevron-down.svg) 50% 50% no-repeat;
        pointer-events: none;
    }

    &:active:after {
        transform: rotateZ(180deg);
    }
}
</style>
