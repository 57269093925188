<template>
    <VSelect
        class="multiselect"
        multiple
        v-model="localValue"
        :options="options"
        :disabled="isDisabled"
        :placeholder="placeholder"
        :label="label"
        :name="name"
        :close-on-select="closeOnSelect"
        :class="groupByClass"
        @search="$emit('search', $event)"
        @open="setToggleShow(true)"
        @close="setToggleShow(false)"
    />
</template>

<script>
import _ from 'lodash';

export default {
    name: 'MultiselectComponent',

    props: {
        name: {
            type: String
        },

        value: {
            type: Array
        },

        options: {
            default: () => [],
            type: Array
        },

        isDisabled: {
            default: false,
            type: Boolean
        },

        placeholder: {
            type: String
        },

        errorsBag: {
            type: Object,
            name: 'errors'
        },

        groupByClass: {
            default: () => 'group-by-select',
            type: String
        },

        label: {
            type: String,
            default: 'label'
        },

        closeOnSelect: {
            type: Boolean,
            default: true
        }
    },

    data: function () {
        return {
            localErrors: this.errorsBag,
            valueAfterOpened: {},
            isShow: false
        };
    },

    computed: {
        localValue: {
            get() {
                return this.value;
            },

            set(value) {
                this.$emit('input', value);

                if (!this.isShow) {
                    this.$emit('change-after-close', value);
                }
            }
        }
    },

    created: function () {
        this.localErrors = this.errorsBag || this.errors;
    },

    methods: {
        setToggleShow(value) {
            this.isShow = value;
        }
    },

    watch: {
        isShow(value) {
            if (value) {
                this.valueAfterOpened = _.cloneDeep(this.value);
            } else if (!value && !_.isEqual(this.value, this.valueAfterOpened)) {
                this.$emit('change-after-close', this.value);
            }
        }
    }
};
</script>

<style lang="scss">
.multiselect {
    .clean-select {
        background: none;
        padding-left: 10px;
    }

    .vs__dropdown-option--selected {
        span {
            color: #c9c9c9;
        }
    }
}
</style>
