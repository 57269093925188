<template>
    <div class="metrix-box">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'MetrixBoxComponent'
};
</script>

<style lang="scss">
@import '../../scss/variables';

.metrix-box {
    width: 100%;
    position: relative;
    min-height: 75px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    @include media(md) {
        grid-template-columns: repeat(4, 1fr);
    }

    .options.alt {
        width: 40px;
        height: 40px;
        position: absolute;
        top: -20px;
        right: -20px;
        padding: 10px;
        text-align: center;
        border: 0;

        .dropdown-trigger {
            width: 18px;
            height: 18px;
            display: inline-block;

            a {
                position: static;

                .icon {
                    position: static;

                    svg {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}
</style>
