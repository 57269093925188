<template>
    <div class="input-group">
        <div class="input-group-prepend">
            <div class="text">{{ prepend }}</div>
        </div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'InputGroupComponent',

    props: {
        prepend: {
            type: String
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../scss/variables';

.input-group {
    &.full-input-group {
        .input-group-prepend {
            flex: 0 0 100px;
        }
    }
}

.input-group {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

    .input-group-prepend {
        display: flex;
        background: #f6f6f8;
        border: solid #c4cdd5;
        border-width: 1px 0 1px 1px;
        box-sizing: border-box;
        box-shadow: inset 0 1px 2px rgba(102, 113, 123, 0.21);
        border-radius: 5px 0 0 5px;
        flex: 0 0 42px;
        align-content: center;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        line-height: 20px;
        color: $color-secondary;
        margin-right: -3px;
    }
}
</style>
