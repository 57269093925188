<template>
    <div class="header-dropdown-notification" v-bind:class="{ 'is-active': isDropdownActive }" v-on-clickaway="away">
        <div class="dropdown-trigger" @click="isDropdownActive = !isDropdownActive">
            <a aria-haspopup="true" aria-controls="{ 'dropdown-menu'}">
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.0098 7.84323C12.1945 6.63744 13.2276 5.69995 14.4992 5.69995C15.7713 5.69995 16.8046 6.63814 16.9887 7.84453C19.4236 8.88648 20.6742 11.3519 20.6742 13.9744V17.5422L21.7546 18.6332C21.7547 18.6333 21.7546 18.6332 21.7546 18.6332C22.1035 18.9855 22.2992 19.4613 22.2992 19.9571C22.2992 20.9961 21.4569 21.8384 20.4178 21.8384H17.3851C17.1571 23.2235 15.9587 24.2999 14.4992 24.2999C13.0288 24.2999 11.8396 23.2205 11.6131 21.8384H8.58059C7.54152 21.8384 6.69922 20.9961 6.69922 19.9571C6.69922 19.4613 6.89486 18.9856 7.24375 18.6333C7.24377 18.6333 7.24373 18.6333 7.24375 18.6333L8.32422 17.5422V13.9744C8.32422 11.3462 9.5675 8.88384 12.0098 7.84323ZM14.4992 6.99995C13.8254 6.99995 13.2804 7.54908 13.2804 8.23072C13.2804 8.38773 13.228 8.54033 13.1286 8.67059L13.1276 8.67183C13.0333 8.79473 12.9007 8.89287 12.7461 8.94949C10.7484 9.68126 9.62422 11.6457 9.62422 13.9744V17.6656C9.62422 17.9291 9.52013 18.1818 9.33503 18.369L8.16749 19.548C8.05978 19.6567 7.99922 19.8038 7.99922 19.9571C7.99922 20.2782 8.25951 20.5385 8.58059 20.5385H12.8742V21.3589C12.8742 22.2622 13.598 23 14.4992 23C15.3924 23 16.1242 22.262 16.1242 21.3589V20.5385H20.4178C20.7389 20.5385 20.9992 20.2782 20.9992 19.9571C20.9992 19.8038 20.9387 19.6568 20.831 19.5481L19.6637 18.3692C19.4785 18.182 19.3742 17.9291 19.3742 17.6656V13.9744C19.3742 11.6539 18.2437 9.68339 16.2521 8.95016C16.0986 8.89361 15.9647 8.79541 15.8695 8.67025C15.7733 8.54375 15.7179 8.39206 15.7179 8.23072C15.7179 7.54903 15.173 6.99995 14.4992 6.99995Z"
                        fill="#C2CAD8"
                    />
                </svg>

                <span class="counter" :class="{ has: unreadDisplay > 0 }">{{ unreadDisplay }}</span>
            </a>
        </div>

        <div class="dropdown-menu" role="menu">
            <div class="dropdown-content notification" v-loading="countRequests">
                <div class="top">
                    <div class="title">Notifications</div>
                    <a v-if="unread.length" @click="setReadAll" class="mark-all">Mark all as read</a>
                    <div class="only-unread title">
                        Only unread
                        <DspSwitcher :enabled="onlyUnread" @change="setOnlyUnread()" />
                    </div>
                </div>

                <PerfectScrollbar v-if="items.length" @ps-y-reach-end="get()" class="note-list m-0 p-0">
                    <div>
                        <div
                            v-for="(notification, index) in items"
                            :key="index"
                            class="item"
                            @click="!notification.read && setRead(notification.id, index)"
                            :class="{ unread: !notification.read, new: notification.new }"
                        >
                            <div class="info">
                                <div :class="`text-${notification.type.color}`" class="name">
                                    {{ notification.type.name }}
                                </div>
                                <div class="date">{{ notification.created }}</div>
                            </div>
                            <div v-if="notification.type.id === types.updatesAreAvailable" class="text">
                                {{ notification.data.replace(/Changelog version.+$/, '') }}
                                <RouterLink :to="{ name: 'changelogs' }" @click.native="away">
                                    Changelogs version {{ notification.details.version }}
                                </RouterLink>
                                are available.
                                {{ notification.details.title }}
                            </div>
                            <div v-else class="text">{{ notification.data }}</div>
                        </div>
                    </div>
                </PerfectScrollbar>

                <div v-else-if="countRequests" style="height: 200px"></div>

                <div v-else class="text-center m-3"><small>You dont have notifications.</small></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mixin as clickAway } from 'vue-clickaway';
import moment from 'moment';

export default {
    name: 'TopNotificationComponent',

    mixins: [clickAway],

    data() {
        return {
            intervalID: null,
            countRequests: 0,
            timeout: (process.env.VUE_APP_USER_NOTIFICATIONS_TIMEOUT_MINUTES || 3) * 60000,
            isDropdownActive: false,
            onlyUnread: true,

            unread: [],
            read: [],
            excludesUUID: [],
            pagination: {
                currentPage: 1,
                limit: 10,
                endResult: false,
                unread: 0
            },

            types: {
                updatesAreAvailable: 13
            }
        };
    },

    mounted() {
        this.get(true);
        this.getUnreadCount();
        this.intervalID = setInterval(() => this.getUnread(), this.timeout);
    },

    destroyed() {
        clearInterval(this.intervalID);
    },

    computed: {
        items() {
            return this.onlyUnread ? this.unread : this.unread.concat(this.read);
        },

        unreadDisplay() {
            return this.pagination.unread < 99 ? this.pagination.unread : 99;
        },

        deniedGetting() {
            return this.pagination.endResult || this.countRequests || !this.isDropdownActive;
        }
    },

    methods: {
        away() {
            this.isDropdownActive = false;
        },

        get(firstLoading = null) {
            if (this.deniedGetting && !firstLoading) {
                return false;
            }

            const params = new URLSearchParams();
            params.append('page', this.pagination.currentPage);
            params.append('limit', this.pagination.limit);
            params.append('onlyUnread', String(Number(this.onlyUnread)));
            this.excludesUUID.length && this.excludesUUID.map(element => params.append('excludesUUID[]', element));

            this.countRequests++;
            this.$axios
                .get('/notifications/with-filters', { params })
                .then(response => {
                    const data = response.data.data;
                    this.pagination.endResult = data.length !== this.pagination.limit;
                    data.forEach(element => (element.read ? this.read.push(element) : this.unread.push(element)));
                    this.read.sort((a, b) => (a.created < b.created ? 1 : b.created < a.created ? -1 : 0));
                    this.pagination.currentPage++;
                })
                .catch(() => {
                    this.pagination.endResult = true;
                    this.pagination.currentPage = 0;
                })
                .finally(() => {
                    this.countRequests--;
                });
        },

        getUnread() {
            if (this.countRequests) {
                return false;
            }

            const params = new URLSearchParams();
            this.unread.length && params.append('lastDate', this.unread[0].created);

            this.countRequests++;
            this.$axios
                .get('/notifications/unread', { params })
                .then(response => {
                    if (response.data.data.length) {
                        const result = response.data.data.map(element => ({ ...element, new: true }));
                        this.unread = result.concat(this.unread);
                        this.getUnreadCount();
                        this.getLack(this.items.length);
                    }
                })
                .finally(() => {
                    this.countRequests--;
                });
        },

        getUnreadCount() {
            this.countRequests++;
            this.$axios
                .get('/notifications/unread-count')
                .then(response => {
                    this.pagination.unread = response.data;
                })
                .catch(() => {
                    this.pagination.unread = 0;
                })
                .finally(() => {
                    this.countRequests--;
                });
        },

        getLack(offset) {
            if (!Number.isInteger(offset)) {
                this.globalToastError('Offset must be integer.');
                return false;
            }

            this.pagination.currentPage = Math.ceil(offset / this.pagination.limit);
            const params = new URLSearchParams();
            params.append('page', this.pagination.currentPage);
            params.append('offset', offset);
            params.append('limit', this.pagination.limit);
            params.append('onlyUnread', String(Number(this.onlyUnread)));
            this.excludesUUID.length && this.excludesUUID.map(element => params.append('excludesUUID[]', element));

            this.countRequests++;
            this.$axios
                .get('/notifications/with-filters', { params })
                .then(response => {
                    const data = response.data.data;
                    const limit = this.pagination.currentPage * this.pagination.limit - offset;
                    this.pagination.endResult = data.length !== limit;
                    data.forEach(element => (element.read ? this.read.push(element) : this.unread.push(element)));
                    this.pagination.currentPage++;
                })
                .catch(() => {
                    this.pagination.endResult = true;
                    this.pagination.currentPage = 0;
                })
                .finally(() => {
                    this.countRequests--;
                });
        },

        setRead(UUID, index) {
            this.countRequests++;
            this.$axios
                .patch(`/notifications/${UUID}/read`)
                .then(response => {
                    this.excludesUUID.push(UUID);
                    const notification = response.data;
                    this.unread.splice(index, 1);

                    this.read = this.read.concat(notification);
                    this.read.sort((a, b) => (a.created < b.created ? 1 : b.created < a.created ? -1 : 0));
                    this.pagination.unread--;
                })
                .finally(() => {
                    this.countRequests--;
                });
        },

        setReadAll() {
            if (!this.items.length) {
                return false;
            }

            this.countRequests++;
            this.$axios
                .put('/notifications/read-all')
                .then(() => {
                    const now = moment().format('YYYY-MM-DD HH:mm:ss');
                    const unread = this.unread.map(element => ({ ...element, read: now, new: null }));
                    this.unread = [];

                    this.read = this.read.concat(unread);
                    this.read.sort((a, b) => (a.created < b.created ? 1 : b.created < a.created ? -1 : 0));
                    this.pagination.unread = 0;
                })
                .finally(() => {
                    this.countRequests--;
                });
        },

        resetPagination() {
            this.unread = [];
            this.read = [];
            this.excludesUUID = [];
            this.pagination.currentPage = 1;
            this.pagination.endResult = false;
            this.pagination.unread = 0;
            this.get(true);
            this.getUnreadCount();
        },

        setOnlyUnread() {
            this.onlyUnread = !this.onlyUnread;
            this.resetPagination();
        }
    },

    watch: {
        countRequests(value) {
            if (value < 0) {
                this.countRequests = 0;
            }
        }
    }
};
</script>
