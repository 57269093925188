export default {
    namespaced: true,

    state: {
        draftDeclinedComment: {}
    },

    getters: {
        getDraftDeclinedComment: state => state.draftDeclinedComment,
        getDraftDeclinedCommentByKey: state => key => state.draftDeclinedComment[key]
    },

    mutations: {
        setDraftDeclinedCommentByKey: (state, { value, key }) => (state.draftDeclinedComment[key] = value),
        removeDraftDeclinedCommentByKey: (state, key) => delete state.draftDeclinedComment[key]
    }
};
