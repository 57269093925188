export default {
    namespaced: true,

    state: {
        hasSanctionLocation: ''
    },

    getters: {
        hasSanctionLocation: state => state.hasSanctionLocation
    },

    mutations: {
        setHasSanctionLocation: (state, value) => (state.hasSanctionLocation = value)
    }
};
