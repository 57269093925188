<template>
    <div
        v-if="$auth.ready() && $auth.check()"
        v-bind:class="{ 'is-active': isDropdownActive }"
        v-on-clickaway="away"
        v-loading="$store.state.loadingOriginalUser"
        class="header-dropdown"
    >
        <div class="dropdown-trigger" @click="isDropdownActive = !isDropdownActive">
            <div>
                <div class="is-ellipsis">
                    <span class="name">{{ $auth.getOriginalUser() | full_username }}</span>
                </div>

                <div class="is-ellipsis">
                    <span class="balance">$ {{ $auth.getOriginalUser().balanceEarn }}</span>
                </div>
            </div>

            <span class="dropdown-arrow" />
        </div>

        <div class="dropdown-menu" role="menu">
            <div class="dropdown-content">
                <RouterLink :to="{ name: 'billing' }" class="dropdown-item">
                    <svg width="16" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4.8112 0C5.17939 0 5.47786 0.298477 5.47786 0.666667V15.3333C5.47786 15.7015 5.17939 16 4.8112 16C4.44301 16 4.14453 15.7015 4.14453 15.3333V0.666667C4.14453 0.298477 4.44301 0 4.8112 0Z"
                            fill="#C2CAD8"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M1.02321 3.54535C1.58582 2.98274 2.34888 2.66667 3.14453 2.66667H8.14453C8.51272 2.66667 8.8112 2.96514 8.8112 3.33333C8.8112 3.70152 8.51272 4 8.14453 4H3.14453C2.7025 4 2.27858 4.17559 1.96602 4.48816C1.65346 4.80072 1.47786 5.22464 1.47786 5.66667C1.47786 6.10869 1.65346 6.53262 1.96602 6.84518C2.27858 7.15774 2.7025 7.33333 3.14453 7.33333H6.47786C7.27351 7.33333 8.03658 7.6494 8.59919 8.21201C9.16179 8.77462 9.47787 9.53768 9.47787 10.3333C9.47787 11.129 9.16179 11.892 8.59919 12.4547C8.03658 13.0173 7.27351 13.3333 6.47786 13.3333H0.811198C0.443008 13.3333 0.144531 13.0349 0.144531 12.6667C0.144531 12.2985 0.443008 12 0.811198 12H6.47786C6.91989 12 7.34382 11.8244 7.65638 11.5118C7.96894 11.1993 8.14453 10.7754 8.14453 10.3333C8.14453 9.89131 7.96894 9.46738 7.65638 9.15482C7.34382 8.84226 6.91989 8.66667 6.47786 8.66667H3.14453C2.34888 8.66667 1.58582 8.3506 1.02321 7.78799C0.460602 7.22538 0.144531 6.46232 0.144531 5.66667C0.144531 4.87102 0.460602 4.10796 1.02321 3.54535Z"
                            fill="#C2CAD8"
                        />
                    </svg>
                    <span>Balance</span>
                </RouterLink>
                <a @click="toProfile" class="dropdown-item">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12.4374 13.9317L13.9155 12.4536L13.3949 10.5623L13.6217 10.0207L15.3333 9.05488V6.96463L13.6266 5.99481L13.4036 5.45411L13.9307 3.56189L12.4516 2.08488L10.5601 2.60531L10.0197 2.37871L9.05384 0.666992H6.96358L5.99377 2.37369L5.45319 2.59663L3.56128 2.06886L2.08443 3.54572L2.60495 5.43744L2.37834 5.97789L0.666626 6.94376V9.03341L2.37178 10.0054L2.59497 10.5465L2.06794 12.4384L3.54535 13.9159L5.43708 13.3953L5.97758 13.622L6.94347 15.333H9.03319L10.0044 13.6278L10.5455 13.4046L12.4374 13.9317ZM12.5684 9.08406L11.9872 10.4722L12.4241 12.0594L12.0452 12.4383L10.4613 11.9971L9.07261 12.5698L8.2582 13.9997H7.72191L6.91424 12.5689L5.52767 11.9875L3.93961 12.4245L3.56132 12.0462L4.00254 10.4624L3.42985 9.07375L1.99997 8.2587V7.72235L3.43137 6.91466L4.01277 5.52803L3.57581 3.93997L3.95331 3.56246L5.53714 4.00429L6.92633 3.43136L7.73951 2.00033H8.27525L9.08294 3.43173L10.4696 4.01312L12.0579 3.57608L12.4372 3.95477L11.9961 5.5383L12.5689 6.92736L14 7.74053V8.27628L12.5684 9.08406ZM7.99995 10.667C6.52719 10.667 5.33328 9.47308 5.33328 8.00033C5.33328 6.52757 6.52719 5.33366 7.99995 5.33366C9.47271 5.33366 10.6666 6.52757 10.6666 8.00033C10.6666 9.47308 9.47271 10.667 7.99995 10.667ZM9.33329 8.00033C9.33329 8.7367 8.73634 9.33366 7.99996 9.33366C7.26358 9.33366 6.66663 8.7367 6.66663 8.00033C6.66663 7.26395 7.26358 6.66699 7.99996 6.66699C8.73634 6.66699 9.33329 7.26395 9.33329 8.00033Z"
                            fill="#C2CAD8"
                        />
                    </svg>
                    <span>Settings</span>
                </a>
                <RouterLink :to="{ name: 'changelogs' }" class="dropdown-item">
                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M21 7.00015H8C7.4 7.00015 7 6.60015 7 6.00015C7 5.40015 7.4 5.00015 8 5.00015H21C21.6 5.00015 22 5.40015 22 6.00015C22 6.60015 21.6 7.00015 21 7.00015Z"
                            fill="#C2CAD8"
                        />
                        <path
                            d="M21 13.0001H8C7.4 13.0001 7 12.6001 7 12.0001C7 11.4001 7.4 11.0001 8 11.0001H21C21.6 11.0001 22 11.4001 22 12.0001C22 12.6001 21.6 13.0001 21 13.0001Z"
                            fill="#C2CAD8"
                        />
                        <path
                            d="M21 19.0001H8C7.4 19.0001 7 18.6001 7 18.0001C7 17.4001 7.4 17.0001 8 17.0001H21C21.6 17.0001 22 17.4001 22 18.0001C22 18.6001 21.6 19.0001 21 19.0001Z"
                            fill="#C2CAD8"
                        />
                        <path
                            d="M3 7.00015C2.9 7.00015 2.9 7.00015 2.8 7.00015C2.7 7.00015 2.7 7.00015 2.6 6.90015C2.5 6.90015 2.5 6.80015 2.4 6.80015C2.3 6.80015 2.3 6.70015 2.3 6.70015C2.2 6.60015 2.1 6.50015 2.1 6.40015C2 6.30015 2 6.10015 2 6.00015C2 5.90015 2 5.70015 2.1 5.60015C2.2 5.50015 2.2 5.40015 2.3 5.30015C2.6 5.00015 3 4.90015 3.4 5.10015C3.5 5.20015 3.6 5.20015 3.7 5.30015C3.9 5.50015 4 5.70015 4 6.00015C4 6.30015 3.9 6.50015 3.7 6.70015C3.5 6.90015 3.3 7.00015 3 7.00015Z"
                            fill="#C2CAD8"
                        />
                        <path
                            d="M3 13.0001C2.7 13.0001 2.5 12.9001 2.3 12.7001C2.1 12.5001 2 12.3001 2 12.0001C2 11.9001 2 11.9001 2 11.8001C2 11.7001 2 11.7001 2.1 11.6001C2.1 11.5001 2.2 11.5001 2.2 11.4001C2.2 11.3001 2.3 11.3001 2.3 11.3001C2.4 11.2001 2.5 11.1001 2.6 11.1001C3 10.9001 3.4 11.0001 3.7 11.3001L3.8 11.4001C3.8 11.5001 3.9 11.5001 3.9 11.6001C3.9 11.7001 3.9 11.7001 4 11.8001C4 11.9001 4 11.9001 4 12.0001C4 12.3001 3.9 12.5001 3.7 12.7001C3.5 12.9001 3.3 13.0001 3 13.0001Z"
                            fill="#C2CAD8"
                        />
                        <path
                            d="M3 19.0001C2.7 19.0001 2.5 18.9001 2.3 18.7001C2.1 18.5001 2 18.3001 2 18.0001C2 17.9001 2 17.7001 2.1 17.6001C2.2 17.5001 2.2 17.4001 2.3 17.3001C2.7 16.9001 3.3 16.9001 3.7 17.3001C3.9 17.5001 4 17.7001 4 18.0001C4 18.3001 3.9 18.5001 3.7 18.7001C3.5 18.9001 3.3 19.0001 3 19.0001Z"
                            fill="#C2CAD8"
                        />
                    </svg>
                    <span>Changelogs</span>
                </RouterLink>
                <a href="//smartyads.com/services-privacy-policy" class="dropdown-item" target="_blank">
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8.57646 0.709195C8.72739 0.652599 8.89371 0.652599 9.04463 0.709195L14.378 2.7092C14.6382 2.80677 14.8105 3.05552 14.8105 3.33341V8.00008C14.8105 10.2977 13.2894 12.1336 11.9162 13.3351C11.2156 13.9481 10.5182 14.4273 9.99721 14.7529C9.73603 14.9162 9.51743 15.0419 9.36259 15.1276C9.28513 15.1705 9.2235 15.2034 9.18033 15.2261C9.15875 15.2374 9.14176 15.2462 9.12972 15.2524L9.11538 15.2597L9.11107 15.2618L9.10963 15.2626C9.10942 15.2627 9.10869 15.263 8.81055 14.6667C8.5124 15.263 8.51221 15.2629 8.51199 15.2628L8.51003 15.2618L8.50571 15.2597L8.49138 15.2524C8.47933 15.2462 8.46235 15.2374 8.44076 15.2261C8.3976 15.2034 8.33597 15.1705 8.2585 15.1276C8.10367 15.0419 7.88506 14.9162 7.62388 14.7529C7.10291 14.4273 6.40547 13.9481 5.70488 13.3351C4.33171 12.1336 2.81055 10.2977 2.81055 8.00008V3.33341C2.81055 3.05552 2.98293 2.80677 3.24313 2.7092L8.57646 0.709195ZM8.81055 14.6667L8.51199 15.2628C8.69968 15.3567 8.921 15.3569 9.10869 15.263L8.81055 14.6667ZM8.81055 13.9086C8.9383 13.8362 9.10168 13.7403 9.29055 13.6222C9.76958 13.3229 10.4055 12.8853 11.0382 12.3317C12.3317 11.1999 13.4772 9.70246 13.4772 8.00008V3.79542L8.81055 2.04542L4.14388 3.79542V8.00008C4.14388 9.70246 5.28938 11.1999 6.58288 12.3317C7.21563 12.8853 7.85152 13.3229 8.33055 13.6222C8.51941 13.7403 8.68279 13.8362 8.81055 13.9086Z"
                            fill="#C2CAD8"
                        />
                    </svg>
                    <span>Services Privacy Policy</span>
                </a>
                <a href="//smartyads.com/terms-of-service" class="dropdown-item" target="_blank">
                    <img height="16" src="@/images/svg/terms.svg" alt="terms icon svg" />
                    <span>Terms of Service</span>
                </a>
                <a href="//smartyads.com/terms-of-use" class="dropdown-item" target="_blank">
                    <img height="16" src="@/images/svg/terms.svg" alt="terms icon svg" />
                    <span>Terms of Use</span>
                </a>
                <a href="//smartyads.com/publishers-agreement" class="dropdown-item" target="_blank">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M2.66602 2.66683C2.4892 2.66683 2.31964 2.73707 2.19461 2.86209C2.06959 2.98712 1.99935 3.15668 1.99935 3.3335V7.3335C1.99935 8.9248 2.63149 10.4509 3.75671 11.5761C4.31386 12.1333 4.97529 12.5752 5.70325 12.8768C6.4312 13.1783 7.21142 13.3335 7.99935 13.3335C9.59065 13.3335 11.1168 12.7014 12.242 11.5761C13.3672 10.4509 13.9993 8.9248 13.9993 7.3335V3.3335C13.9993 3.15669 13.9291 2.98712 13.8041 2.86209C13.6791 2.73707 13.5095 2.66683 13.3327 2.66683H2.66602ZM2.66602 1.3335H13.3327C13.8631 1.3335 14.3718 1.54421 14.7469 1.91928C15.122 2.29436 15.3327 2.80306 15.3327 3.3335V7.3335C15.3327 9.27842 14.5601 11.1437 13.1848 12.5189C11.8095 13.8942 9.94427 14.6668 7.99935 14.6668C7.03632 14.6668 6.08272 14.4771 5.193 14.1086C4.30328 13.7401 3.49486 13.1999 2.8139 12.5189C1.43863 11.1437 0.666016 9.27842 0.666016 7.3335V3.3335C0.666016 2.80306 0.876729 2.29436 1.2518 1.91928C1.62687 1.54421 2.13558 1.3335 2.66602 1.3335Z"
                            fill="#C2CAD8"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4.86128 6.19526C5.12163 5.93491 5.54374 5.93491 5.80409 6.19526L7.99935 8.39052L10.1946 6.19526C10.455 5.93491 10.8771 5.93491 11.1374 6.19526C11.3978 6.45561 11.3978 6.87772 11.1374 7.13807L8.47075 9.80474C8.2104 10.0651 7.78829 10.0651 7.52794 9.80474L4.86128 7.13807C4.60093 6.87772 4.60093 6.45561 4.86128 6.19526Z"
                            fill="#C2CAD8"
                        />
                    </svg>
                    <span>Publisher Agreement</span>
                </a>
                <a href="//smartyads.com/ccpa-resource" class="dropdown-item" target="_blank">
                    <img src="@/images/svg/ccpa-dpa.svg" alt="ccpa dpa icon svg" />
                    <span>CCPA Resource</span>
                </a>
                <a href="//smartyads.com/data-processing-addendum" class="dropdown-item" target="_blank">
                    <img src="@/images/svg/ccpa-dpa.svg" alt="ccpa dpa icon svg" />
                    <span>Data Processing Addendum</span>
                </a>
                <a href="/" @click.stop="logout" class="dropdown-item">
                    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.58756 8.67514C7.4165 8.84273 7.4165 9.11399 7.58756 9.28114C7.75865 9.44872 8.03556 9.44872 8.20619 9.28114L11.2245 6.32401C11.3155 6.23485 11.3545 6.11615 11.3483 5.99955C11.3545 5.88255 11.3155 5.76428 11.2245 5.67514L8.20619 2.71843C8.03556 2.55086 7.75819 2.55086 7.58756 2.71843C7.4165 2.88601 7.4165 3.15729 7.58756 3.32441L9.88137 5.57141H0.4375C0.196 5.57143 0 5.76343 0 6C0 6.23657 0.196 6.42857 0.4375 6.42857H9.88137L7.58756 8.67514ZM12.25 0H3.5C2.53356 0 1.75 0.767574 1.75 1.71429V3.42857H2.625V1.71429C2.625 1.24114 3.01656 0.857143 3.5 0.857143H12.25C12.7334 0.857143 13.125 1.24114 13.125 1.71429V10.2857C13.125 10.7589 12.7334 11.1429 12.25 11.1429H3.5C3.01656 11.1429 2.625 10.7593 2.625 10.2857V8.57143H1.75V10.2857C1.75 11.2324 2.53356 12 3.5 12H12.25C13.2164 12 14 11.2324 14 10.2857V1.71429C14 0.767574 13.2164 0 12.25 0Z"
                            fill="#C2CAD8"
                        />
                    </svg>
                    <span>Log Out</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import modalLeave from '@/mixins/modalLeave';

export default {
    name: 'TopUserActionComponent',

    mixins: [clickaway, modalLeave],

    data: function () {
        return {
            isDropdownActive: false
        };
    },

    created() {
        this.$router.beforeEach((to, from, next) => {
            this.isDropdownActive = false;
            next();
        });
    },

    methods: {
        away() {
            this.isDropdownActive = false;
        },

        logout() {
            this.$auth.logout();
        },

        toProfile() {
            if (this.$route.name !== 'profile') {
                this.$router.push({ name: 'profile' }).then(() => {
                    this.setOriginalUser();
                });
            } else if (this.$store.state.hasProfileDifference) {
                this.modalLeave().then(answer => answer && this.setOriginalUser());
            } else {
                this.setOriginalUser();
            }
        },

        setOriginalUser() {
            this.$store.commit('setProfileOriginalUser', true);
            this.$auth.refreshOriginalUser();
        }
    }
};
</script>
