<template>
    <div class="action-panel">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'TableTopActionsComponent'
};
</script>

<style lang="scss">
@import '@/scss/components/action-panel';

.action-panel {
    display: flex;

    & + .action-panel {
        border-top: 1px solid $color-grey;
    }

    @media (max-width: 720px) {
        &.has-column-mobile {
            flex-wrap: wrap;

            .action-panel-group {
                width: 100%;

                &:not(:first-child) {
                    border-top: 1px solid $color-grey;
                    border-left: 0;
                }
            }

            .action-panel-options {
                @include full-width-top-border;
                min-height: 50px;
                border-left: 0;
            }
        }
    }

    &-options {
        width: 50px;
        flex-shrink: 0;
        border-left: 1px solid $color-grey;
    }

    &-group {
        padding: 10px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;

        &:not(:first-child) {
            border-left: 1px solid $color-grey;
        }
    }
}
</style>
