import { Option } from '@/interfaces/select';
import moment from 'moment-timezone';

const zones: Set<string> = new Set();
const countries = moment.tz.countries();

for (const country of countries) {
    moment.tz.zonesForCountry(country).reduce((set, zone) => set.add(zone), zones);
}

export const timezonesValue = [...zones, 'UTC'].sort();
const getName = (timezone: string) => {
    if (timezone === 'UTC') {
        return timezone;
    }

    const offset = moment().tz(timezone).utcOffset() / 60;
    const sign = offset >= 0 ? '+' : '';

    return `${timezone} (UTC${sign}${offset})`;
};

const timezones: Readonly<Option[]> = timezonesValue.map(timezone => ({
    value: timezone,
    text: getName(timezone)
}));
export default timezones;
