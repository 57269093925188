<template>
    <div :class="{ 'has-error': errorsBag.length > 0 }" class="textarea">
        <BFormTextarea
            :value="localValue"
            :placeholder="placeholder"
            @change="$emit('input', $event)"
            @keyup="$emit('keyup', $event.target.value)"
            :disabled="isDisabled"
            :rows="rows"
            :max-rows="maxRows"
            :no-resize="noResize"
        ></BFormTextarea>
        <div v-show="errorsBag.length > 0" class="help-text">{{ errorsBag[0] }}</div>
    </div>
</template>

<script>
import { BFormTextarea } from 'bootstrap-vue';

export default {
    name: 'TextareaComponent',

    components: { BFormTextarea },

    props: {
        value: {
            type: [String, Number]
        },

        isDisabled: {
            default: false,
            type: Boolean
        },

        placeholder: {
            type: String
        },

        errorsBag: {
            type: Array,
            name: 'errors',
            default() {
                return [];
            }
        },

        rows: {
            type: Number,
            default: 3
        },

        maxRows: {
            type: Number,
            default: 3
        },

        noResize: {
            type: Boolean,
            default: true
        }
    },

    data: function () {
        return {
            localErrors: this.errorsBag,
            type_: this.type
        };
    },

    watch: {
        type: function (newVal) {
            this.type_ = newVal;
        }
    },

    computed: {
        localValue() {
            return String(this.value ?? '');
        }
    },

    created: function () {
        this.localErrors = this.errorsBag || this.errors;
    }
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables';

div.textarea {
    width: 100%;

    textarea {
        box-shadow: inset 0 1px 2px rgba(102, 113, 123, 0.21);

        &::placeholder {
            color: $color-grey-dark;
        }
    }

    &.has-error {
        textarea {
            border-color: $color-red;
        }

        .help-text {
            margin-top: 5px;
            padding-left: 20px;
            display: block;
            color: $color-red;
            background: url(@/images/alert-circle.svg) 0 50% no-repeat;
        }
    }
}
</style>
