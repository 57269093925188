<template>
    <div class="progress-bar-wrap" v-bind:class="{ overfilled: isOverfilledLocal }">
        <div class="bar" :style="{ width: progressPercent + '%' }"></div>
    </div>
</template>

<script>
export default {
    name: 'ProgressComponent',
    props: {
        progressPercent: {
            type: [String, Number]
        },

        isOverfilled: {
            Default: false,
            type: Boolean
        }
    },

    watch: {
        isOverfilled: function () {
            this.isOverfilledLocal = this.isOverfilled;
        }
    },

    data: function () {
        return {
            isOverfilledLocal: false
        };
    },

    methods: {}
};
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.progress-bar-wrap {
    min-width: 76px;
    width: 100%;
    height: 2px;
    position: relative;
    border-radius: 1px;
    background: #c2cad8;

    .bar {
        position: absolute;
        left: 0;
        top: 0;
        height: 2px;
        background: $color-primary;
        border-radius: 1px;
        max-width: 100% !important;
    }

    &.overfilled {
        .bar {
            background: #eb5757;
            width: 100% !important;
        }
    }
}
</style>
