<template>
    <div class="header-dropdown-notification" v-bind:class="{ 'is-active': isDropdownActive }" v-on-clickaway="away">
        <div class="dropdown-trigger" @click="isDropdownActive = !isDropdownActive">
            <a aria-haspopup="true" aria-controls="{ 'dropdown-menu'}">
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M20.5855 17.6594C20.113 20.2697 17.9426 22.162 15.3079 22.2596C12.8431 22.3588 10.654 20.895 9.97176 18.7189C9.38952 16.8584 9.84816 14.8148 11.3745 12.4737C11.5745 12.1663 12.0234 11.6044 12.4219 11.1197C12.4382 11.2051 12.4593 11.2897 12.4821 11.3694C12.6545 11.9695 12.8805 12.5509 13.3554 12.7437C13.6441 12.8616 14.1011 12.8681 14.6151 12.2273C15.7283 10.8367 16.2463 8.96479 16.0438 7.07088C19.5088 10.287 21.2051 14.2359 20.5855 17.6594ZM16.5844 6.24148C16.2998 5.98208 15.8956 5.92515 15.5541 6.10243C15.2044 6.28296 15.0109 6.6554 15.0589 7.0506C15.267 8.70788 14.8157 10.4156 13.8537 11.6175C13.783 11.7053 13.7277 11.7589 13.6903 11.7899C13.6342 11.7077 13.5406 11.5207 13.4195 11.1003C13.2853 10.6335 13.2812 9.91952 13.2812 9.9122C13.2804 9.70809 13.1527 9.52594 12.9608 9.45601C12.7697 9.38526 12.5551 9.44218 12.4225 9.59669C12.364 9.665 10.9889 11.2775 10.5562 11.9411C8.86156 14.5417 8.36633 16.8544 9.04128 19.0109C9.82925 21.5294 12.2948 23.2395 15.0881 23.2395C15.1735 23.2395 15.2581 23.2379 15.3443 23.2346C18.4401 23.12 20.991 20.8983 21.5464 17.8334C22.232 14.0383 20.3771 9.70484 16.5844 6.24148Z"
                        fill="#C2CAD8"
                    />
                </svg>

                <span class="counter" :class="{ has: unreadDisplay > 0 }">{{ unreadDisplay }}</span>
            </a>
        </div>

        <div class="dropdown-menu" role="menu">
            <div class="dropdown-content notification" v-loading="countRequests">
                <div class="top">
                    <div class="title">Admin Notifications</div>
                    <div class="only-unread title">
                        Only unread
                        <DspSwitcher :enabled="onlyUnread" @change="setOnlyUnread()"></DspSwitcher>
                    </div>
                </div>

                <PerfectScrollbar v-if="items.length" @ps-y-reach-end="get()" class="note-list m-0 p-0">
                    <div>
                        <div
                            v-for="(notification, index) in items"
                            :key="index"
                            class="item"
                            @click="clickNotification(notification, index)"
                            :class="{ unread: !notification.read, new: notification.new }"
                        >
                            <div class="info">
                                <div :class="`text-${notification.type.color}`" class="name">
                                    {{ notification.type.name }}
                                </div>
                                <div class="date">{{ notification.created }}</div>
                            </div>
                            <div class="text">{{ notification.data }}</div>
                        </div>
                    </div>
                </PerfectScrollbar>

                <div v-else-if="countRequests" style="height: 200px"></div>
                <div v-else class="text-center m-3">You dont have notifications.</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mixin as clickAway } from 'vue-clickaway';

export default {
    name: 'AdminTopNotificationComponent',

    mixins: [clickAway],

    data() {
        return {
            intervalID: null,
            countRequests: 0,
            timeout: (process.env.VUE_APP_ADMIN_NOTIFICATIONS_TIMEOUT_MINUTES || 3) * 60000,
            isDropdownActive: false,
            onlyUnread: true,

            unread: [],
            read: [],
            excludesUUID: [],
            pagination: {
                currentPage: 1,
                limit: 10,
                endResult: false,
                unread: 0
            }
        };
    },

    mounted() {
        this.get(true);
        this.getUnreadCount();
        this.intervalID = setInterval(() => {
            this.getUnread();
            if ((this.$auth.check('admin') || this.$auth.check('root')) && this.inventory.length) {
                this.getActualInventoryWaitingApproved();
            }
        }, this.timeout);
    },

    destroyed() {
        clearInterval(this.intervalID);
    },

    computed: {
        items() {
            return this.onlyUnread ? this.unread : this.unread.concat(this.read);
        },

        unreadDisplay() {
            return this.pagination.unread < 99 ? this.pagination.unread : 99;
        },

        deniedGetting() {
            return this.pagination.endResult || this.countRequests || !this.isDropdownActive;
        },

        inventory() {
            const typeWaitingApprovedID = 4;
            return this.unread.filter(element => +element.type.id === typeWaitingApprovedID);
        }
    },

    methods: {
        away() {
            this.isDropdownActive = false;
        },

        get(firstLoading = null) {
            if (this.deniedGetting && !firstLoading) {
                return false;
            }

            const params = new URLSearchParams();
            params.append('page', this.pagination.currentPage);
            params.append('limit', this.pagination.limit);
            params.append('onlyUnread', String(Number(this.onlyUnread)));
            this.excludesUUID.length && this.excludesUUID.map(element => params.append('excludesUUID[]', element));

            this.countRequests++;
            this.$axios
                .get('/admin/notifications/with-filters', { params })
                .then(response => {
                    const data = response.data.data;
                    this.pagination.endResult = data.length !== this.pagination.limit;
                    data.forEach(element => (element.read ? this.read.push(element) : this.unread.push(element)));
                    this.read.sort((a, b) => (a.created < b.created ? 1 : b.created < a.created ? -1 : 0));
                    this.pagination.currentPage++;
                })
                .catch(() => {
                    this.pagination.endResult = true;
                    this.pagination.currentPage = 0;
                })
                .finally(() => {
                    this.countRequests--;
                });
        },

        getUnread() {
            if (this.countRequests) {
                return false;
            }

            const params = new URLSearchParams();
            this.unread.length && params.append('lastDate', this.unread[0].created);

            this.countRequests++;
            this.$axios
                .get('/admin/notifications/unread', { params })
                .then(response => {
                    if (response.data.data.length) {
                        const result = response.data.data.map(element => ({ ...element, new: true }));
                        this.unread = result.concat(this.unread);
                        this.getUnreadCount();
                        this.getLack(this.items.length);
                    }
                })
                .finally(() => {
                    this.countRequests--;
                });
        },

        getUnreadCount() {
            this.countRequests++;
            this.$axios
                .get('/admin/notifications/unread-count')
                .then(response => {
                    this.pagination.unread = response.data;
                })
                .catch(() => {
                    this.pagination.unread = 0;
                })
                .finally(() => {
                    this.countRequests--;
                });
        },

        getLack(offset) {
            if (!Number.isInteger(offset)) {
                this.globalToastError('Offset must be integer.');
                return false;
            }

            this.pagination.currentPage = Math.ceil(offset / this.pagination.limit);
            const params = new URLSearchParams();
            params.append('page', this.pagination.currentPage);
            params.append('offset', offset);
            params.append('limit', this.pagination.limit);
            params.append('onlyUnread', String(Number(this.onlyUnread)));
            this.excludesUUID.length && this.excludesUUID.map(element => params.append('excludesUUID[]', element));

            this.countRequests++;
            this.$axios
                .get('/admin/notifications/with-filters', { params })
                .then(response => {
                    const data = response.data.data;
                    const limit = this.pagination.currentPage * this.pagination.limit - offset;
                    this.pagination.endResult = data.length !== limit;
                    data.forEach(element => (element.read ? this.read.push(element) : this.unread.push(element)));
                    this.pagination.currentPage++;
                })
                .catch(() => {
                    this.pagination.endResult = true;
                    this.pagination.currentPage = 0;
                })
                .finally(() => {
                    this.countRequests--;
                });
        },

        setRead(UUID, index) {
            this.countRequests++;
            this.$axios
                .patch(`/admin/notifications/${UUID}/read`)
                .then(response => {
                    this.excludesUUID.push(UUID);
                    const notification = response.data;
                    this.unread.splice(index, 1);

                    this.read = this.read.concat(notification);
                    this.read.sort((a, b) => (a.created < b.created ? 1 : b.created < a.created ? -1 : 0));
                    this.pagination.unread--;
                })
                .finally(() => {
                    this.countRequests--;
                });
        },

        resetPagination() {
            this.unread = [];
            this.read = [];
            this.excludesUUID = [];
            this.pagination.currentPage = 1;
            this.pagination.endResult = false;
            this.pagination.unread = 0;
            this.get(true);
            this.getUnreadCount();
        },

        setOnlyUnread() {
            this.onlyUnread = !this.onlyUnread;
            this.resetPagination();
        },

        clickNotification(notification, index) {
            if (notification.type.id === 4) {
                const id = notification.id;
                if (this.$route.query.inventoryID !== id) {
                    this.$router.push({ name: 'admin.inventory', query: { inventoryID: id } });
                }
            } else if (!notification.read) {
                this.setRead(notification.id, index);
            }
        },

        getActualInventoryWaitingApproved() {
            const params = new URLSearchParams();
            const inventoriesID = [];

            this.inventory.map(element => {
                inventoriesID.push(+element.id);
                params.append('inventoriesID[]', element.id);
            });

            this.countRequests++;
            this.$axios
                .get('/admin/notifications/actual-inventory-waiting-approved', { params })
                .then(response => {
                    const actualInventoriesID = response.data.data.map(element => element.id);
                    inventoriesID
                        .filter(id => !actualInventoriesID.includes(id))
                        .forEach(notActualInventoryID => {
                            const index = this.unread.findIndex(elem => +elem.id === +notActualInventoryID);
                            index !== -1 && this.unread.splice(index, 1) && this.pagination.unread--;
                        });
                })
                .finally(() => {
                    this.countRequests--;
                });
        }
    },

    watch: {
        countRequests(value) {
            if (value < 0) {
                this.countRequests = 0;
            }
        }
    }
};
</script>
