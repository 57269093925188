<template>
    <div>
        <div v-if="displayAlertsEndMonth">
            <BToast
                v-if="checkBilling"
                id="check-billing"
                :visible="true"
                solid
                no-auto-hide
                toaster="b-toaster-bottom-right"
                toast-class="alert-custom"
            >
                <div class="alert-custom__icon">
                    <img src="@/images/dollar-red.svg" alt="dollar red" />
                </div>
                <div class="alert-custom__content">
                    <div class="alert-custom__title">Check billing settings</div>
                    <div class="alert-custom__message">
                        Please make sure the stated payment details and selected payment method in the
                        <a class="alert-custom__link" @click="clickLinkInAlert('check-billing', 'billing')">
                            Billing section
                        </a>
                        are correct and up-to-date.
                    </div>
                </div>
                <div>
                    <button @click="$bvToast.hide('check-billing')" type="button" class="close ml-auto mb-1">×</button>
                </div>
            </BToast>
            <BToast
                v-if="completeDetails"
                id="complete-details"
                :visible="true"
                solid
                no-auto-hide
                toaster="b-toaster-bottom-right"
                toast-class="alert-custom"
            >
                <div class="alert-custom__icon">
                    <img src="@/images/card-red.svg" alt="card red" />
                </div>
                <div class="alert-custom__content">
                    <div class="alert-custom__title">Complete your payment details</div>
                    <div class="alert-custom__message">
                        Please complete your payment details in the
                        <a class="alert-custom__link" @click="clickLinkInAlert('complete-details', 'billing')">
                            <span>Billing section</span>
                        </a>
                        so we can deliver payments to your bank account or PayPal ID
                    </div>
                </div>
                <div>
                    <button @click="$bvToast.hide('complete-details')" type="button" class="close ml-auto mb-1">
                        ×
                    </button>
                </div>
            </BToast>
        </div>
        <BToast
            v-if="displayUpdateAdsTxt"
            id="update-ads-txt"
            :visible="true"
            solid
            no-auto-hide
            toaster="b-toaster-bottom-right"
            toast-class="alert-custom"
        >
            <div class="alert-custom__icon">
                <img src="@/images/svg/terms.svg" alt="terms red" />
            </div>
            <div class="alert-custom__content" v-loading="loadingFile">
                <div class="alert-custom__title">Please update your ads.txt file</div>
                <div class="alert-custom__message">
                    Please add SmartyAds’s line in your ads.txt/app-ads.txt file for our partnership's full potential:
                    <br /><b>{{ ourLine }}</b> <a class="alert-custom__link" @click="copy">copy</a><br />
                    For getting an access to our premium buyers, add their lines from the
                    <a class="alert-custom__link" @click="download">file</a> as well
                </div>
            </div>
            <div>
                <button @click="hideUpdateAdsTxt" type="button" class="close ml-auto mb-1">×</button>
            </div>
        </BToast>
    </div>
</template>

<script>
import moment from 'moment';
import { DictionaryService } from '@/services/DictionaryService';
import { namesType } from '@/lib/adsTxt';

export default {
    name: 'TheAlertsComponent',

    data() {
        return {
            authNow: false,
            correctDate: moment().date() >= 25,

            checkBilling: this.$auth.user().availableCashout >= 50,
            completeDetails: false,
            loadingFile: false
        };
    },

    mounted() {
        const authNow = localStorage.getItem('authNow');
        if (authNow) {
            this.authNow = true;
            localStorage.removeItem('authNow');
        }

        this.displayAlertsEndMonth && this.getBilling();
    },

    methods: {
        getBilling() {
            this.$axios.get('/billing/settings').then(response => {
                this.completeDetails = response.data.data.length === 0;
            });
        },

        clickLinkInAlert(alert, route) {
            this.$bvToast.hide(alert);
            this.$router.push({ name: route });
        },

        copy() {
            this.$copyText(this.ourLine).then(() => {
                this.globalToastInfo('Line copied.');
            });
        },

        download() {
            this.loadingFile = true;
            new DictionaryService()
                .getAdxTxt()
                .then(response => {
                    const result = response.data;
                    let content = '';
                    for (const type in result) {
                        content += `${namesType[type]}\n`;
                        content += `${result[type].join('\n')}\n\n`;
                    }
                    content = `${content.trimEnd()}\n`;

                    const url = window.URL.createObjectURL(new Blob([content], { type: 'text/plain' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'ads.txt');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(error => this.globalToastError(error.response.data.message))
                .finally(() => (this.loadingFile = false));
        },

        hideUpdateAdsTxt() {
            this.$bvToast.hide('update-ads-txt');
            this.$store.commit('setNexDisplayingAdsAlert');
        }
    },

    computed: {
        displayAlertsEndMonth() {
            return this.authNow && this.correctDate;
        },

        ourLine() {
            return `smartyads.com, ${this.$auth.user().id}, DIRECT, fd2bde0ff2e62c5d`;
        },

        displayUpdateAdsTxt() {
            const unixNextAlert = this.$store.state.nextDisplayingAdsAlerts;
            return moment().unix() >= unixNextAlert && this.$auth.user().showAdsTxtNotification;
        }
    }
};
</script>

<style lang="scss">
@import '../../scss/variables';

#b-toaster-bottom-right.b-toaster.b-toaster-bottom-right .b-toaster-slot:has(.alert-custom),
#b-toaster-bottom-right.b-toaster.b-toaster-bottom-right .b-toaster-slot .b-toast:has(.alert-custom),
.b-toast .alert-custom {
    max-width: 565px;
}

.b-toast .toast.alert-custom {
    margin: 0 20px 20px 0;

    & .toast-header {
        display: none;
    }

    & .toast-body {
        display: flex;
        padding: 20px;
    }

    & .alert-custom__icon {
        img {
            padding: 16px;
            background: rgba(235, 87, 87, 0.1);
            border-radius: 8px;
            margin-right: 25px;
            width: 56px;
            height: 56px;
            filter: brightness(0) saturate(100%) invert(79%) sepia(74%) saturate(5865%) hue-rotate(324deg)
                brightness(92%) contrast(100%);
        }
    }

    & .alert-custom__content {
        margin-right: 30px;

        & .alert-custom__title {
            font-family: Montserrat, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $color-secondary;
            margin-bottom: 10px;
        }

        & .alert-custom__message {
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #637381;

            & .alert-custom__link {
                text-decoration: underline;
                cursor: pointer;
            }

            & b {
                color: $color-secondary;
            }
        }
    }

    & .close {
        float: initial;
        font-size: 1.5rem;
        line-height: 1;
        color: #000;
        text-shadow: 0 1px 0 $color-white;
        opacity: 0.5;

        &:hover,
        &:focus {
            outline: none;
        }
    }
}
</style>
