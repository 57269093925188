import moment from 'moment-timezone';

export default {
    namespaced: true,

    state: {
        inventory: {
            id: null,
            title: '',
            platform: 'web',
            os: null,
            coppa: 0,
            languageId: 'EN',
            categories: [],
            blockedCategories: [],
            blockedDomains: '',
            source: '',
            subdomains: [],
            status: null,
            storeURL: null,
            isAppCountryRestricted: null,
            appCountry: null
        },
        iosCountries: { value: [], ttl: null }
    },

    getters: {
        getInventory: state => ({
            id: state.inventory.id,
            title: state.inventory.title,
            platform: state.inventory.platform,
            os: state.inventory.os,
            coppa: state.inventory.coppa,
            languageId: state.inventory.languageId,
            categories: state.inventory.categories,
            blockedCategories: state.inventory.blockedCategories,
            blockedDomains: state.inventory.blockedDomains,
            source: state.inventory.source,
            subdomains: state.inventory.subdomains,
            status: state.inventory.status,
            storeURL: state.inventory.storeURL,
            isAppCountryRestricted: state.inventory.isAppCountryRestricted,
            appCountry: state.inventory.appCountry
        }),
        getIOSCountries: state => {
            if (state.iosCountries.ttl && state.iosCountries.ttl < moment().valueOf()) {
                return { value: [], ttl: null };
            }

            return state.iosCountries.value;
        }
    },

    mutations: {
        setInventoryId(state, value) {
            state.inventory.id = value.id;
        },
        setInventoryTitle(state, value) {
            state.inventory.title = value.title;
        },
        setInventoryPlatform(state, value) {
            state.inventory.platform = value.platform;
        },
        setInventoryOS(state, value) {
            state.inventory.os = value.os;
        },
        setInventoryCoppa(state, value) {
            state.inventory.coppa = value.coppa;
        },
        setInventoryLanguageId(state, value) {
            state.inventory.languageId = value.languageId;
        },
        setInventoryCategories(state, value) {
            state.inventory.categories = value.categories;
        },
        setInventoryBlockedCategories(state, value) {
            state.inventory.blockedCategories = value.blockedCategories;
        },
        setInventoryBlockedDomains(state, value) {
            state.inventory.blockedDomains = value.blockedDomains;
        },
        setInventorySource(state, value) {
            state.inventory.source = value.source;
        },
        setInventorySubdomains(state, value) {
            state.inventory.subdomains = value.subdomains;
        },
        setInventoryStatus(state, value) {
            state.inventory.status = value.status;
        },
        setInventoryStoreURL(state, value) {
            state.inventory.storeURL = value.storeURL;
        },
        setInventoryIsAppCountryRestricted(state, value) {
            state.inventory.isAppCountryRestricted = value.isAppCountryRestricted;
        },
        setInventoryAppCountry(state, value) {
            state.inventory.appCountry = value.appCountry;
        },
        resetState(state) {
            state.inventory = {
                id: null,
                title: '',
                platform: 'web',
                os: null,
                coppa: 0,
                languageId: 'EN',
                categories: [],
                blockedCategories: [],
                blockedDomains: '',
                source: '',
                subdomains: [],
                status: null,
                storeURL: null,
                isAppCountryRestricted: null,
                appCountry: null
            };
        },
        setIOSCountries: (state, { value, ttl }) => (state.iosCountries = { value, ttl: moment().valueOf() + ttl })
    }
};
