<template>
    <div @click="onClick" class="input-wrap activator-wrapper">
        <input :value="value" :name="name" v-bind="$attrs" :class="inputClass" class="text-field" type="text" />
        <span v-if="picker.showActivatorBar" class="bar"></span>
        <label v-if="picker.showActivatorLabel">{{ 'Select Date' }}</label>
    </div>
</template>

<script>
export default {
    name: 'CustomActivatorComponent',

    inheritAttrs: false,

    inject: ['picker'],

    props: ['value', 'name', 'errorsBag'],

    data: function () {
        return {
            localErrors: this.errorsBag
        };
    },

    computed: {
        inputClass() {
            return {
                'text-field__filled': this.value && this.value.trim().length > 0
            };
        }
    },

    created: function () {
        this.localErrors = this.errorsBag || this.errors;
    },

    methods: {
        onClick(event) {
            this.$emit('click', event);
        }
    }
};
</script>
