<template>
    <ValidationProvider
        class="app-field-textarea"
        v-slot="{ errors }"
        :name="title ?? 'undefined'"
        :rules="rules"
        :custom-messages="customMessages"
        :vid="vid ?? ''"
    >
        <AppFieldTitle v-if="title" :title="title" :required="required" :tooltip="tooltip" />
        <TextareaComponent
            :value="value"
            :placeholder="placeholder"
            :class="classes"
            @keyup="handler"
            :max-rows="rowTextarea"
            :rows="rowTextarea"
            :errors-bag="errors"
        />
    </ValidationProvider>
</template>

<script setup lang="ts">
import AppFieldTitle from '@/components/shared/AppFieldTitle.vue';
import debounce from 'debounce';
import TextareaComponent from '@/components/shared/Textarea.vue';
import { toRefs } from 'vue';

interface Props {
    title?: string;
    value?: string | number;
    required?: boolean;
    tooltip?: string;
    inputClass?: string;
    inputPlaceholder?: string;
    rules?: { [key: string]: number | string | boolean };
    customMessages?: { [key: string]: string };
    vid?: string;
    delay?: number;
    row?: number;
}

const props = withDefaults(defineProps<Props>(), {
    isSearch: true,
    delay: 0,
    isTitleFullWidth: false
});

const emit = defineEmits(['input']);

const { inputClass, delay } = toRefs(props);

const placeholder = props.inputPlaceholder ?? '';
const rowTextarea = props.row ?? 5;
const classes = [inputClass?.value];

const handler = debounce((value: string) => emit('input', value.trim()), delay.value, { immediate: false });
</script>

<style scoped lang="scss">
@import '@/scss/variables';

.app-field-textarea {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px 20px;

    & .app-field-title {
        flex-shrink: 0;
        width: 100%;
    }
}
</style>

<style lang="scss">
.app-field-textarea textarea {
    min-width: 100px;
    max-width: 350px;
    flex-grow: 1;
}
</style>
