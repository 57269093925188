import Vue from 'vue';
import Vuex from 'vuex';
import inventory from './inventory';
import adminReports from './admin-reports';
import reports from './reports';
import dashboard from './dashboard';
import billing from './billing';
import adminInventory from './admin/inventory';
import adminReportsPixalateFraud from './admin/reports/pixalate-fraud';
import adminSampleLogger from '@/store/admin/sampe-logger';
import adminCridBlacklist from '@/store/admin/cridBlacklist';
import adminPrebidAnalytics from '@/store/admin/prebid-analytics';

import VuexPersistence from 'vuex-persist';

import adminUsers from './admin-users';
import moment from 'moment/moment';
import scheduleReport from '@/store/scheduleReport';

Vue.use(Vuex);

export default new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',

    state: {
        profileOriginalUser: false,
        loadingUserFromSudo: false,
        loadingOriginalUser: false,
        viewUserID: null,
        hasProfileDifference: false,
        showErrorDormant: false,
        errorEmails: {},
        nextDisplayingAdsAlerts: 0,

        sanctionsCountries: ['RU', 'CU', 'IR', 'KP', 'SY', 'BY'],
        sanctionsStates: [
            {
                name: 'Luhansk',
                sanctionsCity: [
                    "Alchevs'k",
                    'Antratsit',
                    'Bryanka',
                    'Kirovsk',
                    'Krasnodon',
                    'Krasnyi Luch',
                    'Luhansk',
                    'Pervomaysk',
                    'Rovenki',
                    "Sverdlovs'k",
                    'Stakhanov'
                ]
            },
            {
                name: 'Donetsk Oblast',
                sanctionsCity: [
                    'Dokuchayevsk',
                    'Donetsk',
                    'Debaltseve',
                    'Horlivka',
                    'Kirovskoe',
                    'Khartsyzsk',
                    'Makiivka',
                    'Snizhne',
                    'Shakhtarsk',
                    'Torez',
                    'Yenakiieve',
                    'Yasinovataya',
                    'Zhdanovka'
                ]
            },
            {
                name: 'Crimea'
            },
            {
                name: 'Republic of Crimea'
            }
        ]
    },

    modules: {
        inventory,
        dashboard,
        reports,
        billing,
        scheduleReport,
        adminUsers,
        adminReports,
        adminInventory,
        adminReportsPixalateFraud,
        adminSampleLogger,
        adminCridBlacklist,
        adminPrebidAnalytics
    },

    plugins: [new VuexPersistence().plugin],

    mutations: {
        setLoadingUserFromSudo: (state, value) => (state.loadingUserFromSudo = value),

        setLoadingOriginalUser: (state, value) => (state.loadingOriginalUser = value),

        setProfileOriginalUser: (state, value) => (state.profileOriginalUser = value),

        setViewUserID: (state, value) => (state.viewUserID = value),

        setHasProfileDifference: (state, value) => (state.hasProfileDifference = value),

        defaultValues: state => {
            state.loadingUserFromSudo = false;
            state.loadingOriginalUser = false;
            state.showErrorDormant = false;
        },

        setShowErrorDormant: (state, value) => (state.showErrorDormant = value),

        addErrorEmail: (state, payload) => {
            state.errorEmails[payload.email.toLowerCase()] = payload.error;
            if (!state.errorEmails.ttl) {
                state.errorEmails.ttl = moment().valueOf() + 86400000;
            }
        },
        setNexDisplayingAdsAlert: state => {
            const date = moment().add(1, 'days').utc().set({ hour: 5, minute: 0, second: 0, millisecond: 0 });
            state.nextDisplayingAdsAlerts = date.unix();
        },

        clearErrorEmails: state => (state.errorEmails = {})
    }
});
