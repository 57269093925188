import Vue from 'vue';

Vue.prototype.$backendErrors = function (error, errorMessage, validationObserver, transformerFields) {
    if (!error.response) {
        return;
    }

    if (error.response.status !== 422) {
        if (error.response.status === 403 && error.response && error.response.data && error.response.data.error) {
            const text = errorMessage ? errorMessage : '';
            this.globalToastError(text + ' ' + (error.response ? error.response.data.error : ''));

            return;
        }
        if (error?.response?.status === 404 && error?.response?.data?.message) {
            const message = error?.response?.data?.message;
            const text = errorMessage ? errorMessage : '';
            this.globalToastError(`${text} ${message}`);
            return true;
        }
        const text = errorMessage ? errorMessage : '';
        this.globalToastError(
            text + ' ' + (error.response ? error.response.status + ' - ' + error.response.statusText : '')
        );

        return;
    }

    const errors = {};
    for (let errorField in error.response.data.errors) {
        const errorMessage = error.response.data.errors[errorField].pop();
        errorField = errorField.replace(/\.\d$/, '');

        if (!validationObserver || !transformerFields) {
            this.globalToastError(errorMessage);
            continue;
        }

        for (const field in transformerFields) {
            if (transformerFields[field] == errorField) {
                errors[field] = [errorMessage];
            }
        }
    }
    validationObserver && validationObserver.setErrors(errors);
};
