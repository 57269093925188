import _ from 'lodash';

export default <Type>(model: Type, old: Type) => {
    for (const element in model) {
        const isPropertyExist = _.hasIn(model, element) && _.hasIn(old, element);
        if (isPropertyExist && !_.isEqual(model[element], old[element])) {
            return true;
        }
    }

    return false;
};
