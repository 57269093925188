<template>
    <div id="app" class="over-wrap app" :class="{ restricted: isPageWithoutPermanentlyContent }">
        <template v-if="hasAuth && !isPageWithoutPermanentlyContent">
            <TheHeaderComponent />
            <AlertsComponent />
        </template>
        <div class="main-content-wrapper" :class="{ 'has-login': isPageWithoutPermanentlyContent }">
            <TheSidebarComponent v-if="hasAuth && !isPageWithoutPermanentlyContent" />
            <div v-if="hasAccess" class="main-content" :class="{ login: isPageWithoutPermanentlyContent }">
                <RouterView v-if="loaded" />

                <footer
                    v-if="loaded && !isPageWithoutPermanentlyContent"
                    class="footer-copy text-center"
                    :class="{ publisher: $auth.check('publisher') }"
                >
                    <a href="https://smartyads.com/ccpa-resource#ccpa-formwrap" target="_blank"
                        >Do not sell My Personal Information</a
                    >
                    <div class="mt-2">2011 - {{ new Date().getFullYear() }} © SmartyAds. All Rights Reserved</div>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import TheHeaderComponent from '@/components/TheHeader.vue';
import AlertsComponent from '@/components/shared/TheAlerts.vue';
import TheSidebarComponent from '@/components/TheSidebar.vue';
import * as Sentry from '@sentry/vue';
import moment from 'moment-timezone';
import { isNumber } from 'lodash';

export default {
    name: 'app',

    components: {
        AlertsComponent,
        TheSidebarComponent,
        TheHeaderComponent
    },

    data: function () {
        return {
            loaded: false,
            appVersion: '{{POST_BUILD_ENTERS_HASH_HERE}}',
            appVersionRegExpForCheck: /\{\{POST_BUILD_ENTERS_HASH_HERE\}\}/,
            timerId: null,
            versionCheckTimeout: (process.env.VUE_APP_CHECK_NEW_SPA_VERSION_TIMEOUT_MINUTES || 20) * 60000
        };
    },

    created() {
        this.$auth.load().then(() => {
            this.loaded = true;
            const viewUserID = this.$store.state.viewUserID;
            if (!this.$auth.check() && viewUserID) {
                this.$store.commit('setViewUserID', null);
            } else if (viewUserID) {
                this.$auth.setUserView(viewUserID);
            }
        });

        const hasAppVersion = this.appVersion.search(this.appVersionRegExpForCheck) === -1;
        if (process.env.NODE_ENV === 'production' && hasAppVersion) {
            this.timerId = setInterval(this.checkRemoteVersion, this.versionCheckTimeout);
        }

        this.$store.commit('inventory/resetState');
        this.$store.commit('defaultValues');

        const ttl = this.$store.state.errorEmails?.ttl;
        if (ttl && ttl < moment().valueOf()) {
            this.$store.commit('clearErrorEmails');
        }
    },

    mounted() {
        const hubSpotScriptLoader = process.env.VUE_APP_HUBSPOT_SCRIPT_LOADER;
        if (hubSpotScriptLoader) {
            this.appendExternalScriptTag(hubSpotScriptLoader, { id: 'hs-script-loader', async: '', defer: '' });
        }

        this.checkTimezones();
    },

    methods: {
        checkVersionHandler() {
            this.timerId = setTimeout(() => {
                this.checkRemoteVersion();

                this.checkVersionHandler();
            }, this.versionCheckTimeout);
        },

        checkRemoteVersion() {
            this.$axios
                .get(document.location.origin + '/' + process.env.BASE_URL + 'version.json', {
                    headers: { 'Cache-Control': 'no-cache' }
                })
                .then(response => {
                    if (!response.data.hash || response.data.hash === this.appVersion) {
                        return;
                    }

                    if (this.timerId) {
                        clearInterval(this.timerId);
                    }

                    this.$router.afterEach(to => {
                        if (to.meta.noRebootWhenUpdatingSPA) {
                            return;
                        }

                        this.$nextTick(() => {
                            document.location.reload(true);
                        });
                    });
                });
        },

        /**
         * Добавляет внешний tag-скрипт.
         *
         * @param {string} src Адрес скрипта.
         * @param {object} extraAttributes={} Дополнительные атрибуты tag'a.
         * @return void
         */
        appendExternalScriptTag(src, extraAttributes = {}) {
            const script = document.createElement('script');
            script.setAttribute('type', 'text/javascript');
            script.setAttribute('src', src);

            for (const key in extraAttributes) {
                script.setAttribute(key, extraAttributes[key]);
            }

            document.body.appendChild(script);
        },

        checkTimezones() {
            let dateRange = this.$store.getters['adminReports/getDateRange'];
            if (isNumber(dateRange.timezone)) {
                dateRange.timezone = 'UTC';
                this.$store.commit('adminReports/setDateRange', dateRange);
            }

            dateRange = this.$store.getters['reports/getDateRange'];
            if (isNumber(dateRange.timezone)) {
                dateRange.timezone = 'UTC';
                this.$store.commit('reports/setDateRange', dateRange);
            }
        }
    },

    computed: {
        isPageWithoutPermanentlyContent() {
            return [
                'login',
                'resetPass',
                'confirmAccount',
                'error404',
                'restricted',
                'restrictedRussia',
                'userGuide',
                'plug.technical'
            ].includes(this.$route.name);
        },

        /**
         * Авторизирован ли пользователь.
         *
         * @return boolean
         */
        hasAuth() {
            return this.$auth.ready() && this.$auth.check();
        },

        /**
         * Есть ли право на отображение.
         *
         * @return boolean
         */
        hasAccess() {
            return (this.$route.meta.auth && this.hasAuth) || !this.$route.meta.auth;
        }
    },

    watch: {
        $route: {
            handler(to) {
                document.title = (to.meta.title ? `${to.meta.title} | ` : '') + 'SmartyAds SSP';
            },

            immediate: true,
            deep: true
        },

        hasAuth() {
            const user = this.$auth.getOriginalUser();
            if (user && Object.keys(user).length) {
                Sentry.setUser({
                    id: user?.id,
                    email: user?.email,
                    username: `${user?.firstName} ${user?.lastName}`
                });
            }
        },

        loaded() {
            this.$nextTick(() => document.getElementById('loading')?.remove());
        }
    }
};
</script>

<style lang="scss">
@import '@/scss/app.scss';

.app {
    .footer-copy {
        margin-top: 30px;
        padding-bottom: 30px;
        font-size: 12px;

        @include media(lg) {
            display: none;
        }

        .login &,
        .login-page & {
            display: none;
        }
    }

    /* ANIMATIONS BEGIN */
    .slide-fade-enter-active {
        transition: all 0.3s ease;
    }

    .slide-fade-leave-active {
        transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
    }

    .slide-fade-enter,
    .slide-fade-leave-to {
        transform: translateX(-50px);
        opacity: 0;
    }

    .bounce-enter-active {
        animation: bounce-in 0.1s;
    }

    .bounce-leave-active {
        animation: bounce-in 0.1s reverse;
    }

    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }

    /* ANIMATIONS END */
}
</style>
