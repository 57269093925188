'use strict';

import Vue from 'vue';
import axios from 'axios';
import store from '@/store';

let sessionID = sessionStorage.getItem('sessionID');
if (!sessionID) {
    const timestamp = Date.now().toString(16);
    const random = Math.random().toString(16).substring(2);
    sessionID = timestamp + random;
    sessionStorage.setItem('sessionID', sessionID);
}

const config = {
    baseURL: process.env.VUE_APP_API_URL || 'http://ai.testsmartyads.com/api/',
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Accept: 'application/json, text/plain, *!/!*',
        'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
        'X-Session-ID': sessionID
    }
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
    function (config) {
        if (store.state.viewUserID) {
            config.headers['SudoUser'] = store.state.viewUserID;
        }

        const xdebugSession = Vue.$cookies.get('XDEBUG_SESSION');
        if (xdebugSession) {
            if (!config.params) {
                config.params = {};
            }

            if (config.params instanceof URLSearchParams) {
                config.params.append('XDEBUG_SESSION', xdebugSession);
            } else {
                config.params.XDEBUG_SESSION = xdebugSession;
            }
        }

        // Do something before request is sent
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
_axios.interceptors.response.use(
    function (response) {
        // Do something with response data
        return response;
    },
    function (error) {
        /**
         * @type VueAuth
         */
        const auth = Vue.auth;
        /**
         * @type VueRouter
         */
        const router = Vue.router;

        if (+error?.response?.status === 403) {
            const data = error.response.data;
            const country = data?.country;

            if (store.state.sanctionsCountries.includes(country)) {
                localStorage.setItem('sanctionCountry', country);
                router.push({ name: 'restricted' + (country === 'RU' ? 'Russia' : '') });
                auth.logout();
            }
            if (data?.error === 'Forbidden. Your account is dormant.' && router.app.$route.name !== 'login') {
                store.commit('setShowErrorDormant', true);
                auth.logout();
            }
        }

        return Promise.reject(error);
    }
);

const axiosPlugin = {
    install(Vue) {
        Vue.axios = _axios;
        window.axios = _axios;
        Object.defineProperties(Vue.prototype, {
            axios: { value: _axios },
            $axios: { value: _axios }
        });
    }
};

Vue.use(axiosPlugin);

export default axiosPlugin;
