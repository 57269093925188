<template>
    <div class="box" :class="boxClass">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'BoxComponent',

    props: {
        boxClass: {
            type: String
        }
    }
};
</script>
