<template>
    <BoxComponent>
        <div class="empty-table" v-if="isBusyChart">
            <img src="@/images/loader_smaller.svg" alt="loader smaller" />
        </div>

        <div v-else>
            <div class="chart-control">
                <div
                    v-for="category in numericFields"
                    :key="category.data"
                    v-bind:class="{ active: selectedChartMetric === category.data }"
                    @click="onChartCategoryChange(category.data)"
                    class="item"
                >
                    <div class="name">{{ category.name }}</div>
                    <div class="value">
                        {{ category.prefix }}
                        <span v-if="category.decimals === 4">{{ chartTotalsLine[category.data] | float(4) }}</span>
                        <span v-else>{{ chartTotalsLine[category.data] | integer }}</span>
                        {{ category.suffix }}
                    </div>
                </div>
                <div class="ml-auto">
                    <DropdownComponent
                        v-if="filterList.length > 2"
                        class="dash-drop"
                        v-model="selectedFilter"
                        :showSearch="false"
                        :leftAlignMenu="true"
                        :options="filterList"
                    >
                        <template v-slot:header="header">
                            <span>{{ header.value.name }}</span>
                        </template>
                        <template v-slot:item="item">
                            <span>{{ item.option.name }}</span>
                        </template>
                    </DropdownComponent>
                </div>
            </div>

            <Highcharts v-if="chartData.series[0].data.length > 0" :options="chartData" ref="chart" />

            <div v-else class="empty-table mt-20">
                <h4>No data to display</h4>
            </div>
        </div>
    </BoxComponent>
</template>

<script>
import { ChartDataService } from '@/services/ChartDataService';

export default {
    name: 'SummaryStatisticWithChartComponent',

    props: {
        fromDate: {
            type: Object
        },

        toDate: {
            type: Object
        },

        filter: {
            type: String
        },

        inventoryId: {
            type: Number,
            default: null
        },

        rangeName: {
            type: String,
            required: true
        }
    },

    data: function () {
        return {
            selectedChartMetric: 'requests',
            numericFields: [
                {
                    name: 'Requests',
                    data: 'requests',
                    decimals: 0,
                    suffix: '',
                    prefix: '',
                    isMetrics: false
                },
                {
                    name: 'Impressions',
                    data: 'impressions',
                    decimals: 0,
                    suffix: '',
                    prefix: '',
                    isMetrics: false
                },
                {
                    name: 'CTR',
                    data: 'ctr',
                    decimals: 4,
                    suffix: '%',
                    prefix: '',
                    isMetrics: false
                },
                {
                    name: 'Revenue',
                    data: 'sspSpend',
                    decimals: 4,
                    suffix: '',
                    prefix: '$',
                    isMetrics: false
                }
            ],

            selectedFilter: {
                id: null,
                name: 'All '
            },

            filterList: [],

            filterListItem: {
                id: null,
                name: 'All '
            },

            /**  line */
            chartOptionsLine: {
                title: '',
                series: [
                    {
                        name: 'Test',
                        data: [],
                        color: '#740B99',
                        lineWidth: 2,
                        tooltip: {
                            // valueDecimals: category.decimals,
                            // valuePrefix: category.prefix,
                            // valueSuffix: category.suffix,
                        },

                        marker: {
                            symbol: 'circle',
                            fillColor: '#740B99',
                            lineWidth: 3,
                            radius: 6,
                            lineColor: '#FFFFFF'
                        }
                    }
                ],

                tooltip: {
                    borderWidth: 1,
                    shared: true,
                    backgroundColor: '#212B36',
                    borderColor: '#212B36',
                    className: 'dash-tooltip',
                    useHTML: true,
                    headerFormat:
                        "<div style='display: flex; flex-direction: column;  align-items: center; width: 175px; height: 60px'>" +
                        '<div style="color:#fff;font-size: 12px; margin-top: 7px; line-height: 20px; display: block; text-align: center; width: 100%">{point.key}</div><br><div style=\'display: flex; justify-items: center; align-items: center;  width: 100%\'>',

                    pointFormat:
                        '<div style=\'color:#fff;font-size:12px; width: 50%; display: flex; align-items: center\'><div style="background-color:{point.color}; margin-right: 8px; display:inline-block; width: 16px; height: 16px; border-radius: 3px; line-height: 20px "></div>' +
                        '<b>{point.y}</b>' +
                        '</div>',

                    footerFormat: '</div></div>'
                },

                xAxis: {
                    categories: [],
                    labels: {
                        y: 50,
                        align: 'center',
                        formatter: function () {
                            return this.value.substring(10);
                        }
                    }
                },

                credits: {
                    enabled: false
                },

                legend: {
                    enabled: false
                },

                yAxis: {
                    title: {
                        text: ''
                    }
                },

                chart: {
                    type: 'spline',
                    minPadding: 0,
                    maxPadding: 0
                }
            },

            chartStatLine: {
                requests: [],
                impressions: [],
                ctr: [],
                sspSpend: []
            },

            chartTotalsLine: {},
            isBusyChart: false
        };
    },

    computed: {
        chartData() {
            const options = this.chartOptionsLine;
            const format = 'YYYY-MM-DD' + (this.fromDate.isSame(this.toDate, 'day') ? ' HH:mm' : '');
            options.series[0].name = this.numericFields.filter(item => item.data === this.selectedChartMetric)[0].name;
            options.series[0].data = this.chartStatLine[this.selectedChartMetric].map(i => i[1]);
            options.xAxis.categories = this.chartStatLine[this.selectedChartMetric].map(i => i[0].format(format));

            return this.chartOptionsLine;
        }
    },

    watch: {
        rangeName() {
            this.getFilterList();
            this.getChartData();
        },

        selectedFilter() {
            this.getChartData();
        }
    },

    async mounted() {
        await this.getFilterList();
        await this.getChartData();
    },

    methods: {
        async getChartData() {
            const dateField = this.toDate.diff(this.fromDate, 'days') === 0 ? 'YMDH' : 'YMD';

            const agregatedFields = this.numericFields.map(item => item.data);
            const filter = {
                originalPubId: this.$auth.user().id
            };
            if (this.selectedFilter.id !== null) {
                filter[this.filter] = this.selectedFilter.id;
            }

            //for placement list
            if (this.inventoryId != null) {
                filter.inventoryId = this.inventoryId;
            }

            this.isBusyChart = true;
            const chartService = new ChartDataService(this.axios)
                .setFromDate(this.fromDate)
                .setToDate(this.toDate)
                .setFilter(filter)
                .setAgregatedFields(agregatedFields)
                .setDateField(dateField)
                .setGroupBy(dateField);
            try {
                await chartService.fetch();
            } catch (e) {
                this.isBusyChart = false;
                this.globalToastError('Data service unavailable');
            }

            this.isBusyChart = false;
            this.chartTotalsLine = chartService.getTotals();
            this.chartStatLine = chartService.getData();
        },

        onChartCategoryChange(categoryData) {
            this.selectedChartMetric = categoryData;
        },

        getFilterList() {
            const params = {
                timezone: 'UTC',
                from: this.fromDate.format('YYYY-MM-DD'),
                to: this.toDate.format('YYYY-MM-DD'),
                groupBy: [this.filter],
                filter: {},
                agregatedFields: [],
                orderField: 'impressions',
                orderDir: 'desc',
                rowsOnPage: 25,
                page: 1
            };
            if (this.inventoryId) {
                params.filter.inventoryId = this.inventoryId;
            }

            this.$axios.post('/statistic', params).then(response => {
                this.filterList = response.data.data.map(item => {
                    return {
                        id: item[this.filter],
                        name: item[this.filter + '_dict']
                    };
                });

                this.filterList.unshift(this.filterListItem);
            });
        }
    }
};
</script>
