<template>
    <div v-bind:class="{ 'is-active': isDropdownActive }" v-on-clickaway="away" class="wrapper dropdown">
        <div class="group-filter-wrap" @click="openDropdown()">
            <div class="label">{{ groupName }}</div>
            <div class="item">
                <a aria-haspopup="true" aria-controls="{'dropdown-menu'}">
                    <slot name="header" :value="searchValue">
                        <span class="value is-ellipsis">{{ searchValue }}</span>
                    </slot>
                </a>
            </div>
        </div>
        <div class="dropdown-box dropdown-menu" role="menu" :class="{ leftAlignMenu: leftAlignMenu }">
            <DspInput :value="search" @keyup="search = $event" class="w-100" :placeholder="'Search'"></DspInput>
            <div class="scroll-area dropdown-content">
                <PerfectScrollbar class="dropdown-scroll">
                    <div class="item" v-for="(option, index) in optionsSearch" :key="index">
                        <BFormCheckbox
                            :checked="selected.indexOf(option.value) !== -1"
                            @change="toggleState($event, option.value)"
                        >
                            {{ option.text }}
                        </BFormCheckbox>
                    </div>
                </PerfectScrollbar>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import { BFormCheckbox } from 'bootstrap-vue';
import { mixin as clickaway } from 'vue-clickaway';

export default {
    name: 'GroupByDropdownComponent',

    components: { BFormCheckbox },

    mixins: [clickaway],

    props: {
        groupName: {
            type: String
        },

        options: {
            type: Array,
            default: () => []
        },

        dropValue: {
            type: [String, Object]
        },

        value: {
            type: Array,
            default: () => []
        },

        leftAlignMenu: {
            type: Boolean,
            default: false
        }
    },

    data: function () {
        return {
            selected: [],
            isDropdownActive: false,
            search: '',
            oldValues: []
        };
    },

    computed: {
        searchValue() {
            return this.value ? this.valueText : this.dropValue;
        },

        valueText: {
            get() {
                const labels = [];

                this.selected.map(item => {
                    this.options.map(option => {
                        if (option.value === item) {
                            labels.push(option.text);
                        }
                    });
                });

                return labels.length > 0 ? labels.join(', ') : this.dropValue;
            }
        },

        optionsSearch: {
            get() {
                return this.options.filter(item => {
                    if (this.search.length > 0) {
                        const index = item.text.toLowerCase().indexOf(this.search.toLowerCase());
                        return index >= 0;
                    }

                    return true;
                });
            }
        }
    },

    watch: {
        isDropdownActive: function (value) {
            if (!value && JSON.stringify(this.oldValues) !== JSON.stringify(this.selected)) {
                this.search = '';
                this.$emit('input', this.selected);
            }
        },

        options(value) {
            !value.length && (this.selected = []);
        },

        value: {
            handler(value) {
                const selected = value.map(item => item?.value ?? item);
                if (!_.isEqual(selected, this.selected)) {
                    this.selected = selected;
                }
            },

            immediate: true
        }
    },

    methods: {
        away() {
            this.isDropdownActive = false;
        },

        toggleState(checked, value) {
            const index = this.selected.indexOf(value);

            if (checked) {
                if (index === -1) {
                    this.selected.push(value);
                }
            } else {
                if (index >= 0) {
                    this.selected.splice(index, 1);
                }
            }
        },

        openDropdown() {
            this.isDropdownActive = !this.isDropdownActive;

            if (this.isDropdownActive) {
                this.oldValues = this.selected.slice(0);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables';
@import '@/scss/fonts';

.wrapper {
    position: relative;

    .group-filter-wrap {
        padding: 4px 40px 4px 16px;
        height: 35px;
        background: linear-gradient($color-white, $color-grey-light);
        border: 1px solid $color-grey;
        box-sizing: border-box;
        border-radius: 5px;
        display: flex;
        font-size: 12px;
        line-height: 20px;
        color: $color-primary;
        align-items: center;
        position: relative;
        cursor: pointer;
        transition: min-width 0.5s;
        min-width: 145px;

        &:after {
            content: '';
            position: absolute;
            right: 10px;
            top: 10px;
            width: 16px;
            height: 16px;
            background: url(@/images/select-bg.svg) 0 0 no-repeat;
            z-index: 1;
        }

        .label {
            color: $color-secondary;
        }

        .item {
            padding-left: 4px;
        }
    }

    &.is-active .group-filter-wrap {
        border: 1px solid $color-primary;
        min-width: 330px;
    }

    .dropdown-box {
        position: absolute;
        padding: 20px;
        background: $color-white;
        border-radius: 5px;
        top: 45px;
        left: 0;
        width: 330px;
        max-height: 330px;
        border: 1px solid $color-grey;
        z-index: 99;

        .scroll-area {
            margin-top: 10px;
            max-height: 255px;
            overflow: hidden;

            .ps {
                padding: 0;
            }

            .item {
                padding: 10px;

                .custom-control {
                    padding-left: 0;
                    font-size: 12px;
                    line-height: 20px;
                    color: $color-secondary;

                    .custom-control-label {
                        font-size: 12px;
                        line-height: 20px;
                        color: $color-secondary;
                    }
                }
            }
        }
    }

    &.is-active {
        .dropdown-menu {
            display: block;
            animation: smooth-opacity 0.5s forwards;
        }

        .dropdown-content {
            display: block;
        }
    }
}

.is-ellipsis {
    @include is-ellipsis;
}

@keyframes smooth-opacity {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
</style>
