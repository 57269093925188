import moment, { Moment } from 'moment';

interface Range {
    rangeName: string;
    fromDate: string;
    toDate: string;
}
interface RangeMoment {
    rangeName: string;
    fromDate: Moment;
    toDate: Moment;
}
interface DateRange {
    dateRange: Range | RangeMoment;
}

export default {
    namespaced: true,

    state: {
        dateRange: {
            rangeName: 'This Month',
            fromDate: moment().format('YYYY-MM-DD'),
            toDate: moment().format('YYYY-MM-DD')
        },
        tableGroupFieldsSelected: ['insertDate'],
        tableNumericFieldsSelected: ['totalScan', 'fraud', 'percent'],
        tableFilter: {}
    },

    getters: {
        getDateRange: (state: DateRange) => ({
            rangeName: state.dateRange.rangeName ? state.dateRange.rangeName : 'Today',
            fromDate: moment(state.dateRange.fromDate, 'YYYY-MM-DD'),
            toDate: moment(state.dateRange.toDate, 'YYYY-MM-DD')
        }),
        getTableGroupFieldsSelected: (state: { tableGroupFieldsSelected: Array<string> }) =>
            state.tableGroupFieldsSelected,
        getTableNumericFieldsSelected: (state: { tableNumericFieldsSelected: Array<string> }) =>
            state.tableNumericFieldsSelected,
        getTableFilter: (state: { tableFilter: object }) => state.tableFilter
    },

    actions: {},
    mutations: {
        setDateRange(state: DateRange, value: RangeMoment) {
            state.dateRange = {
                fromDate: value.fromDate.format('YYYY-MM-DD'),
                toDate: value.toDate.format('YYYY-MM-DD'),
                rangeName: value.rangeName
            };
        },
        setTableGroupFieldsSelected(state: { tableGroupFieldsSelected: Array<string> }, value: Array<string>) {
            state.tableGroupFieldsSelected = value;
        },
        setTableNumericFieldsSelected(state: { tableNumericFieldsSelected: Array<string> }, value: Array<string>) {
            state.tableNumericFieldsSelected = value;
        },
        setTableFilter(state: { tableFilter: object }, value: object) {
            state.tableFilter = value;
        }
    }
};
