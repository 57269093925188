<template>
    <div :class="{ 'has-error': errorsBag.length > 0, [boxClass]: true }" class="input-wrap">
        <input
            :value="localValue"
            :type="type"
            :name="name"
            :disabled="isDisabled"
            :readonly="isReadonly"
            :maxlength="max"
            :placeholder="placeholder"
            :autocomplete="autocomplete ? 'on' : 'off'"
            @change="
                $emit('input', $event.target.value);
                $emit('change', $event.target.value);
            "
            @keyup="$emit('keyup', $event.target.value)"
            @input="$emit('changed', $event.target.value)"
            class="input-text"
            :class="{ widthFillAvailable: fillAvailable }"
        />
        <div v-show="errorsBag.length > 0" class="help-text">{{ errorsBag[0] }}</div>
    </div>
</template>

<script>
export default {
    name: 'InputComponent',

    props: {
        name: {
            type: String
        },

        value: {
            type: [String, Number]
        },

        boxClass: {
            default: 'input-wrap-default',
            type: String
        },

        isDisabled: {
            default: false,
            type: Boolean
        },

        isReadonly: {
            default: false,
            type: Boolean
        },

        placeholder: {
            type: String
        },

        errorsBag: {
            type: Array,
            name: 'errors',
            default() {
                return [];
            }
        },

        type: {
            type: String,
            default: 'text'
        },

        max: {
            type: Number,
            default: 1024
        },

        fillAvailable: {
            type: Boolean,
            default: false
        },

        autocomplete: {
            type: Boolean,
            default: true
        }
    },

    data: function () {
        return {
            localErrors: this.errorsBag,
            type_: this.type
        };
    },

    watch: {
        type: function (newVal) {
            this.type_ = newVal;
        }
    },

    computed: {
        localValue() {
            return this.value;
        }
    },

    created: function () {
        this.localErrors = this.errorsBag;
    }
};
</script>

<style lang="scss" scoped>
@import '../../scss/variables';

.input-wrap {
    &.short {
        ::v-deep .input-text {
            width: 70px;
            min-width: 100%;
        }
    }

    &.w-100 {
        .input-text {
            width: 100%;
            min-width: 100%;
        }
    }

    &.alt-error {
        position: relative;

        &.has-error {
            .help-text {
                position: absolute;
                left: 0;
                bottom: -22px;
                white-space: nowrap;
            }
        }
    }
}

.widthFillAvailable {
    width: 100% !important;
    min-width: 100% !important;
}

.users-filter-input.input-wrap {
    @include mediaDesktop($W1200) {
        .input-text {
            min-width: 200px;
        }
    }
}
</style>
