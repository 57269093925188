import Vue from 'vue';
import routerVueRouter from '@websanova/vue-auth/src/drivers/router/vue-router.2.x';
import axios from '@websanova/vue-auth/src/drivers/http/axios.1.x';
import router from '../router';
import auth from '@websanova/vue-auth/src/v2';
import store from '@/store';

Vue.router = router;

Vue.use(auth, {
    plugins: {
        http: Vue.axios,
        router: Vue.router
    },
    drivers: {
        auth: {
            request(req, token) {
                this.drivers.http.setHeaders.call(this, req, { Authorization: 'Bearer ' + token });
                this.drivers.http.setHeaders.call(this, req, { 'Access-Control-Allow-Origin': '*' });
                this.drivers.http.setHeaders.call(this, req, { 'Access-Control-Allow-Credentials': 'true' });
                this.drivers.http.setHeaders.call(this, req, { 'Access-Control-Max-Age': '1800' });
                this.drivers.http.setHeaders.call(this, req, { 'Access-Control-Allow-Headers': 'content-type' });
                this.drivers.http.setHeaders.call(this, req, {
                    'Access-Control-Allow-Methods': 'PUT, POST, GET, DELETE, PATCH, OPTIONS'
                });

                if (
                    Object.keys(Vue.auth.dataOrig).length > 0 &&
                    Vue.auth.$vm.state.data &&
                    Vue.auth.$vm.state.data.id
                ) {
                    this.drivers.http.setHeaders.call(this, req, { SudoUser: Vue.auth.$vm.state.data.id });
                }
            },

            response: response => response?.data?.access_token
        },
        http: axios,
        router: routerVueRouter
    },
    options: {
        loginData: {
            url: 'users/login',
            method: 'POST',
            redirect: { name: 'dashboard' }
        },
        registerData: {
            url: 'users/signup',
            method: 'POST',
            fetchUser: false,
            autoLogin: false,
            redirect: false
        },
        logoutData: {
            url: 'logout',
            method: 'POST',
            redirect: { name: 'login' },
            makeRequest: false
        },
        fetchData: {
            url: 'users/profile',
            method: 'GET'
        },
        refreshData: {
            url: 'users/token/refresh',
            method: 'POST',
            enabled: true,
            interval: 60
        },

        rolesKey: 'role',
        rememberKey: 'ssp_auth_remember',
        staySignedInKey: 'ssp_auth_stay_signed_in',
        tokenDefaultKey: 'ssp_auth_token_default',
        tokenImpersonateKey: 'ssp_auth_token_impersonate',
        stores: ['cookie'],
        cookie: { Domain: `.${new URL(process.env.VUE_APP_API_URL).hostname.split('.').splice(-2).join('.')}` },

        authRedirect: { name: 'login' },
        parseUserData: user => user
    }
});

Vue.prototype.$auth.dataOrig = Vue.observable({});

Vue.prototype.$auth.getOrigUser = function () {
    return Vue.auth.dataOrig;
};

Vue.prototype.$auth.setOriginalUser = data => {
    Object.entries(data).forEach(([key, value]) => Vue.set(Vue.auth.dataOrig, key, value));
};

Vue.prototype.$auth.setUserView = userID => {
    if (userID !== Vue.auth.getOriginalUser().id) {
        store.commit('setViewUserID', userID);
        store.commit('setLoadingUserFromSudo', true);
        window.axios
            .get(`users/${userID}`)
            .then(response => {
                if (!Object.keys(Vue.auth.dataOrig).length) {
                    Vue.auth.setOriginalUser(Vue.auth.$vm.state.data);
                }
                Vue.auth.$vm.state.data = response.data.data;
                Vue.auth.$vm.state.data.roleView = response.data.data.role;
                Vue.auth.$vm.state.data.role = Vue.auth.dataOrig.role;
            })
            .catch(() => {
                store.commit('setViewUserID', null);
            })
            .finally(() => {
                store.commit('setLoadingUserFromSudo', false);
            });
    } else {
        if (Object.keys(Vue.auth.dataOrig).length) {
            Vue.auth.$vm.state.data = Vue.auth.dataOrig;
            store.commit('setViewUserID', null);
            Vue.auth.dataOrig = {};
        }

        Vue.auth.refreshOriginalUser();
    }
};

Vue.prototype.$auth.refreshOriginalUser = () => {
    store.commit('setLoadingOriginalUser', true);
    window.axios
        .get(`users/${Vue.auth.getOriginalUser().id}`)
        .then(response => {
            if (Vue.auth.isViewOtherUser()) {
                Vue.auth.setOriginalUser(response.data.data);
            } else {
                Vue.auth.$vm.state.data = response.data.data;
            }
        })
        .finally(() => {
            store.commit('setLoadingOriginalUser', false);
        });
};

Vue.prototype.$auth.getOriginalUser = () =>
    Object.keys(Vue.auth.dataOrig).length ? Vue.auth.dataOrig : Vue.auth.user();

Vue.prototype.$auth.isTechnicalRole = () => {
    return ['admin', 'finance', 'root'].includes(Vue['auth'].getOriginalUser().role);
};

Vue.prototype.$auth.isSuspended = () => {
    const auth = Vue['auth'];
    const isCorrectOriginalUser = !Object.keys(auth.getOrigUser()).length || auth.getOrigUser().id === auth.user().id;

    return +auth.user()?.isActive === -1 && isCorrectOriginalUser;
};

Vue.prototype.$auth.isViewOtherUser = () => {
    const auth = Vue['auth'];
    const originalUser = auth.getOrigUser();

    return Object.keys(originalUser).length && originalUser.id !== auth.user().id;
};

router.beforeEach((to, from, next) => {
    if (to.meta.forbiddenForUserSuspended && Vue['auth'].isSuspended()) {
        next(from.path);
    }

    next();
});
