<template>
    <div class="button-group">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'AppButtonGroupComponent'
};
</script>

<style lang="scss">
.button-group {
    position: relative;
    display: inline-flex;
    vertical-align: middle;

    &.tag-btn-group .button {
        min-width: auto;
        padding-block: 5px;
        line-height: 12px !important;

        span {
            font-size: 9px;
        }
    }

    .button {
        flex: 1 1 auto;
        position: relative;

        &:not(:last-child) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &:not(:first-child) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left-color: transparent;

            &:active,
            &:focus,
            &.active {
                border-left-color: inherit;
            }
        }
    }
}
</style>
