import Vue from 'vue';
import en from 'vee-validate/dist/locale/en.json';
import { extend, localize, ValidationObserver, ValidationProvider } from 'vee-validate';
import {
    alpha,
    confirmed,
    double,
    email,
    max,
    max_value,
    min,
    min_value,
    numeric,
    regex,
    required
} from 'vee-validate/dist/rules';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

localize('en', en);

extend('required', required);
extend('alpha', alpha);
extend('email', email);
extend('min', min);
extend('max', max);
extend('min_value', min_value);
extend('max_value', max_value);
extend('confirmed', confirmed);
extend('numeric', numeric);
extend('regex', regex);
extend('double', double);

extend('finance', {
    validate: value => /^\$?\s*?\d*[.|,]?\d*$/gim.test(value)
});

extend('finance_min_value', {
    validate: (value, maxValue) => {
        const valueFloat = parseFloat(value);
        if (isNaN(valueFloat)) {
            return false;
        }

        return valueFloat >= maxValue;
    }
});

extend('finance_min_value_or_null', {
    validate: (value, maxValue) => {
        const valueFloat = parseFloat(value.replace('$', ''));
        if (isNaN(valueFloat)) {
            return false;
        }

        return valueFloat == 0 || valueFloat >= maxValue;
    }
});

extend('numeric_min_value_or_null', {
    validate: (value, maxValue) => {
        const valueFloat = parseFloat(value);
        if (isNaN(valueFloat)) {
            return false;
        }

        return valueFloat == 0 || valueFloat >= maxValue;
    }
});

extend('percent', {
    validate: value => {
        if (!/^\d+[.|,]?\d*\s*%?$/gim.test(value)) {
            return false;
        }

        const markup = parseInt(value.replace(/%/, ''));

        return !isNaN(markup) && markup >= 0 && markup <= 100;
    }
});
