import { NeedHelpParamsDTO } from '@/dto/NeedHelpParamsDTO';
import { AxiosError, AxiosResponse } from 'axios';

const variables = import('@/plugins/global-variables-from-vue-2');

interface Response {
    success: boolean;
    message: string;
}

export interface AxiosErrorNeedHelp extends AxiosError {
    response: AxiosResponse & {
        data: {
            message: string;
            success: boolean;
        };
    };
}

export class NeedHelpService {
    private _url = '/need-help';

    /**
     * Отправляет сообщение Need Help.
     *
     * @param {NeedHelpParamsDTO} params Параметры для запроса в виде NeedHelpParamsDTO.
     *
     * @return Promise<Response>
     */
    public async send(params: NeedHelpParamsDTO): Promise<Response> {
        const $axios = await variables.then(variables => variables.$axios);
        const response = await $axios.post(this._url, { ...params.getObject() });
        return response.data;
    }
}
