import Vue from 'vue';
import { DirectiveBinding } from 'vue/types/options';

Vue.directive('loading', (element: HTMLElement, binding: DirectiveBinding): void => {
    let attribute = element.getAttribute('data-loading');
    if (!attribute) {
        attribute = (Math.random() + 1).toString(36).substring(2).replaceAll(/\d/g, '');
        element.setAttribute('data-loading', attribute);
    }

    const styles = getComputedStyle(element);
    if (styles.position && !element.getAttribute('data-position-inherit')) {
        element.setAttribute('data-position-inherit', styles.position);
    }

    const id = `${attribute}_loading`;
    if (binding.value) {
        element.style.position = 'relative';
        (document.activeElement as HTMLElement)?.blur();

        const style = `border-radius: ${styles.borderRadius ?? 'inherit'}`;
        !element.querySelector(`#${id}`) &&
            element.insertAdjacentHTML('beforeend', `<div id="${id}" style="${style}" class="loading"></div>`);
    } else {
        element.removeAttribute('data-loading');
        element.querySelector(`#${id}`)?.remove();
        element.style.position = element.getAttribute('data-position-inherit') ?? 'initial';
        element.removeAttribute('data-position-inherit');
    }
});
