<template>
    <PickerComponent
        :start-date="dateStart"
        :end-date="dateEnd"
        :opens="opens || 'center'"
        :auto-apply="autoApply"
        :aria-disabled="true"
        :allTimePresets="allTimePresets"
        :presets="rangesLocalized"
        :singleDatePicker="singleDatePicker"
        :showPresets="showPresets"
        :timezone="timezone"
        @change="dateChange($event)"
        ref="VMdDateRangePicker"
        class="with-icon-calendar"
    >
        <template slot="input">
            <CustomActivatorComponent
                :value="value_"
                :errorsBag="errorsBag"
                @click="onActivate"
                ref="defaultActivator"
                name="dateEnd"
                readonly
            />
        </template>
    </PickerComponent>
</template>

<script>
import moment from 'moment-timezone';
import { getRanges, getAllTimeRanges, getAllDateRanges } from '@/lib/periodToDates';
import PickerComponent from '@/components/DatePicker/VMdDateRangePicker2/src/components/Data2Picker.vue';
import CustomActivatorComponent from '@/components/DatePicker/CustomActivator.vue';
import { timezonesValue } from '@/lib/timezones';
import _ from 'lodash';
import getDatesWithTimezone from '@/lib/getDatesWithTimezone';

export default {
    name: 'DateSelectComponent',

    components: { CustomActivatorComponent, PickerComponent },

    props: {
        dateStart: {},
        dateEnd: {},
        value: {},
        errorsBag: {},
        opens: {},
        autoApply: {},
        allTimePresets: {},
        allDatePresets: {},
        singleDatePicker: {},
        showPresets: {},
        timezone: {
            type: String,
            default: 'UTC',
            validator: value => timezonesValue.includes(value)
        }
    },

    data: function () {
        return {
            localErrors: this.errorsBag,
            ranges: []
        };
    },

    computed: {
        rangesLocalized() {
            return this.ranges.map(range => ({
                label: range.label,
                name: range.label,
                range: range.range
            }));
        },

        value_() {
            return this.value;
        },

        currentDate() {
            return moment().tz(this.timezone).format('YYYY-MM-DD');
        }
    },

    created: function () {
        this.localErrors = this.errorsBag || this.errors;
        this.ranges = this.setRanges();
    },

    mounted() {
        document.querySelector('.mdrp__activator').addEventListener('click', () => {
            this.onActivate();
        });
    },

    methods: {
        onActivate() {
            this.$emit('open');
            this.ranges = this.setRanges();
        },

        dateChange(dateRange) {
            this.$emit('change', {
                dateStart: dateRange[0].format('YYYY-MM-DD'),
                dateEnd: dateRange[1].format('YYYY-MM-DD')
            });
        },

        setRanges() {
            if (this.allTimePresets || this.allDatePresets) {
                return this.allTimePresets ? getAllTimeRanges() : getAllDateRanges();
            }

            return getRanges();
        }
    },

    watch: {
        currentDate() {
            this.ranges = this.setRanges();
        },

        ranges(value, old) {
            if (!_.isEqual(value, old)) {
                this.ranges = value.map(element => {
                    const dates = getDatesWithTimezone(element.range[0], element.range[1], this.timezone);
                    return {
                        ...element,
                        range: [dates.from, dates.to]
                    };
                });
            }
        }
    }
};
</script>
