import moment from 'moment';

export default {
    namespaced: true,

    state: {
        dateRange: {
            rangeName: 'Today',
            fromDate: moment().format('YYYY-MM-DD'),
            toDate: moment().format('YYYY-MM-DD')
        },
        byHours: false
    },

    getters: {
        getDateRange: state => ({
            rangeName: state.dateRange.rangeName ? state.dateRange.rangeName : 'Today',
            fromDate: moment(state.dateRange.fromDate, 'YYYY-MM-DD'),
            toDate: moment(state.dateRange.toDate, 'YYYY-MM-DD')
        }),
        getByHours: state => {
            return state.byHours;
        }
    },

    mutations: {
        setByHours(state, value) {
            state.byHours = value;
        },

        setDateRange(state, value) {
            state.dateRange.rangeName = value.rangeName;
            state.dateRange.fromDate = value.fromDate.format('YYYY-MM-DD');
            state.dateRange.toDate = value.toDate.format('YYYY-MM-DD');
        }
    }
};
