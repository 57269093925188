import Country from '@/interfaces/country';
import { Option } from '@/interfaces/select';

interface DefaultResponse<T> {
    data: T;
    success: true;
}

interface AdxTxt {
    [key: string]: string[];
}

const variables = import('@/plugins/global-variables-from-vue-2');

export class DictionaryService {
    private _url = '/dictionary';

    /**
     * Возвращает список Adx.txt.
     *
     * @return Promise<DefaultResponse<AdxTxt>>
     */
    public async getAdxTxt(): Promise<DefaultResponse<AdxTxt>> {
        const $axios = await variables.then(variables => variables.$axios);
        const response = await $axios.get(`${this._url}/ads-txt`);
        return response.data;
    }

    public async getCountries(): Promise<Country[]> {
        const $axios = await variables.then(variables => variables.$axios);
        const response = await $axios.post(`${this._url}/countries`);
        return Object.entries(response.data).map(country => ({ label: country[1] as string, code: country[0] }));
    }

    /**
     * Возвращает список стран для IOS c нашими именами.
     *
     * @return Promise<IOSCountries[]>
     */
    public async getCountriesForIOS(): Promise<Option[]> {
        const $axios = await variables.then(variables => variables.$axios);
        const ourCountries = $axios.post(`${this._url}/countries`).then(response => response.data);
        const iosCountries = fetch(
            'https://raw.githubusercontent.com/jcoester/iTunes-country-codes/main/itunes_country_codes.json'
        ).then(response => response.json());

        return await Promise.allSettled([ourCountries, iosCountries]).then(responses => {
            const ourCountries = responses[0].status === 'fulfilled' ? responses[0].value : [];
            const iosCountries = responses[1].status === 'fulfilled' ? responses[1].value : [];

            return Object.keys(iosCountries).map(key => ({ text: ourCountries[key], value: key }));
        });
    }
}
