<template>
    <button :disabled="isDisabled" @click="$emit('click')" class="button">
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: 'ButtonComponent',

    props: {
        isDisabled: {
            default: false,
            type: Boolean
        }
    }
};
</script>
