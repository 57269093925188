import moment from 'moment';
import _ from 'lodash';

class ChartDataService {
    fromDate = moment();
    toDate = moment();
    filter = {};
    agregatedFields = [];
    dateField = '';
    groupBy = [];
    typeChart = 'line';
    testMode = false;
    timezone = 'UTC';
    data = {
        requests: [],
        impressions: [],
        clicks: [],
        wins: [],
        ctr: [],
        sspSpend: [],
        dspSpend: [],
        profit: [],
        markup: [],
        winrate: [],
        response_rate: [],
        backfills: [],
        ecpm: [],
        fillrate: [],
        bids: []
    };
    total = {
        requests: 0,
        impressions: 0,
        clicks: 0,
        wins: 0,
        ctr: 0,
        sspSpend: 0,
        dspSpend: 0,
        profit: 0,
        markup: 0,
        winrate: 0,
        response_rate: 0,
        backfills: 0,
        ecpm: 0,
        fillrate: 0,
        bids: 0
    };
    dataForPieChart = [];
    seriesForPieChart = [];
    dictFields = {
        trafficType: 'trafficType_dict'
    };

    typeReport = 'publisher';

    axios;

    constructor(axios) {
        this.axios = axios;
    }

    setTypeReport(type) {
        this.typeReport = type;
        return this;
    }

    setTypeChart(type) {
        this.typeChart = type;
        return this;
    }

    setFromDate(fromDate) {
        this.fromDate = fromDate;
        return this;
    }

    setToDate(toDate) {
        this.toDate = toDate;
        return this;
    }

    setGroupBy(groupBy) {
        this.groupBy = groupBy;
        return this;
    }

    setFilter(filter) {
        this.filter = filter;
        return this;
    }

    setDateField(dateField) {
        this.dateField = dateField;
        return this;
    }

    setAgregatedFields(agregatedFields) {
        this.agregatedFields = agregatedFields;
        return this;
    }

    setTestMode(testMode) {
        this.testMode = testMode;
        return this;
    }

    setTimezone(timezone) {
        this.timezone = timezone;
        return this;
    }

    getData() {
        return this.data;
    }

    getDataPieChart() {
        return this.dataForPieChart;
    }

    getSeriesPieChart() {
        return this.seriesForPieChart.map(Number);
    }

    getTotals() {
        return this.total;
    }

    async fetch() {
        const data = {
            timezone: this.timezone,
            from: this.fromDate.format('YYYY-MM-DD'),
            to: this.toDate.format('YYYY-MM-DD'),
            groupBy: [this.groupBy],
            agregatedFields: this.agregatedFields,
            orderField: this.dateField,
            orderDir: 'asc',
            rowsOnPage: 500,
            page: 1,
            filter: this.filter,
            testMode: this.testMode
        };

        const route = this.typeReport === 'publisher' ? '/statistic' : '/admin/statistic';

        const response = await this.axios.post(route, data);

        if (this.typeChart === 'pie') {
            this.dataForPieChart = _.map(response.data.data, this.dictFields[this.groupBy]);
            this.seriesForPieChart = _.map(response.data.data, this.agregatedFields[0]);

            return;
        }

        if (!['YMDH', 'YMD'].includes(this.groupBy)) {
            return response;
        }

        response.data.data.map(item => {
            this.data.requests.push([moment(item[this.groupBy]), parseInt(item.requests)]);
            this.data.impressions.push([moment(item[this.groupBy]), parseInt(item.impressions)]);
            this.data.clicks.push([moment(item[this.groupBy]), parseFloat(item.clicks)]);
            this.data.wins.push([moment(item[this.groupBy]), parseFloat(item.wins)]);
            this.data.ctr.push([moment(item[this.groupBy]), parseFloat(item.ctr)]);
            this.data.sspSpend.push([moment(item[this.groupBy]), parseFloat(item.sspSpend)]);
            this.data.dspSpend.push([moment(item[this.groupBy]), parseFloat(item.dspSpend)]);
            this.data.profit.push([moment(item[this.groupBy]), parseFloat(item.profit)]);
            this.data.markup.push([moment(item[this.groupBy]), parseFloat(item.markup)]);
            this.data.winrate.push([moment(item[this.groupBy]), parseFloat(item.winrate)]);
            this.data.response_rate.push([moment(item[this.groupBy]), parseFloat(item.response_rate)]);
            this.data.backfills.push([moment(item[this.groupBy]), parseFloat(item.backfills)]);
            this.data.ecpm.push([moment(item[this.groupBy]), parseFloat(item.ecpm)]);
            this.data.fillrate.push([moment(item[this.groupBy]), parseFloat(item.fillrate)]);
            this.data.bids.push([moment(item[this.groupBy]), parseFloat(item.bids)]);
        });

        this.total.requests = response.data.totals.requests;
        this.total.impressions = response.data.totals.impressions;
        this.total.clicks = response.data.totals.clicks;
        this.total.sspSpend = response.data.totals.sspSpend;
        this.total.dspSpend = response.data.totals.dspSpend;
        this.total.profit = response.data.totals.profit;
        this.total.markup = response.data.totals.markup;
        this.total.wins = response.data.totals.wins;
        this.total.ctr = response.data.totals.ctr;
        this.total.winrate = response.data.totals.winrate;
        this.total.response_rate = response.data.totals.response_rate;
        this.total.backfills = response.data.totals.backfills;
        this.total.ecpm = response.data.totals.ecpm;
        this.total.fillrate = response.data.totals.fillrate;
        this.total.bids = response.data.totals.bids;
        return response;
    }
}

export { ChartDataService };
