import moment, { Moment } from 'moment-timezone';

export enum Period {
    TODAY = 'Today',
    YESTERDAY = 'Yesterday',
    LAST_7_DAYS = 'Last 7 Days',
    WEEK = 'Week',
    LAST_WEEK = 'Last-week',
    LAST_30_DAYS = 'Last 30 Days',
    MONTH = 'Month',
    THIS_MONTH = 'This Month',
    LAST__MONTH = 'Last-month',
    LAST_MONTH = 'Last Month',
    MTD = 'MTD',
    All_DATES = 'All Dates',
    ALL_TIME = 'All Time',
    TOTAL = 'Total'
}

interface Range {
    label: Period;
    range: Moment[];
}

const dayFormat = 'YYYY-MM-DD';

function getRanges(): Range[] {
    return [
        {
            label: Period.TODAY,
            range: [moment(), moment()]
        },
        {
            label: Period.YESTERDAY,
            range: [moment().subtract(1, 'days'), moment().subtract(1, 'days')]
        },
        {
            label: Period.LAST_7_DAYS,
            range: [moment().subtract(7, 'days'), moment().subtract(1, 'days')]
        },
        {
            label: Period.LAST_30_DAYS,
            range: [moment().subtract(30, 'days'), moment().subtract(1, 'days')]
        },
        {
            label: Period.THIS_MONTH,
            range: [moment().startOf('month'), moment().endOf('month')]
        },
        {
            label: Period.LAST_MONTH,
            range: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }
    ];
}

function getAllTimeRanges(): Range[] {
    return [
        {
            label: Period.ALL_TIME,
            range: [moment('2020-01-01').tz('UTC', true), moment()]
        },
        {
            label: Period.TODAY,
            range: [moment(), moment()]
        },
        {
            label: Period.YESTERDAY,
            range: [moment().subtract(1, 'days'), moment().subtract(1, 'days')]
        },
        {
            label: Period.LAST_7_DAYS,
            range: [moment().subtract(7, 'days'), moment().subtract(1, 'days')]
        },
        {
            label: Period.LAST_30_DAYS,
            range: [moment().subtract(30, 'days'), moment().subtract(1, 'days')]
        },
        {
            label: Period.THIS_MONTH,
            range: [moment().startOf('month'), moment().endOf('month')]
        },
        {
            label: Period.LAST_MONTH,
            range: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }
    ];
}

function getAllDateRanges(): Range[] {
    return [
        {
            label: Period.All_DATES,
            range: [moment('2010-01-01').tz('UTC', true), moment()]
        },
        {
            label: Period.TODAY,
            range: [moment(), moment()]
        },
        {
            label: Period.YESTERDAY,
            range: [moment().subtract(1, 'days'), moment().subtract(1, 'days')]
        },
        {
            label: Period.LAST_7_DAYS,
            range: [moment().subtract(7, 'days'), moment().subtract(1, 'days')]
        },
        {
            label: Period.LAST_30_DAYS,
            range: [moment().subtract(30, 'days'), moment().subtract(1, 'days')]
        },
        {
            label: Period.THIS_MONTH,
            range: [moment().startOf('month'), moment().endOf('month')]
        },
        {
            label: Period.LAST_MONTH,
            range: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }
    ];
}

function periodToDateWithAllTimes(period: Period, defaultValue: Moment, timezone = 'UTC'): Moment {
    const defaultDate = moment().tz(timezone).startOf('day');

    switch (period) {
        case Period.All_DATES:
            return moment('2010-01-01').tz(timezone, true);
        case Period.YESTERDAY:
            return defaultDate.subtract(1, 'days');
        case Period.LAST_7_DAYS:
            return defaultDate.subtract(7, 'days');
        case Period.LAST_30_DAYS:
            return defaultDate.subtract(30, 'days');
        case Period.WEEK:
            return defaultDate.startOf('isoWeek');
        case Period.LAST_WEEK:
            return defaultDate.subtract(1, 'week').startOf('isoWeek');
        case Period.MONTH:
        case Period.THIS_MONTH:
            return defaultDate.startOf('month');
        case Period.LAST__MONTH:
        case Period.LAST_MONTH:
            return defaultDate.subtract(1, 'month').startOf('month');
        case Period.TOTAL:
            return moment('2018-12-31', 'YYYY-MM-DD').tz(timezone, true);
        case Period.MTD:
            return defaultDate.startOf('month');
        case Period.TODAY:
            return defaultDate;
    }

    if (defaultValue) {
        return defaultValue.tz(timezone, true);
    }

    return moment();
}

function periodToDateTo(period: Period, defaultValue: Moment, timezone = 'UTC'): Moment {
    const defaultDate = moment().tz(timezone).startOf('day');

    switch (period) {
        case Period.TODAY:
            return defaultDate;
        case Period.YESTERDAY:
            return defaultDate.subtract(1, 'days');
        case Period.LAST_7_DAYS:
            return defaultDate.subtract(1, 'days');
        case Period.LAST_30_DAYS:
            return defaultDate.subtract(1, 'days');
        case Period.WEEK:
            return defaultDate.endOf('isoWeek');
        case Period.LAST_WEEK:
            return defaultDate.subtract(1, 'week').endOf('isoWeek');
        case Period.MONTH:
        case Period.THIS_MONTH:
            return defaultDate.endOf('month');
        case Period.LAST__MONTH:
        case Period.LAST_MONTH:
            return defaultDate.subtract(1, 'month').endOf('month');
        case Period.MTD:
        case Period.TOTAL:
        case Period.All_DATES:
            return defaultDate;
    }

    if (defaultValue) {
        return defaultValue.tz(timezone, true);
    }

    return moment();
}

function datesToPeriod(from: string, to: string, timezone = 'UTC') {
    const defaultDate = moment().tz(timezone).startOf('day');
    const today = defaultDate.clone().format(dayFormat);
    const yesterday = defaultDate.clone().subtract(1, 'day').format(dayFormat);

    if (from === to) {
        if (from === today) {
            return Period.TODAY;
        }
        if (from === yesterday) {
            return Period.YESTERDAY;
        }
    }

    if (
        from === defaultDate.clone().subtract(1, 'month').startOf('month').format(dayFormat) &&
        to === defaultDate.clone().subtract(1, 'month').endOf('month').format(dayFormat)
    ) {
        return Period.LAST_MONTH;
    }

    if (to === yesterday) {
        if (from === defaultDate.clone().subtract(7, 'day').format(dayFormat)) {
            return Period.LAST_7_DAYS;
        }
        if (from === defaultDate.clone().subtract(30, 'day').format(dayFormat)) {
            return Period.LAST_30_DAYS;
        }
    }

    if (
        from === defaultDate.clone().startOf('month').format(dayFormat) &&
        to === defaultDate.clone().endOf('month').format(dayFormat)
    ) {
        return Period.THIS_MONTH;
    }

    if (from === moment('2010-01-01').tz(timezone, true).format(dayFormat) && to === today) {
        return Period.All_DATES;
    }

    return from + ' - ' + to; //'Custom Range';
}

function calculatePrevPeriod(start: Moment, end: Moment): Moment[] {
    const periodLength = end.diff(start, 'days');

    const startPrev = moment(start).subtract(periodLength + 1, 'days');

    const endPrev = moment(start).subtract(1, 'days');

    return [startPrev, endPrev];
}

export {
    dayFormat,
    getRanges,
    getAllTimeRanges,
    getAllDateRanges,
    periodToDateWithAllTimes,
    periodToDateTo,
    datesToPeriod,
    calculatePrevPeriod
};
