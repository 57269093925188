<template>
    <header class="header">
        <div class="header-left">
            <RouterLink :to="{ name: 'dashboard' }" class="header-logo">
                <img src="@/images/logo.svg" alt="SmartyAds" />
            </RouterLink>
            <button class="button" @click="$bvModal.show('need-help')">Need Help?</button>
        </div>
        <nav v-if="$auth.ready() && $auth.check()">
            <UserOnline />
            <ul>
                <li>
                    <TopNotification />
                </li>

                <li>
                    <TopUserAction />
                </li>
            </ul>
            <ul class="header-admin" v-if="$auth.isTechnicalRole()">
                <li>
                    <AdminTopNotification noticeQuantity="2" />
                </li>

                <li>
                    <AdminUsersList />
                </li>
            </ul>
        </nav>
        <NeedHelpModal id="need-help"></NeedHelpModal>
    </header>
</template>

<script>
import UserOnline from '@/components/shared/UserOnline.vue';
import TopUserAction from '@/components/shared/TopUserAction.vue';
import NeedHelpModal from '@/components/shared/NeedHelpModal.vue';

export default {
    name: 'TheHeaderComponent',

    components: {
        NeedHelpModal,
        TopUserAction,
        UserOnline
    }
};
</script>

<style lang="scss">
@import '../scss/variables';

.header {
    background: $color-white;
    box-shadow: 0 1px 5px rgba($color-black, 0.1);
    position: relative;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media(md) {
        position: sticky;
        top: 0;
    }

    @include mediaDesktop($W768) {
        nav ul {
            justify-content: space-between;

            li {
                &:first-child {
                    border-right: 1px solid #ecedf1;
                }

                &:nth-child(2) {
                    border-left: none;
                }
            }
        }
    }

    @include mediaDesktop($W530) {
        display: initial;

        nav ul li {
            .header-dropdown .is-ellipsis {
                max-width: calc(100vw - 120px);
            }

            .dropdown-menu {
                left: 0;

                .search-input input {
                    width: 100%;
                }
            }
        }
    }

    &-logo {
        margin: 10px 1rem;
        display: inline-block;

        @include media(md) {
            margin: 0 1rem;
        }
    }

    nav {
        border-top: 1px solid $color-grey;
        min-height: 64px;

        @include media(md) {
            display: flex;
            flex-wrap: nowrap;
            border-top: 0;
        }

        ul {
            display: flex;
            margin: 0;
            padding: 0;

            &:not(:last-child) {
                border-bottom: 1px solid $color-grey;

                @include media(md) {
                    border-bottom: 0;
                }
            }

            li {
                min-width: 40px;
                display: grid;
                place-content: center;
                border-left: 1px solid $color-grey;
                //padding: 10px;
                list-style: none;
                color: rgba($color-secondary, 0.5);

                .balance {
                    color: $color-secondary;
                }
            }
        }
    }

    &-admin {
        background-color: rgba(31, 201, 255, 0.08);
    }

    &-dropdown {
        position: relative !important;
        cursor: pointer;
        padding: 10px;

        .dropdown {
            &-trigger {
                font-size: 12px;
                display: flex;
                align-items: center;
                gap: 5px;
            }

            &-arrow {
                width: 16px;
                height: 16px;
                background: url(@/images/chevron-down.svg) 50% 50% no-repeat;
            }

            &-menu {
                max-width: 250px;
                right: 0;
                left: auto;
                border-radius: 0;
                border: none;
                box-shadow: 0 0 5px rgba($color-black, 0.1);
            }

            &-item {
                font-size: 12px;
                padding: 10px 15px;

                &:hover,
                &:focus {
                    background: $color-primary;
                    color: $color-white;
                }

                svg,
                img {
                    width: 14px;
                    margin-right: 10px;
                }
            }
        }

        .is-ellipsis {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: calc(100vw - 213px);

            @include mediaDesktop($W991) {
                max-width: 135px;
            }
        }

        &.is-active .dropdown-menu {
            display: block;
        }

        &.has-admin {
            color: $color-secondary;

            .is-ellipsis {
                max-width: calc(100vw - 236px);

                @include mediaDesktop($W991) {
                    max-width: 180px;
                }
            }

            .info {
                .user-id,
                .balance {
                    color: $color-secondary;
                    min-width: 50px;
                    display: inline-block;
                    padding-right: 10px;
                }
            }

            .dropdown {
                &-menu {
                    max-width: 360px;
                    padding: 0;
                }

                &-trigger {
                    &:before {
                        content: '';
                        width: 8px;
                        height: 8px;
                        display: inline-block;
                        background: #8d8d8d;
                        border-radius: 50%;
                        margin-right: 10px;
                    }

                    &.online:before {
                        background-color: $color-green;
                    }
                }

                &-content {
                    .top {
                        padding: 10px;
                        display: flex;
                        gap: 10px;
                        z-index: 1;
                        background: $color-white;
                        position: sticky;
                        top: 0;
                        border-bottom: 1px solid $color-grey;

                        .v-select {
                            min-width: 120px;
                        }

                        .vs__dropdown-menu {
                            display: flex;
                            flex-direction: column;
                            max-width: 100px;
                            min-width: 100%;
                        }

                        .vs__dropdown-option {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                        }
                    }

                    .user-list {
                        height: 100%;
                        padding-left: 0;
                        padding-right: 0;
                        padding-top: 0;
                        max-height: 500px;
                        overflow: auto;

                        .item {
                            padding: 20px;
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            font-size: 12px;
                            position: relative;
                            background: $color-white;

                            &:not(:last-child) {
                                border-bottom: 1px solid $color-grey;
                            }

                            &:before {
                                content: '';
                                width: 8px;
                                height: 8px;
                                border-radius: 50%;
                                background: #8d8d8d;
                                margin-right: 10px;
                            }

                            &.online:before {
                                background: $color-green;
                            }

                            .u-id,
                            .u-balance {
                                min-width: 60px;
                                max-width: 60px;
                            }

                            .u-name,
                            .u-mail {
                                max-width: 200px;
                            }

                            .u-id,
                            .u-balance,
                            .u-name,
                            .u-mail {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }

                            .u-actions {
                                width: 16px;
                                height: 16px;
                                position: absolute;
                                right: 20px;
                                top: 50%;
                                margin-top: -8px;
                                opacity: 0;
                                transition: all 400ms;
                            }

                            &:hover {
                                background: #f5f5f6;

                                .u-actions {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }

        // Header notifications
        &-notification {
            position: relative;

            .counter {
                width: 12px;
                height: 12px;
                background: #8d8d8d;
                border-radius: 50%;
                font-size: 8px;
                line-height: 12px;
                text-align: center;
                color: $color-white;
                position: absolute;
                right: 0;
                bottom: -8px;

                &.has {
                    background: #eb5757;
                }
            }

            .dropdown {
                &-trigger {
                    cursor: pointer;

                    a {
                        position: relative;

                        .info {
                            display: inline-block;
                            font-size: 12px;

                            .name {
                                color: #637381;
                                display: block;
                            }

                            .balance {
                                color: $color-secondary;
                                display: block;
                            }
                        }

                        path {
                            transition: fill 0.3s ease-out;
                        }

                        &:hover path {
                            fill: $color-primary;
                        }
                    }
                }

                &-menu {
                    background: $color-white;
                    box-shadow: 0 1px 5px rgba($color-black, 0.1);
                    border-radius: 5px;
                    border: 1px solid $color-grey;
                    padding-block: 0;
                    width: 350px;
                    right: 0;
                    left: -130px;
                    margin-top: 13px;

                    @include media(custom, 991) {
                        left: auto;
                    }
                }

                &-content {
                    position: relative !important;

                    .top {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 10px;
                        gap: 10px;
                        border-bottom: 1px solid $color-grey;
                        background: $color-white;
                        z-index: 2;

                        .only-unread {
                            display: flex;
                            align-items: center;
                            gap: 5px;
                        }

                        .title {
                            font-size: 12px;
                            color: #637381;
                        }

                        .mark-all {
                            font-size: 12px;
                            cursor: pointer;
                            margin-left: auto;
                        }
                    }

                    // TODO Check node-list later
                    .note-list {
                        margin-top: 41px;
                        max-height: 383px;
                        padding-left: 0;
                        padding-right: 0;
                        padding-top: 0;

                        .item {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            padding: 15px 20px 20px 15px;
                            border-width: 1px 0;
                            border-style: solid;
                            border-color: $color-grey;
                            position: relative;

                            &:first-child {
                                border-top-width: 0;
                            }

                            &:last-child {
                                border-bottom-width: 0;
                            }

                            &.unread {
                                background: #f5f5f6;
                                cursor: pointer;

                                &:before {
                                    content: '';
                                    width: 5px;
                                    height: 5px;
                                    border-radius: 50%;
                                    background: $color-primary;
                                    position: absolute;
                                    top: 10px;
                                    left: 10px;
                                }

                                &.new:after {
                                    content: 'new';
                                    position: absolute;
                                    top: 5px;
                                    right: 15px;
                                    font-size: 10px;
                                    color: #637381;
                                }
                            }

                            .info {
                                min-width: 110px;
                                width: 110px;

                                .name {
                                    font-size: 12px;
                                    line-height: 20px;
                                    color: $color-primary;
                                    display: block;
                                }

                                .date {
                                    font-size: 9px;
                                    line-height: 20px;
                                    color: #637381;
                                    display: block;
                                }
                            }

                            .text {
                                font-size: 12px;
                                line-height: 20px;
                                color: $color-secondary;
                                margin-left: 10px;

                                span {
                                    color: $color-primary;
                                }
                            }
                        }
                    }
                }
            }

            &.is-active {
                .dropdown-menu {
                    display: block;
                }

                .dropdown-trigger path {
                    stroke: $color-primary;
                }
            }
        }
    }

    &-left {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        width: 100%;

        @include media(md) {
            justify-content: space-between;
            flex-wrap: initial;
            margin-right: 20px;
        }

        .button {
            height: fit-content;
        }
    }
}
</style>
