import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';

const Login = () => import('@/views/Login.vue');
const Profile = () => import('@/views/Profile.vue');
const Error404 = () => import('@/views/Error404View.vue');
const UserGuide = () => import('@/views/UserGuide.vue');
const Restricted = () => import('@/views/RestrictedView.vue');
const RestrictedRussia = () => import('@/views/RestrictedRussia.vue');
const Changelogs = () => import('@/views/changelogs/ChangelogsList.vue');

const DealNew = () => import(/* webpackChunkName: "publisher" */ '@/views/Deal.vue');
const DealList = () => import(/* webpackChunkName: "publisher" */ '@/views/Deals.vue');
const Billing = () => import(/* webpackChunkName: "publisher" */ '@/views/Billing.vue');
const Inventory = () => import(/* webpackChunkName: "publisher" */ '@/views/Inventories.vue');
const InventoryNew = () => import(/* webpackChunkName: "publisher" */ '@/views/Inventory.vue');
const PlacementNew = () => import(/* webpackChunkName: "publisher" */ '@/views/Placement.vue');
const Reports = () => import(/* webpackChunkName: "publisher" */ '@/views/reports/Reports.vue');
const PlacementList = () => import(/* webpackChunkName: "publisher" */ '@/views/Placements.vue');
const Dashboard = () => import(/* webpackChunkName: "publisher" */ '@/views/dashboard/Dashboard.vue');
const ScheduleReport = () => import(/* webpackChunkName: "publisher" */ '@/views/reports/ScheduleReportView.vue');
const AdsTxt = () => import(/* webpackChunkName: "publisher" */ '@/views/AdsTxt.vue');

const AdminUsers = () => import(/* webpackChunkName: "admin" */ '@/views/administrator/Users.vue');
const AdminUserEdit = () => import(/* webpackChunkName: "admin" */ '@/views/administrator/User.vue');
const AdminInventory = () => import(/* webpackChunkName: "admin" */ '@/views/administrator/Inventory.vue');
const AdminTransactions = () => import(/* webpackChunkName: "admin" */ '@/views/administrator/Transactions.vue');
const AdminActivityLogs = () => import(/* webpackChunkName: "admin" */ '@/views/administrator/ActivityLogs.vue');
const AdminSampleLogger = () => import(/* webpackChunkName: "admin" */ '@/views/administrator/SampleLogger.vue');
const ChangelogsCreate = () => import(/* webpackChunkName: "admin" */ '@/views/changelogs/ChangelogsCreate.vue');
const ChangelogsUpdate = () => import(/* webpackChunkName: "admin" */ '@/views/changelogs/ChangelogsUpdate.vue');
const AdminReportsGeneral = () => import(/* webpackChunkName: "admin" */ '@/views/administrator/Reports/General.vue');
const AdminCRIDBlacklist = () => import(/* webpackChunkName: "admin" */ '@/views/administrator/CRIDBlacklistView.vue');
const AdminPrebidAnalytics = () =>
    import(/* webpackChunkName: "admin" */ '@/views/administrator/PrebidAnalyticsView.vue');
const AdminReportsPixalateFraud = () =>
    import(/* webpackChunkName: "admin" */ '@/views/administrator/Reports/PixalateFraud.vue');

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/login',
            redirect: '/signin'
        },
        {
            path: '/signin',
            name: 'login',
            component: Login,
            meta: {
                title: 'Login'
            }
        },
        {
            path: '/account/confirm/:confirmToken',
            name: 'confirmAccount',
            component: Login,
            meta: {
                auth: false,
                title: 'Confirm account'
            }
        },
        {
            path: '/password/restore/:resetToken',
            name: 'resetPass',
            component: Login,
            meta: {
                auth: false,
                title: 'Reset password'
            }
        },
        {
            alias: '/',
            path: '/dashboard',
            name: 'dashboard',
            component: Dashboard,
            meta: {
                auth: true,
                title: 'Dashboard'
            }
        },
        {
            path: '/billing',
            name: 'billing',
            component: Billing,
            meta: {
                auth: true,
                title: 'Billing'
            }
        },
        {
            path: '/profile',
            name: 'profile',
            component: Profile,
            meta: {
                auth: true,
                title: 'Profile'
            }
        },
        {
            path: '*',
            name: 'error404',
            component: Error404,
            meta: {
                title: '404 Not Found'
            }
        },
        {
            alias: '/',
            path: '/inventory',
            name: 'inventory',
            component: Inventory,
            meta: {
                auth: true,
                title: 'Inventory'
            }
        },
        {
            path: '/inventory/:id',
            name: 'inventory.edit',
            component: InventoryNew,
            meta: {
                auth: true,
                forbiddenForUserSuspended: true,
                title: 'Inventory'
            }
        },
        {
            path: '/inventory/create/add-placement',
            name: 'inventory-add-placement',
            component: PlacementNew,
            props: {
                saveWithInventory: true
            },
            meta: {
                auth: true,
                forbiddenForUserSuspended: true,
                title: 'Placement'
            }
        },
        {
            path: '/inventory/:id/add-placement',
            name: 'add-placement',
            component: PlacementNew,
            meta: {
                auth: true,
                forbiddenForUserSuspended: true,
                title: 'Placement'
            }
        },
        {
            path: '/ads-txt',
            name: 'ads-txt',
            component: AdsTxt,
            meta: {
                auth: true,
                title: 'Ads.txt'
            }
        },
        {
            path: '/placement-new',
            name: 'placementNew',
            component: PlacementNew,
            meta: {
                auth: true,
                forbiddenForUserSuspended: true,
                title: 'Placement'
            }
        },
        {
            path: '/placement/:id',
            name: 'edit-placement',
            component: PlacementNew,
            props: {
                isEdit: true
            },
            meta: {
                auth: true,
                forbiddenForUserSuspended: true,
                title: 'Placement'
            }
        },
        {
            path: '/placement-list/:id',
            name: 'placementList',
            component: PlacementList,
            meta: {
                auth: true,
                forbiddenForUserSuspended: true,
                title: 'Placement'
            }
        },
        {
            path: '/deals/',
            name: 'dealList',
            component: DealList,
            meta: {
                auth: true,
                title: 'Deals'
            }
        },
        {
            path: '/deal/',
            name: 'dealNew',
            component: DealNew,
            meta: {
                auth: true,
                forbiddenForUserSuspended: true,
                title: 'Deals'
            }
        },
        {
            path: '/deal/:id(\\d+)',
            name: 'edit-deal',
            component: DealNew,
            props: {
                isEdit: true
            },
            meta: {
                auth: true,
                forbiddenForUserSuspended: true,
                title: 'Deals'
            }
        },
        {
            path: '/reports',
            name: 'reports',
            component: Reports,
            meta: {
                auth: true,
                title: 'Reports'
            }
        },
        {
            path: '/reports/schedule',
            name: 'reports.schedule',
            component: ScheduleReport,
            meta: {
                auth: true,
                title: 'Schedule Reports'
            }
        },
        {
            path: '/restrictedRussia',
            name: 'restrictedRussia',
            component: RestrictedRussia,
            meta: {
                title: 'Restricted Russia'
            }
        },
        {
            path: '/restricted',
            name: 'restricted',
            component: Restricted,
            meta: {
                title: 'Restricted'
            }
        },
        {
            path: '/user-guide/1.0/(.*)*',
            name: 'userGuide',
            component: UserGuide,
            meta: {
                title: 'Platform guide'
            }
        },
        {
            path: '/changelogs',
            name: 'changelogs',
            component: Changelogs,
            meta: {
                auth: true,
                title: 'Changelogs'
            }
        },

        /** ADMIN SIDE */
        {
            path: '/administrator/reports',
            name: 'admin.reports.general',
            component: AdminReportsGeneral,
            meta: {
                auth: ['admin', 'finance', 'root'],
                title: 'Admin reports'
            }
        },
        {
            path: '/administrator/reports/pixalate-fraud',
            name: 'admin.reports.pixalate-fraud',
            component: AdminReportsPixalateFraud,
            meta: {
                auth: ['admin', 'finance', 'root'],
                title: 'Admin reports'
            }
        },
        {
            path: '/administrator/inventory',
            name: 'admin.inventory',
            component: AdminInventory,
            meta: {
                auth: ['admin', 'root'],
                title: 'Admin inventory'
            }
        },
        {
            path: '/administrator/transactions',
            name: 'admin.transactions',
            component: AdminTransactions,
            meta: {
                auth: ['finance', 'root'],
                title: 'Admin transactions'
            }
        },
        {
            path: '/administrator/users',
            name: 'admin.users',
            component: AdminUsers,
            meta: {
                auth: ['admin', 'finance', 'root'],
                title: 'Admin users'
            }
        },
        {
            path: '/administrator/users/:id',
            name: 'admin.user.edit',
            component: AdminUserEdit,
            meta: {
                auth: ['admin', 'finance', 'root'],
                title: 'Admin users'
            }
        },
        {
            path: '/administrator/activity.logs',
            name: 'activity.logs',
            component: AdminActivityLogs,
            meta: {
                auth: ['admin', 'finance', 'root'],
                title: 'Admin activity logs'
            }
        },
        {
            path: '/administrator/sample.logger',
            name: 'sample.logger',
            component: AdminSampleLogger,
            meta: {
                auth: ['admin', 'root'],
                title: 'Admin sample logger'
            }
        },
        {
            path: '/changelogs/create',
            name: 'changelogs.create',
            component: ChangelogsCreate,
            meta: {
                auth: ['root'],
                title: 'Changelogs'
            }
        },
        {
            path: '/changelogs/:id(\\d+)',
            name: 'changelogs.update',
            component: ChangelogsUpdate,
            meta: {
                auth: ['root'],
                title: 'Changelogs'
            },
            props: route => ({ ...route.params, id: Number.parseInt(route.params.id) })
        },
        {
            path: '/administrator/crid',
            name: 'admin.crid',
            component: AdminCRIDBlacklist,
            meta: {
                auth: ['admin', 'finance', 'root'],
                title: 'CRID Blacklist'
            }
        },
        {
            path: '/administrator/prebid-analytics',
            name: 'admin.prebid.analytics',
            component: AdminPrebidAnalytics,
            meta: {
                auth: ['admin', 'root'],
                title: 'Analytical Prebid Adapter'
            }
        }
    ]
});

export default router;

router.beforeEach(async (to, from, next) => {
    window._hsq.push(['setPath', to.path]);
    window._hsq.push(['trackPageView']);

    const country = localStorage.getItem('sanctionCountry');
    const result = restricted(country, to.name);
    if (country && result) {
        return next(router.resolve({ name: result })?.route.path);
    }

    next();
});

/**
 * Возвращает name redirect если ограниченный доступ к следующему пути, иначе false.
 *
 * @param {string} country
 * @param {string} routeName
 *
 * @return string|false
 */
function restricted(country, routeName) {
    const sanctionsCountries = store.state.sanctionsCountries;
    const hasSanctionCountry = sanctionsCountries.includes(country);

    if (hasSanctionCountry) {
        if (country === 'RU' && routeName !== 'restrictedRussia') {
            return 'restrictedRussia';
        }
        if (country !== 'RU' && routeName !== 'restricted') {
            return 'restricted';
        }
    }
    if (!hasSanctionCountry && ['restricted', 'restrictedRussia'].includes(routeName)) {
        return 'login';
    }

    return false;
}
