<template>
    <span class="text-editable">
        <span v-if="!isEditing" class="progress-wap">
            <span class="wrap">
                <slot></slot>
                <ProgressComponent
                    v-if="showProgress"
                    :isOverfilled="false"
                    :progressPercent="
                        progressMaxValue_ > 0 ? ((progressValue_ / progressMaxValue_) * 100).toFixed(2) : '-'
                    "
                >
                </ProgressComponent>
            </span>
            <a @click="switchToEdit()" class="settings for-show">
                <img class="type-img" src="@/images/icon-edit.svg" alt="icon edit" />
            </a>
        </span>
        <span v-else class="input-wrapper-alt">
            <input
                @focusout="focusOut($event)"
                @keypress.enter="save($event.target.value)"
                @keyup.esc="switchToView"
                v-model="value_"
                :maxlength="maxLength"
                ref="edit_input"
                type="text"
                class="input-text"
            />
            <button class="button" @mousedown="save()" ref="edit_submit">
                <img src="@/images/field-ok.svg" alt="field ok" />
            </button>
        </span>
    </span>
</template>

<script>
import ProgressComponent from '@/components/shared/Progress.vue';

export default {
    name: 'TextEditableComponent',
    components: { ProgressComponent },

    props: {
        progressValue: {
            type: [Number, String]
        },

        showProgress: {
            type: Boolean,
            default: () => {
                return false;
            }
        },

        progressMaxValue: {
            type: [Number, String]
        },

        value: {
            type: [Number, String]
        },

        maxLength: {
            type: Number
        }
    },

    data: function () {
        return {
            isEditing: false,
            progressValue_: this.progressValue,
            progressMaxValue_: this.progressMaxValue,
            value_: this.value
        };
    },

    watch: {
        value: function () {
            this.value_ = this.value;
        },

        progressValue: function () {
            this.progressValue_ = this.progressValue;
        },

        progressMaxValue: function () {
            this.progressMaxValue_ = this.progressMaxValue;
        }
    },

    methods: {
        switchToEdit() {
            this.isEditing = true;
            this.$nextTick(() => this.$refs.edit_input.focus());
        },

        switchToView() {
            this.isEditing = false;
        },

        save() {
            if (this.value_ !== this.value) {
                this.$emit('change', this.value_);
            }
            this.switchToView();
        },

        focusOut(event) {
            if (event.relatedTarget === this.$refs.edit_submit) {
                return;
            }

            this.localValue = this.value;
            this.switchToView();
        }
    }
};
</script>

<style lang="scss">
.text-editable {
    @import '../../scss/variables';

    .progress-wap {
        position: relative;
        padding-right: 40px;

        .wrap {
            display: inline-block;
        }
    }

    .input-wrapper-alt {
        min-width: 85px;
        display: inline-block;
        position: relative;
        padding-right: 34px;

        .input-text {
            width: 70px;
            max-width: none;
            height: 35px;
            padding: 15px;
            background: $color-white;
            border: 1px solid #c4cdd5;
            box-sizing: border-box;
            box-shadow: inset 0 1px 2px rgba(102, 113, 123, 0.21);
            border-radius: 5px 0 0 5px;
            font-size: 12px;
            line-height: 20px;
            color: $color-secondary;

            &:focus {
                background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 3.12%, rgba(97, 121, 133, 0.04) 100%),
                    $color-white;
                box-shadow: none;
                border-color: $color-primary;
            }
        }

        .button {
            min-width: auto;
            width: 36px;
            position: absolute;
            right: 0;
            top: 0;
        }
    }
}
</style>
