<template>
    <ValidationProvider
        :class="['app-field-input', { 'title-full-width': isTitleFullWidth }]"
        v-slot="{ errors }"
        :name="title ?? 'undefined'"
        :rules="rules"
        :custom-messages="customMessages"
        :vid="vid ?? ''"
    >
        <AppFieldTitle v-if="title" :title="title" :required="required" :tooltip="tooltip" />
        <DspInput
            :placeholder="placeholder"
            :is-disabled="disabled"
            :value="value"
            :class="classes"
            @changed="handler"
            :errors-bag="errors"
            :is-readonly="readonly"
        />
    </ValidationProvider>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue';
import debounce from 'debounce';
import AppFieldTitle from '@/components/shared/AppFieldTitle.vue';

interface Props {
    title?: string;
    value?: string | number;
    required?: boolean;
    tooltip?: string;
    inputClass?: string;
    inputPlaceholder?: string;
    isSearch?: boolean;
    rules?: { [key: string]: number | string | boolean | RegExp };
    customMessages?: { [key: string]: string };
    vid?: string;
    delay?: number;
    isTitleFullWidth?: boolean;
    disabled?: boolean;
    readonly?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    isSearch: false,
    delay: 0,
    isTitleFullWidth: false,
    disabled: false,
    readonly: false
});

const emit = defineEmits(['input']);

const { inputClass, isSearch, delay } = toRefs(props);

const placeholder = computed(() => props.inputPlaceholder ?? '');
const classes = computed(() => ['input', inputClass?.value, isSearch.value ? 'search' : '']);

const handler = debounce((value: string) => emit('input', value.trim()), delay.value, { immediate: false });
</script>

<style scoped lang="scss">
@import '@/scss/variables';

.app-field-input {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px 20px;
    margin-bottom: 10px;

    & .app-field-title {
        flex-shrink: 0;
    }

    &.title-full-width .app-field-title {
        width: 100%;
    }
}
</style>

<style lang="scss">
@import '@/scss/components/search';

.app-field-input .input {
    min-width: 100px;
    max-width: 350px;
    flex-grow: 1;

    input.input-text {
        width: 100%;
    }

    &.search input.input-text {
        @include input-icon-search;
    }
}
</style>
