import Vue from 'vue';
import 'vue-toastification/dist/index.css';
import Toast, { TYPE } from 'vue-toastification';
import AppNotification from '@/components/shared/AppNotification.vue';

Vue.use(Toast, { position: 'bottom-center', transition: 'Vue-Toastification__fade' });

Vue.mixin({
    methods: {
        /**
         * Отображение уведомление об успехе.
         *
         * @param {string} message Сообщение, как текст или plant HTML.
         * @param {number|false} timeout=5000 Время отображения уведомления в мс.
         * @return void
         */
        // eslint-disable-next-line vue/no-unused-properties
        globalToastInfo(message: string, timeout: number | false = 5000): void {
            this.$toast({ component: AppNotification, props: { content: message } }, { type: TYPE.SUCCESS, timeout });
        },

        /**
         * Отображение уведомление об неуспехе.
         *
         * @param {string} message Сообщение, как текст или plant HTML.
         * @param {number|false} timeout=15000 Время отображения уведомления в мс.
         * @return void
         */
        // eslint-disable-next-line vue/no-unused-properties
        globalToastError(message: string, timeout: number | false = 15000): void {
            this.$toast({ component: AppNotification, props: { content: message } }, { type: TYPE.ERROR, timeout });
        }
    }
});
