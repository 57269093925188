export default {
    methods: {
        modalLeave() {
            const message = this.$createElement('span', {
                domProps: { innerHTML: 'Are you sure you want<br>to leave this page?' }
            });

            return this.$bvModal.msgBoxConfirm(message, {
                title: ' ',
                okTitle: 'Yes',
                centered: true,
                okVariant: 'yes',
                cancelTitle: 'No',
                bodyClass: 'main',
                cancelVariant: ' ',
                hideHeaderClose: false,
                modalClass: 'confirm-modal'
            });
        }
    }
};
