<template>
    <div class="sample-logger-chart">
        <div class="empty-table" v-if="isBusyChart">
            <img src="@/images/loader_smaller.svg" alt="loader smaller" />
        </div>
        <div v-else>
            <div class="d-fl" v-if="chartData.series[0].data.length > 0">
                <div class="width-50">
                    <Highcharts :options="chartData" ref="chart" />
                </div>
                <div>
                    <table>
                        <tbody>
                            <tr v-for="(row, index) in chartData.series[0].data" :key="index">
                                <td class="p-20">
                                    <span class="point" :style="'background: ' + row.color"></span>
                                    <span class="ml-20">{{ row.name }}</span>
                                </td>
                                <td class="p-20">
                                    {{ row.count }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div v-else class="empty-table mt-20">
                <h4>No data to display</h4>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SampleLoggerChartComponent',

    props: {
        dataChart: {
            type: Array,
            default: function () {
                return [];
            }
        }
    },

    data: function () {
        return {
            /**  line */
            chartOptionsLine: {
                title: '',
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },

                plotOptions: {
                    pie: {
                        dataLabels: {
                            enabled: false,
                            distance: -50,
                            style: {
                                fontWeight: 'bold',
                                color: '#740B99'
                            }
                        },

                        startAngle: -180,
                        endAngle: 180,
                        center: ['50%', '50%'],
                        size: '100%'
                    }
                },

                series: [
                    {
                        name: '',
                        type: 'pie',
                        data: [],
                        innerSize: '80%'
                    }
                ],

                tooltip: {
                    pointFormat: '<b>{point.percentage:.1f}%</b>'
                },

                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: 0,
                    plotShadow: false
                }
            },

            isBusyChart: false
        };
    },

    computed: {
        chartData() {
            const options = this.chartOptionsLine;
            options.series[0].data = this.dataChart;
            return this.chartOptionsLine;
        }
    }
};
</script>

<style lang="scss">
.sample-logger-chart {
    .d-fl {
        display: flex;
    }

    .width-50 {
        width: 50%;
    }

    .p-20 {
        padding: 20px 20px;
    }

    .point {
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 3px;
    }

    .highcharts-credits {
        display: none;
    }

    .highcharts-figure,
    .highcharts-data-table table {
        min-width: 320px;
        max-width: 500px;
        margin: 1em auto;
    }

    #container {
        height: 400px;
    }

    .highcharts-data-table table {
        font-family: Verdana, sans-serif;
        border-collapse: collapse;
        border: 1px solid #ebebeb;
        margin: 10px auto;
        text-align: center;
        width: 100%;
        max-width: 500px;
    }

    .highcharts-data-table caption {
        padding: 1em 0;
        font-size: 1.2em;
        color: #555;
    }

    .highcharts-data-table th {
        font-weight: 600;
        padding: 0.5em;
    }

    .highcharts-data-table td,
    .highcharts-data-table th,
    .highcharts-data-table caption {
        padding: 0.5em;
    }

    .highcharts-data-table thead tr,
    .highcharts-data-table tr:nth-child(even) {
        background: #f8f8f8;
    }

    .highcharts-data-table tr:hover {
        background: #f1f7ff;
    }
}
</style>
