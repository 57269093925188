<template>
    <span></span>
</template>

<script>
export default {
    name: 'UserOnlineComponent',

    data() {
        return {
            timeoutsID: {
                ping: null
            },

            timeouts: {
                ping: (process.env.VUE_APP_USER_PING_TIMEOUT_MINUTES || 1) * 60000
            }
        };
    },

    mounted() {
        this.timeoutsID.ping = setTimeout(this.pingOnline, this.timeouts.ping);
    },

    destroyed() {
        clearTimeout(this.timeoutsID.ping);
    },

    methods: {
        pingOnline() {
            this.$auth.check() &&
                this.$axios.get('/users/ping').then(() => {
                    this.$auth.getOriginalUser().isOnline = true;
                    this.timeoutsID.ping = setTimeout(this.pingOnline, this.timeouts.ping);
                });
        }
    }
};
</script>
