<template>
    <div>
        <BModal :id="id" centered no-close-on-backdrop hide-footer @close="close" modal-class="need-help">
            <p class="title">Need Help?</p>
            <p class="description">Please fill out the contact form for us to offer you a personalized solution</p>
            <ValidationObserver ref="needHelpValidation" v-slot="{ passes }">
                <BForm @submit.prevent="passes(send)">
                    <AppFieldInput
                        title="Subject"
                        input-placeholder="Enter subject"
                        :is-title-full-width="true"
                        vid="need-help-subject"
                        :rules="validation.subject.rules"
                        v-model="model.subject"
                    />
                    <AppFieldTextarea
                        title="Message"
                        input-placeholder="Enter message"
                        :row="7"
                        vid="need-help-message"
                        :rules="validation.message.rules"
                        v-model="model.message"
                    />
                    <div class="actions">
                        <button @click="close" type="button">Cancel</button>
                        <DspButton type="submit" class="primary" v-loading="loading" :disabled="loading">
                            Send
                        </DspButton>
                    </div>
                </BForm>
            </ValidationObserver>
        </BModal>
        <BModal :id="modalSuccessID" centered no-close-on-backdrop hide-footer @close="close" modal-class="need-help">
            <div class="image-sent">
                <img src="@/images/svg/email-sent.svg" alt="email sent icon svg" />
            </div>
            <p class="title">Message Sent</p>
            <p class="description">
                Thank you for reaching out, you can expect a response within <b>4-8 working hours</b>. We appreciate
                your patience as we work to resolve your issue!
            </p>
            <div class="actions">
                <DspButton @click="hide(modalSuccessID)" class="primary">OK</DspButton>
            </div>
        </BModal>
    </div>
</template>

<script setup lang="ts">
import { reactive, Ref, ref } from 'vue';
import { BForm } from 'bootstrap-vue';
import { NeedHelpParamsDTO } from '@/dto/NeedHelpParamsDTO';
import { AxiosErrorNeedHelp, NeedHelpService } from '@/services/NeedHelpService';
import AppFieldInput from '@/components/shared/AppFieldInput.vue';
import AppFieldTextarea from '@/components/shared/AppFieldTextarea.vue';

const variables = import('@/plugins/global-variables-from-vue-2');

interface Props {
    id: string;
}
const props = withDefaults(defineProps<Props>(), {
    id: 'need-help'
});

const loading = ref(false);
const validation = {
    subject: {
        rules: { required: true, min: 2, max: 255 }
    },
    message: {
        rules: { required: true, min: 2, max: 2000 }
    }
};
const model = reactive({
    subject: '',
    message: ''
});

const service = new NeedHelpService();
const globalToastError = (message: string) => variables.then(variables => variables.globalToastError(message));

const modalSuccessID = 'need-help-modal-success';
const show = (id: string) => variables.then(variables => variables.$bvModal.show(id));
const hide = (id: string) => variables.then(variables => variables.$bvModal.hide(id));

const send = () => {
    loading.value = true;

    const DTO = new NeedHelpParamsDTO();
    DTO.subject = model.subject;
    DTO.message = model.message;

    const promise = service.send(DTO);
    promise.then(response => {
        if (response.success) {
            show(modalSuccessID);
            close();
        }
    });
    promise.catch((error: AxiosErrorNeedHelp) => globalToastError(error.response.data.message));
    promise.finally(() => (loading.value = false));
};

const needHelpValidation: Ref<{ reset(): void } | null> = ref(null);
const close = () => {
    variables.then(variables => variables.$bvModal.hide(props.id));

    model.subject = '';
    model.message = '';
    needHelpValidation.value?.reset();
};
</script>

<style scoped lang="scss">
@import '@/scss/variables';
@import '@/scss/fonts';

.need-help {
    .title {
        @include default-title;
    }

    .image-sent {
        text-align: center;
        margin-bottom: 20px;
    }

    .description {
        @include default-size-font;

        text-align: center;

        b {
            color: $color-primary;
        }
    }

    .app-field-input {
        margin-bottom: 15px;
    }

    .actions {
        display: flex;
        justify-content: center;
        gap: 20px;
        margin-top: 20px;

        [type='button'] {
            @include default-size-font;

            border: none;
            background: none;
            color: $color-primary;
        }

        .button {
            min-width: 120px;
        }
    }
}
</style>

<style lang="scss">
@import '@/scss/variables';

.need-help {
    &.modal {
        .modal-dialog {
            max-width: 400px;
            margin: auto;

            @include mediaDesktop($W425) {
                margin: 0.5rem;
            }

            .modal-content {
                padding: 10px 30px 20px;

                @include mediaDesktop($W425) {
                    padding: 10px 20px 20px;
                }

                .modal-body {
                    padding: 1rem 0;
                    margin-bottom: 10px;
                }
            }

            .modal-header {
                padding-bottom: 5px;

                button.close {
                    padding: 5px 10px;
                    font-weight: inherit;
                    transform: translateX(20px);
                }
            }
        }
    }
}
</style>
