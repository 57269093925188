import moment from 'moment-timezone';
import { Period } from '@/lib/periodToDates';
import { Date } from '@/lib/prebidAnalytics';

interface DateString {
    from: string;
    to: string;
    range: string;
}
interface State {
    selectedColumns: string[];
    date: DateString;
    records: number;
}

export default {
    namespaced: true,

    state: {
        date: {
            from: moment().format('YYYY-MM-DD'),
            to: moment().format('YYYY-MM-DD'),
            range: Period.TODAY
        },
        records: 10,
        selectedColumns: [
            'publisherId',
            'placementId',
            'creativeId',
            'auctionStatus',
            'bidWon',
            'winPrice',
            'bidTimeout',
            'bidRejected',
            'rejectionReason',
            'bidderErrors',
            'adRenderFailed',
            'renderFailedReason',
            'responseTime'
        ]
    },

    getters: {
        getSelectedColumns: (state: State): string[] => state.selectedColumns,
        getDate: (state: State): Date => ({
            from: moment(state.date.from),
            to: moment(state.date.to),
            range: state.date.range
        }),
        getRecords: (state: State): number => state.records
    },

    mutations: {
        setSelectedColumns(state: State, value: string[]): void {
            state.selectedColumns = value;
        },
        setDate(state: State, value: Date): void {
            state.date = {
                from: value.from.format('YYYY-MM-DD'),
                to: value.to.format('YYYY-MM-DD'),
                range: value.range
            };
        },
        setRecords(state: State, value: number): void {
            state.records = value;
        }
    }
};
