<template>
    <div class="ranges">
        <ul>
            <li
                v-for="(item, index) in presets_"
                :key="index"
                :class="{ active: item.label === chosenLabel }"
                @click="$emit('clickPreset', item)"
            >
                {{ item.label }}
            </li>
        </ul>
        <!-- feature #49 -->
        <div class="action-buttons" v-if="!picker.autoApply">
            <button class="btn btn-sm btn-success" :disabled="canSelect" type="button" @click="$emit('clickApply')">
                {{ applyLabel }}
            </button>
            <button class="btn btn-sm btn-default" type="button" @click="$emit('clickCancel')">
                {{ cancelLabel }}
            </button>
        </div>
    </div>
</template>

<script>
import { Period } from '@/lib/periodToDates';
import _ from 'lodash';

export default {
    name: 'Data2RangesComponent',

    props: {
        canSelect: {
            type: Boolean,
            default: false
        },

        presets: {
            type: Array,
            default() {
                return [];
            }
        },

        applyLabel: {
            type: String,
            default: 'Apply'
        },

        cancelLabel: {
            type: String,
            default: 'Cancel'
        }
    },

    inject: ['picker'],
    computed: {
        chosenLabel() {
            let chosenLabel = '';
            let customRange = true;
            const picker = this.picker;
            const ftm = 'YYYY-MM-DD';
            for (const preset of this.orderedPresents) {
                const [start, end] = preset.range;
                // ignore times when comparing dates if time picker is not enabled
                if (picker.start_.format(ftm) === start.format(ftm) && picker.end_.format(ftm) === end.format(ftm)) {
                    customRange = false;
                    chosenLabel = preset.label;
                    break;
                }
            }

            if (customRange) {
                if (this.picker.showCustomRangeLabel) {
                    chosenLabel = this.picker.locale.customRangeLabel;
                } else {
                    chosenLabel = null;
                }
            }
            return chosenLabel;
        },

        presets_() {
            if (this.picker.showCustomRangeLabel) {
                return [
                    ...this.presets,
                    {
                        label: this.picker.locale.customRangeLabel,
                        range: ''
                    }
                ];
            }
            return this.presets;
        },

        orderedPresents() {
            const last30DaysIndex = this.presets.findIndex(element => element.label === Period.LAST_30_DAYS);
            const lastMonthIndex = this.presets.findIndex(element => element.label === Period.LAST_MONTH);
            const result = _.cloneDeep(this.presets);

            result.splice(last30DaysIndex, 0, result.splice(lastMonthIndex, 1)[0]);
            return result;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../styles/components/range.scss';
</style>
