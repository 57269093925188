import moment from 'moment';

export default {
    namespaced: true,

    state: {
        dateRange: {
            rangeName: 'All Dates',
            fromDate: moment().format('YYYY-MM-DD'),
            toDate: moment().format('YYYY-MM-DD')
        }
    },

    getters: {
        getDateRange: state => {
            return {
                rangeName: state.dateRange.rangeName ? state.dateRange.rangeName : 'All Dates',
                fromDate: moment(state.dateRange.fromDate, 'YYYY-MM-DD'),
                toDate: moment(state.dateRange.toDate, 'YYYY-MM-DD')
            };
        }
    },

    actions: {},

    mutations: {
        setDateRange(state, value) {
            state.dateRange = {
                fromDate: value.fromDate.format('YYYY-MM-DD'),
                toDate: value.toDate.format('YYYY-MM-DD'),
                rangeName: value.rangeName
            };
        }
    }
};
