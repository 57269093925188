interface State {
    selectedColumns: string[];
}

export default {
    namespaced: true,

    state: {
        selectedColumns: ['dspId', 'createdAt']
    },

    getters: {
        getSelectedColumns: (state: State): string[] => state.selectedColumns
    },

    mutations: {
        setSelectedColumns(state: State, value: string[]): void {
            state.selectedColumns = value;
        }
    }
};
